export function getAuthToken() {
  let keys = Object.keys(sessionStorage);
  // let keys = Object.keys(localStorage);
  let token = "",
    graphToken = "",
    userEmailId = "",
    userName = "";

  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    let val = sessionStorage.getItem(key);
    // let val = localStorage.getItem(key);

    if (val && validJSON(val)) {
      let parsedVal = JSON.parse(val);
      if (parsedVal.hasOwnProperty("credentialType")) {
        if (parsedVal.credentialType === "IdToken") {
          token = parsedVal.secret;
        } else if (parsedVal.credentialType === "AccessToken") {
          graphToken = parsedVal.secret;
        }
      } else if (parsedVal.hasOwnProperty("authorityType")) {
        userEmailId = parsedVal.username;
        userName = parsedVal.name;
      }
    }
  }
  return {
    idToken: token,
    graphToken: graphToken,
    userEmailId: userEmailId,
    userName: userName
  };
}

function validJSON(str) {
  try {
    // eslint-disable-next-line no-unused-vars
    let obj = JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
}
