import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactTabulator } from "react-tabulator";
import { Row, Col, Button, Popover, OverlayTrigger } from "react-bootstrap";
import {
  fetchMasterData,
  setActivityTypeAndCapsData,
  setIsDataProcessedForSaveAsNew
} from "../actions/calcConfig";
import _ from "lodash";
import { Calc_Tabs } from "../helper/constants";

export function ActivityType({ tabChangeHandler }) {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [initialData, setInitialData] = useState([]);
  const [rateCapGroupCol, setRateCapGroupCol] = useState([]);
  const [sessionCapGroupCol, setSessionCapGroupCol] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [tabledata, setTableData] = useState(null);

  const calcConfigActivityType = useSelector(
    state =>
      state.calcConfig?.calcConfigData?.data?.calcConfigActivityTypesHrsAndHrCap
  );
  const activityTypeMaster = useSelector(
    state => state.calcConfig.activityTypeMasterData
  );
  const hcpStatureMaster = useSelector(
    state => state.calcConfig.hcpStatureMasterData
  );
  const saveAsNew = useSelector(state => state.calcConfig.saveAsNew);

  useEffect(() => {
    dispatch(fetchMasterData("activity_types"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activityTypeMaster !== undefined) {
      setInitialData(
        _.map(activityTypeMaster.data, row => {
          row.activity_type_id = row.id;
          return row;
        })
      );
    }
  }, [activityTypeMaster]);

  useEffect(() => {
    if (hcpStatureMaster !== undefined) {
      let rateCap_columns = _.map(hcpStatureMaster.data, o => {
        o["field"] = "rate_" + o.id;
        o["editor"] = "number";
        o["editorParams"] = { min: 0 };
        o["headerSort"] = false;
        o["tooltip"] = true;
        o["width"] = 140;
        o["formatter"] = "money";
        o["formatterParams"] = { precision: false };
        o["validator"] = [{ type: checkNegative }];
        return o;
      });
      rateCap_columns = _.map(rateCap_columns, o => {
        return _.pick(o, [
          "title",
          "field",
          "editor",
          "editorParams",
          "headerSort",
          "tooltip",
          "width",
          "formatter",
          "formatterParams",
          "validator"
        ]);
      });
      setRateCapGroupCol(rateCap_columns);

      let sessionCap_columns = _.map(hcpStatureMaster.data, o => {
        o["field"] = "session_" + o.id;
        o["editor"] = "number";
        o["editorParams"] = { min: 0 };
        o["headerSort"] = false;
        o["tooltip"] = true;
        o["width"] = 140;
        o["formatter"] = "money";
        o["formatterParams"] = { precision: false };
        o["validator"] = [{ type: checkNegative }];
        return o;
      });
      sessionCap_columns = _.map(sessionCap_columns, o => {
        return _.pick(o, [
          "title",
          "field",
          "editor",
          "editorParams",
          "headerSort",
          "tooltip",
          "width",
          "formatter",
          "formatterParams",
          "validator"
        ]);
      });
      setSessionCapGroupCol(sessionCap_columns);
    }
  }, [hcpStatureMaster]);

  useEffect(() => {
    let tableColumns = [
      {
        title: "Activity Type",
        field: "value",
        headerFilter: "input",
        headerFilterPlaceholder: "Search by Name",
        tooltip: true,
        width: 350,
        frozen: true
      },
      {
        title: "&nbsp;",
        headerHozAlign: "center",
        columns: [
          {
            title: "Prep Time",
            field: "prep_time_hrs",
            editor: "number",
            editorParams: { min: 0 },
            headerSort: false,
            tooltip: true,
            width: 110,
            validator: [
              {
                type: checkNegative
              }
            ]
          },
          {
            title: "Service Time",
            field: "service_time_hrs",
            editor: "number",
            editorParams: { min: 0 },
            headerSort: false,
            tooltip: true,
            width: 110,
            validator: [
              {
                type: checkNegative
              }
            ]
          },
          {
            title: "Comments",
            field: "comments",
            editor: "input",
            headerSort: false,
            tooltip: true,
            width: 350
          },
          {
            title: "Cap (Hrs)",
            field: "hr_cap",
            editor: "number",
            editorParams: { min: 0 },
            headerSort: false,
            tooltip: true,
            width: 140,
            validator: [
              {
                type: checkNegative
              }
            ]
          }
        ]
      },
      {
        title: "Regulatory Caps Per HCP Stature (Rate/Hr)",
        headerHozAlign: "center",
        columns: rateCapGroupCol
      },
      {
        title: "Regulatory Caps Per HCP Stature (Cap for Session)",
        headerHozAlign: "center",
        columns: sessionCapGroupCol
      }
    ];
    setTableColumns(tableColumns);
  }, [rateCapGroupCol, sessionCapGroupCol]);

  const checkNegative = function(cell, value, parameters) {
    let check = true;
    if (value) {
      check = value > 0;
    }
    return check;
  };

  useEffect(() => {
    if (calcConfigActivityType !== undefined) {
      _.forEach(calcConfigActivityType, function(obj) {
        _.map(initialData, row => {
          if (row.activity_type_id === obj.activity_type_id) {
            row.prep_time_hrs = obj.prep_time_hrs;
            row.service_time_hrs = obj.service_time_hrs;
            row.comments = obj.comments;
            // row.hr_cap = obj.hcp_stature_hr_cap;
            row.item_id = obj.item_id;
            row.hr_cap = obj.hr_cap;

            row.calcConfigActivityTypeHcpStatureCap = [];
            _.forEach(obj?.calcConfigActivityTypeHcpStatureCap, function(o) {
              let columnId = "rate_" + o.hcp_stature_id;
              row[columnId] = o.rate_cap;
              o.item_id_stature = o.item_id;
              delete o.item_id;
              row.calcConfigActivityTypeHcpStatureCap.push(o);
            });

            row.calcConfigActivityTypeHcpStatureSessionCap = [];
            _.forEach(obj?.calcConfigActivityTypeHcpStatureSessionCap, function(
              o
            ) {
              let columnId = "session_" + o.hcp_stature_id;
              row[columnId] = o.session_cap;
              o.item_id_session = o.item_id;
              delete o.item_id;
              row.calcConfigActivityTypeHcpStatureSessionCap.push(o);
            });
          }
          return row;
        });
      });
      setInitialData(initialData);
      setTableData(
        _.filter(
          initialData,
          o => {
            return (
              (o.service_time_hrs >= 0 && o.service_time_hrs !== null) ||
              (o.prep_time_hrs >= 0 && o.prep_time_hrs !== null)
            );
          },
          0
        )
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcConfigActivityType]);

  useEffect(() => {
    if (saveAsNew) {
      let data = _.forEach(initialData, obj => {
        delete obj["item_id"];
        delete obj["updated_at"];
        delete obj["updated_by"];

        if (obj?.calcConfigActivityTypeHcpStatureCap) {
          _.forEach(obj?.calcConfigActivityTypeHcpStatureCap, o => {
            delete o["calculator_config_activity_type_id"];
            delete o["item_id_stature"];
          });
        }
        if (obj?.calcConfigActivityTypeHcpStatureSessionCap) {
          _.forEach(obj?.calcConfigActivityTypeHcpStatureSessionCap, o => {
            delete o["calculator_config_activity_type_id"];
            delete o["item_id_session"];
          });
        }
      });
      let filteredData = _.filter(data, o => {
        return (
          (o.service_time_hrs >= 0 && o.service_time_hrs !== null) ||
          (o.prep_time_hrs >= 0 && o.prep_time_hrs !== null)
        );
      });
      setInitialData(data);
      setTableData(filteredData);
      dispatch(setActivityTypeAndCapsData({ tableData: filteredData }));
      dispatch(
        setIsDataProcessedForSaveAsNew({ activityTypesProcessed: true })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAsNew]);

  useEffect(() => {
    tabChangeHandler.current[Calc_Tabs.TAB_ActivityType] = {
      getTabData: getTabData
    };
    getTabData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabledata]);

  const getTabData = function() {
    dispatch(setActivityTypeAndCapsData({ tableData: tabledata }));
  };

  function onCellEdited(cell) {
    if (
      cell
        .getRow()
        .getTable()
        .getData() !== undefined
    ) {
      let selectedData = _.filter(
        cell
          .getRow()
          .getTable()
          .getData(),
        o => {
          return (
            (o.service_time_hrs >= 0 && o.service_time_hrs !== null) ||
            (o.prep_time_hrs >= 0 && o.prep_time_hrs !== null)
          );
        },
        0
      );

      let data = [];
      if (selectedData !== undefined && selectedData.length > 0) {
        data = _.map(selectedData, o => {
          return {
            item_id: o.item_id,
            calc_config_id: o.calc_config_id,
            calc_config_basic_id: o.calc_config_basic_id,
            activity_type_id: o.activity_type_id,
            prep_time_hrs: o.prep_time_hrs,
            service_time_hrs: o.service_time_hrs,
            hr_cap: o.hr_cap,
            comments: o.comments,
            calcConfigActivityTypeHcpStatureCap: formatRateCapData(o),
            calcConfigActivityTypeHcpStatureSessionCap: formatSessionCapData(o)
          };
        });
      }
      setTableData(data);
    }
  }

  function formatRateCapData(rowObj) {
    let rateCapsObj = _.pickBy(rowObj, (value, key) => key.startsWith("rate_"));
    let rateCapsArr = Object.keys(rateCapsObj).map(key => ({
      key,
      value: rateCapsObj[key]
    }));

    _.map(rateCapsArr, function(obj) {
      var hcp_stature_id = obj.key.substring(
        obj.key.indexOf("_") + 1,
        obj.key.length
      );
      _.map(rowObj.calcConfigActivityTypeHcpStatureCap, function(o) {
        if (o.hcp_stature_id === hcp_stature_id) {
          obj.item_id_stature = o.item_id_stature;
        }
      });
    });

    return _.map(rateCapsArr, function(obj) {
      return {
        hcp_stature_id: obj.key.substring(
          obj.key.indexOf("_") + 1,
          obj.key.length
        ),
        rate_cap: obj.value,
        ...(obj.item_id_stature ? { item_id_stature: obj.item_id_stature } : {})
      };
    });
  }

  function formatSessionCapData(rowObj) {
    let sessionCapsObj = _.pickBy(rowObj, (value, key) =>
      key.startsWith("session_")
    );
    let sessionCapsArr = Object.keys(sessionCapsObj).map(key => ({
      key,
      value: sessionCapsObj[key]
    }));

    _.map(sessionCapsArr, function(obj) {
      var hcp_stature_id = obj.key.substring(
        obj.key.indexOf("_") + 1,
        obj.key.length
      );
      _.map(rowObj.calcConfigActivityTypeHcpStatureSessionCap, function(o) {
        if (o.hcp_stature_id === hcp_stature_id) {
          obj.item_id_session = o.item_id_session;
        }
      });
    });

    return _.map(sessionCapsArr, function(obj) {
      return {
        hcp_stature_id: obj.key.substring(
          obj.key.indexOf("_") + 1,
          obj.key.length
        ),
        session_cap: obj.value,
        ...(obj.item_id_session ? { item_id_session: obj.item_id_session } : {})
      };
    });
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        To exclude any row please leave all the values blank.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="activity-type">
      <Row>
        <Col className="section-header" md={12}>
          Activity Type - Prep Time, Service Time and Caps
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            overlay={popover}
          >
            <Button className="Info-btn-tbulator">i</Button>
          </OverlayTrigger>
        </Col>
      </Row>

      {activityTypeMaster !== undefined ? (
        <div className="calc-config-tabulator-wrapper">
          <ReactTabulator
            ref={a => (tableRef.current = a)}
            columns={tableColumns}
            data={initialData}
            key={"activityType"}
            cellEdited={onCellEdited}
            height="100%"
            layout="fitColumns"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
