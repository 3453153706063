import apiAction from "./apiActionMethod";

//to do
export function fetchAppMessages() {
  return apiAction.getAPI({
    url: process.env.REACT_APP_API_BASE_URL + "pages",
    onSuccess: getPages,
    onFailure: err => console.log("Error occured in fetching pages.", err),
    label: "FETCH_APP_MESSAGE_PAGES"
  });
}

function getPages(data) {
  return {
    type: "GET_APP_MESSAGE_PAGES",
    payload: data
  };
}

export function fetchApplicationMessagesData(pageId) {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "pageConfig/ACTIVE/" +
      pageId +
      "/dashboard",
    onSuccess: getPageConfigData,
    onFailure: err =>
      console.log("Error occured in fetching page config data.", err),
    label: "FETCH_APP_CONFIG_MESSAGES_DATA"
  });
}
function getPageConfigData(data) {
  return {
    type: "GET_APP_CONFIG_MESSAGES_DATA",
    payload: data
  };
}
