import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// import _ from "lodash";
import { fetchKolDefinitionData } from "../actions/kolDefinition";

export function FMVKolDefinition() {
  const dispatch = useDispatch();

  const [kolTableData, setKolTableData] = useState([]);

  const kolDefinitionData = useSelector(state => state.kolDefinition?.kolData);

  useEffect(() => {
    dispatch(fetchKolDefinitionData());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      kolDefinitionData !== undefined &&
      kolDefinitionData?.data !== undefined
    ) {
      setKolTableData(kolDefinitionData?.data);
    }
  }, [kolDefinitionData]);

  const KolDefinitionTable = props => {
    const rows = props.tableData?.map((row, index) => {
      return (
        <tr key={index + 2}>
          <td className="kol_def_left_col left-padding">{row?.defn_type}</td>
          <td className="kol_def_comman_col left-padding">
            {row?.local_kol_defn}
          </td>
          <td className="kol_def_comman_col left-padding">
            {row?.national_kol_defn}
          </td>
          <td className="kol_def_comman_col left-padding">
            {row?.international_kol_defn}
          </td>
        </tr>
      );
    });
    return (
      <table className="kol_def_table">
        <tbody>
          <tr key={0} className="table_header">
            <td className="kol_def_hcp_header">HCP Stature (KOL)</td>
            <td className="kol_def_header">Local KOL</td>
            <td className="kol_def_header">National KOL</td>
            <td className="kol_def_header">International KOL</td>
          </tr>
          {rows}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <div className="fmv-ratecard kol-definition">
        <Container>
          <Row>
            <Col className="calc-section-header" md={12}>
              KOL DEFINITION
            </Col>
          </Row>
          <Row>
            <Col className="calc_koldef_guidance" md={12}>
              General guidance on determining KOL stature is found below:
            </Col>
          </Row>
          <Row>
            <KolDefinitionTable tableData={kolTableData}></KolDefinitionTable>
          </Row>
          <Row className="data-row"></Row>
          <Row className="data-row"></Row>
        </Container>
      </div>
    </div>
  );
}
