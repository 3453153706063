import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReactTabulator } from "react-tabulator";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";
import { GetCalculatorConfigHistory } from "../actions/calculatorHistory";
import { ShowLoader } from "../helper/showLoader";
import "moment";
const moment = require("moment");

export function CalcConfigHistory() {
  const dispatch = useDispatch();

  let { calcconfigid } = useParams();

  const [calcTableData, setCalcTableData] = useState([]);
  const [calcTableColumns, setCalcTableColumns] = useState([]);
  const [calcSectors, setCalcSectors] = useState("");
  const [country, setCountry] = useState("");
  const [calcName, setCalcName] = useState("");
  const [calcRegion, setCalcRegion] = useState("");
  const [calcRegionId, setCalcRegionId] = useState("");
  const [isAccess, setIsAccess] = useState(false);

  const regionId = useRef("");
  const redirectUrl = useRef("");
  const accessUrl = useRef("");
  const tableRef = useRef(null);

  const calcHistoryData = useSelector(state => state.calculatorHistory?.Data);
  const userInformation = useSelector(state => state.userInformation);

  useEffect(() => {
    if (
      userInformation !== undefined &&
      userInformation?.data !== undefined &&
      calcRegionId !== undefined
    ) {
      let systemadmin = false,
        businessadmin = false;
      _.map(userInformation?.data?.users[0]?.roles, o => {
        if (o?.role?.includes("System")) {
          systemadmin = true;
        }
      });
      _.map(userInformation?.data?.users[0]?.roles, o => {
        if (o?.role?.includes("Business")) {
          businessadmin = true;
        }
      });
      if (!systemadmin) {
        if (businessadmin) {
          if (userInformation?.data?.users[0]?.regionId !== undefined) {
            regionId.current = userInformation?.data?.users[0]?.regionId;
          }
        }
      }
      let pagesAccess = userInformation?.data?.users[0]?.accessPages;
      if (pagesAccess !== undefined) {
        _.map(pagesAccess, o => {
          if (o === "Calculator configuration") {
            if (systemadmin) {
              setIsAccess(true);
            } else if (businessadmin) {
              setIsAccess(true);
              if (calcRegionId !== regionId.current && calcRegionId !== "") {
                setIsAccess(false);
              }
            }
          }
        });
        accessUrl.current = "/accessdenied";
      }
    }
  }, [userInformation, calcRegionId]);

  useEffect(() => {
    if (calcconfigid !== undefined) {
      dispatch(GetCalculatorConfigHistory(calcconfigid));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcconfigid]);

  useEffect(() => {
    if (calcHistoryData && calcHistoryData !== undefined) {
      let data = [];
      if (calcHistoryData?.data?.calcConfigBasic) {
        data = _.map(calcHistoryData?.data?.calcConfigBasic, function(obj) {
          return {
            User_email: obj?.updated_by,
            version: obj?.version,
            last_updated_date: formatDate(obj?.last_updated_date),
            comments: obj?.comments,
            actualVersion: obj?.actualVersion
          };
        });
        function formatDate(o) {
          let updateAt = new Date(o);
          return updateAt.getTime();
        }
        setCalcTableData(data);
      }
      if (calcHistoryData?.data?.calcConfig) {
        setCalcName(calcHistoryData?.data?.calcConfig?.name);
        setCalcRegion(calcHistoryData?.data?.calcConfig?.region);
        setCountry(calcHistoryData?.data?.calcConfig?.country);
        setCalcRegionId(calcHistoryData?.data?.calcConfig?.region_id);
      }
      if (calcHistoryData?.data?.calcConfigSectors) {
        setCalcSectors(
          _.map(calcHistoryData?.data?.calcConfigSectors, o => {
            return o.sector;
          }).join(", ")
        );
      }
    }
  }, [calcHistoryData]);

  useEffect(() => {
    let TableColumns = [
      {
        title: "User Name",
        field: "User_email",
        tooltip: true,
        headerFilter: "input",
        headerFilterPlaceholder: "Search"
      },
      {
        title: "Version",
        field: "version",
        tooltip: true,
        headerFilter: "input",
        headerFilterPlaceholder: "Search"
      },
      {
        title: "Last Updated Date",
        field: "last_updated_date",
        formatter: function(cell, formatterParams, onRendered) {
          let dt = new Date(cell.getValue());
          return moment(dt).format("MMM DD, YYYY");
        },
        tooltip: true,
        headerFilter: "input",
        headerFilterPlaceholder: "Search",
        headerFilterFunc: function(
          headerValue,
          rowValue,
          rowData,
          filterParams
        ) {
          let dt = new Date(rowValue);
          let dtStr = moment(dt).format("MMM DD, YYYY");
          return dtStr.toLowerCase().indexOf(headerValue.toLowerCase()) >= 0;
        }
      },
      {
        title: "Comments",
        field: "comments",
        tooltip: true,
        headerFilter: "input",
        headerFilterPlaceholder: "Search"
      }
    ];
    setCalcTableColumns(TableColumns);
  }, []);

  const backToCalculator = () => {
    redirectUrl.current = "/calculatorconfig/" + calcconfigid;
    window.location.href = redirectUrl.current;
  };

  const onRowClick = function(e, row) {
    redirectUrl.current =
      "/calculatorconfig/" + calcconfigid + "/" + row.getData()?.actualVersion;
    window.location.href = redirectUrl.current;
  };

  return (
    <>
      {accessUrl.current !== "" &&
        (!isAccess ? <Redirect to={accessUrl.current} /> : "")}
      {isAccess ? (
        <div>
          <ShowLoader></ShowLoader>
          <Container className="calc-history-header-div">
            <Row>
              <Col md={6}>
                <h4>Calculator History</h4>
              </Col>
              <Col>
                <div className="calc-config-buttons">
                  <Button
                    variant="secondary"
                    className="submit_template_btn"
                    onClick={backToCalculator}
                    id="calc_history_top_back_btn"
                  >
                    Back to Calculator
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="calc-history-border">
            <Row>
              <Col className="label-name" md={1}>
                <span>Name:</span>
              </Col>
              <Col className="label-name" md={5}>
                <span>{calcName}</span>
              </Col>
              <Col md={1} />
              <Col className="label-name" md={1}>
                <span>Region:</span>
              </Col>
              <Col className="label-name" md={3}>
                <span>{calcRegion}</span>
              </Col>
            </Row>
            <Row>
              <Col className="label-name" md={1}>
                <span>Sector:</span>
              </Col>
              <Col className="label-name" md={5}>
                <span>{calcSectors}</span>
              </Col>
              <Col md={1} />
              <Col className="label-name" md={1}>
                <span>Market:</span>
              </Col>
              <Col className="label-name" md={3}>
                <span>{country}</span>
              </Col>
            </Row>
          </Container>
          <div className="cal-history-tabulator-wrapper">
            {calcTableData !== undefined && calcTableColumns !== undefined ? (
              <ReactTabulator
                ref={a => (tableRef.current = a)}
                columns={calcTableColumns}
                data={calcTableData}
                key={"CalculatorHistory"}
                rowClick={onRowClick}
                height="100%"
                layout="fitColumns"
              />
            ) : (
              ""
            )}
          </div>
          <div className="calc-config-buttons">
            <Button
              variant="secondary"
              className="submit_template_btn"
              onClick={backToCalculator}
              id="calc_history_bottom_back_btn"
            >
              Back to Calculator
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
