export const Calc_Tabs = {
  TAB_CalculatorConfigBasic: 1,
  TAB_HCPSegment: 2,
  TAB_HCPStature: 3,
  TAB_CountrySpecificLimit: 4,
  TAB_ActivityType: 5,
  TAB_ActivityLocation: 6,
  TAB_GroundRules: 7,
  TAB_HCPSpecialty: 8
};
