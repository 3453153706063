import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { useParams } from "react-router-dom";
import { ReactTabulator } from "react-tabulator";
import DOMPurify from "dompurify";
import { Row, Col, Popover, OverlayTrigger } from "react-bootstrap";

import { Modal, Button } from "react-bootstrap";

import Swal from "sweetalert2";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setGroundRulesMessagesData,
  fetchPagesMessages,
  fetchPageConfigMessagesData,
  setIsDataProcessedForSaveAsNew,
} from "../actions/calcConfig";
import _ from "lodash";
import { Calc_Tabs } from "../helper/constants";
import { TextEditor } from "../helper/textEditor";

export function GroundRules({ tabChangeHandler }) {
  const dispatch = useDispatch();
  // let { calculatorid } = useParams();

  //const [isEditing, setIsEditing] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const [dataInitialize, setDataInitialize] = useState(false);
  const [pageid, setPageId] = useState("");

  const [groundRulesTableColumns, setGroundRulesTableColumns] = useState([]);
  const [messagesTableColumns, setMessagesTableColumns] = useState([]);
  const [messagestabledata, setMessagesTableData] = useState(null);
  const [initialData, setInitialData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [show, setShow] = useState(false);

  const [contentType, setContentType] = useState("");
  const [editorData, setEditorData] = useState("");

  const [isMessageData, setIsMessageData] = useState(false);
  const [isContent, setIsContent] = useState(false);

  //new add start
  const [groundRulesTabledata, setGroundRulesTableData] = useState(null);
  const [groundRuleupdatedData, setGroundRuleUpdatedData] = useState([]);
  const [showGroundrule, setShowGroundrule] = useState(false);
  const [groundRuleInitialData, setGroundRuleInitialData] = useState([]);
  const [groundRuleEditorData, setGroundRuleEditorData] = useState("");
  const [groundRuleDataInitialize, setGroundRuleDataInitialize] =
    useState(false);
  //const [isGroundRuleMessageData, setGroundRuleIsMessageData] = useState(false);
  //const [isGroundRuleContent, setIsGroundRuleContent] = useState(false);
  //const [groundRuleContentType, setGroundRuleContentType] = useState("");
  const [isGround, setIsGround] = useState(false);
  //new add end 

  const pageConfig = useSelector((state) => state.calcConfig);
 
  const calcConfigMessages = useSelector(
    (state) => state.calcConfig?.calcConfigData?.data?.calcConfigMessages
  );
  const calcConfigGroundRules = useSelector(
    (state) => state.calcConfig?.calcConfigData?.data?.calcConfigGroundRules
  );

  const saveAsNew = useSelector((state) => state.calcConfig.saveAsNew);

  // const tableRef = useRef(null);
  const ref = useRef(null);

  //check if page is new or we want to edit
  /*  useEffect(() => {
    if (calculatorid !== undefined) {
      setIsEditing(true);
    }
  }, [calculatorid]); */

  useEffect(() => {
    if (!initialize) {
      dispatch(fetchPagesMessages());
    }
    setInitialize(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize]);

  useEffect(() => {
    if (pageid !== undefined && pageid !== "") {
      dispatch(fetchPageConfigMessagesData(pageid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageid]);

  //fetch correct pageconfig data
  useEffect(() => {
    if (pageConfig !== undefined) {
      let selectedData = _.filter(pageConfig?.messagePages?.data, (o) => {
        return o.page_name === "Calculator configuration";
      });

      if (selectedData !== undefined && selectedData.length > 0) {
        setPageId(selectedData[0]?.id);
      }
    }
  }, [pageConfig]);

  //initialize ground rules only
  useEffect(() => {
    if (
      pageConfig?.pageMessageData?.data !== undefined &&
      pageConfig?.pageMessageData?.data?.length > 0 &&
      !isGround
    ) {
      let groundruleFilterData = _.filter(
        pageConfig?.pageMessageData?.data,
        (o) => {
          return o.content_type === "Ground Rules";
        }
      );
      let groundRuleData = _.map(groundruleFilterData, (o) => {
        return {
          content_type: o.content_type,
          contents: o.contents,
        };
      });

      if (calcConfigGroundRules === undefined) {
        setGroundRuleInitialData(groundRuleData);
      } else {
        setGroundRuleInitialData(groundruleFilterData);
      }

      //setGroundRuleContentType(groundruleFilterData[0].content_type);
      setGroundRuleEditorData(groundruleFilterData[0].contents);
    }
  }, [pageConfig, isGround]);

  //initialize message config only
  useEffect(() => {
    if (
      pageConfig?.pageMessageData?.data !== undefined &&
      pageConfig?.pageMessageData?.data?.length > 0 &&
      !isMessageData
    ) {
      let filterData = _.filter(pageConfig?.pageMessageData?.data, (o) => {
        return o.content_type === "Internal Message";
      });

      let data = _.map(filterData, (o) => {
        return {
          message_type: o.content_type,
          messages: o.contents,
        };
      });
      /*  let groundruleFilterData = _.filter(
        pageConfig?.pageMessageData?.data,
        (o) => {
          return o.content_type === "Ground Rules";
        }
      );
       let groundRuleData = _.map(groundruleFilterData, (o) => {
        return {
          rule_type: o.content_type,
          rule_desc: o.contents,
        };
      }); */

      setInitialData(filterData);

      if (!isContent) {
        setMessagesTableData(data);
        setIsContent(true);
      }

      setContentType(filterData[0].content_type);
      setEditorData(filterData[0].contents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageConfig, isMessageData]);

  //update message config on model save
  useEffect(() => {
    let data = [];
    if (
      updatedData !== undefined &&
      updatedData !== "" &&
      updatedData.length > 0 &&
      !dataInitialize
    ) {
      data = _.map(updatedData, (o) => {
        return {
          message_type: o.content_type,
          messages: o.contents,
          item_id: o.item_id,
        };
      });

      setMessagesTableData(data);

      setInitialData(updatedData);
      setIsMessageData(true);

      setContentType(updatedData[0].content_type);
      setEditorData(updatedData[0].contents);

      setDataInitialize(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData, pageConfig]);

  //update ground rules on model save
  useEffect(() => {
    let groundRuleData = [];
    if (
      groundRuleupdatedData !== undefined &&
      groundRuleupdatedData !== "" &&
      groundRuleupdatedData.length > 0 &&
      !groundRuleDataInitialize
    ) {
      groundRuleData = _.map(groundRuleupdatedData, (o) => {
        return {
          rule_type: o.content_type,
          rule_desc: o.contents,
          item_id: o.item_id,
        };
      });

      setGroundRulesTableData(groundRuleData);
      setGroundRuleInitialData(groundRuleupdatedData);
      setIsGround(true);
      setGroundRuleDataInitialize(true);

      // setGroundRuleContentType(groundRuleupdatedData[0].content_type);
      setGroundRuleEditorData(groundRuleupdatedData[0].contents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groundRuleupdatedData, pageConfig]);

  //update message config data from backend
  useEffect(() => {
    if (calcConfigMessages !== undefined && calcConfigMessages.length > 0) {
      let filterData = _.filter(calcConfigMessages, (o) => {
        return o.message_type === "Internal Message";
      });
      let data = _.map(filterData, (o) => {
        return {
          message_type: o.message_type,
          messages: o.messages,
          item_id: o.item_id,
        };
      });
      let updateTableData = _.map(filterData, (o) => {
        return {
          content_type: o?.message_type,
          contents: o?.messages,
          created_at: o?.created_at,
          created_by: o?.created_by,
          deleted_by: o?.deleted_by,
          item_id: o?.item_id,
          status: o.status,
          updated_at: o?.updated_at,
          updated_by: o?.updated_by,
        };
      });

      if (updateTableData.length) {
        setInitialData(updateTableData);
        setIsMessageData(true);
      }
      setMessagesTableData(data);
      setContentType(filterData[0]?.message_type);
      setEditorData(filterData[0]?.messages);
    }
  }, [calcConfigMessages]);

  //update ground data from backend
  useEffect(() => {
    if (
      calcConfigGroundRules !== undefined &&
      calcConfigGroundRules.length > 0
    ) {
      let filterData = _.filter(calcConfigGroundRules, (o) => {
        return o.rule_type === "Ground Rules";
      });
      let groundRuleData = _.map(filterData, (o) => {
        return {
          rule_type: o.rule_type,
          rule_desc: o.rule_desc,
          item_id: o.item_id,
        };
      });
      let updateTableData = _.map(filterData, (o) => {
        return {
          content_type: o?.rule_type,
          contents: o?.rule_desc,
          created_at: o?.created_at,
          created_by: o?.created_by,
          deleted_by: o?.deleted_by,
          item_id: o?.item_id,
          status: o.status,
          updated_at: o?.updated_at,
          updated_by: o?.updated_by,
        };
      });

      setGroundRuleInitialData(updateTableData);
      setIsGround(true);
      setGroundRulesTableData(groundRuleData);

      //setGroundRuleContentType(filterData[0]?.rule_type);
      setGroundRuleEditorData(filterData[0]?.rule_desc);

      //setGroundRuleIsMessageData(true);
    }
  }, [calcConfigGroundRules]);

  useEffect(() => {
    let messagesTableColumns = [
      {
        title: "Content Type",
        field: "content_type",
        width: 200,
        tooltip: true,
      },
      {
        title: "Content",
        field: "contents",
        formatter: function (cell, formatterParams, onRendered) {
          return DOMPurify.sanitize(cell.getValue());
        },
      },
      {
        // title:  " ",
        hozAlign: "center",
        width: 45,
        headerSort: false,
        formatter: editIcon,
        cellClick: editItem,
      },
    ];
    let groundRulesTableColumns = [
      {
        title: "Content Type",
        field: "content_type",

        width: 200,
        tooltip: true,
      },
      {
        title: "Rules",
        field: "contents",
        formatter: function (cell, formatterParams, onRendered) {
          return DOMPurify.sanitize(cell.getValue());
        },
      },

      {
        // title:  " ",
        hozAlign: "center",
        width: 45,
        headerFilter: "input",
        headerSort: false,
        formatter: editIcon1,
        cellClick: editItem1,
      },
    ];
    setMessagesTableColumns(messagesTableColumns);
    setGroundRulesTableColumns(groundRulesTableColumns);
  }, []);

  useEffect(() => {
    if (saveAsNew) {
      let groundRules = _.forEach(groundRulesTabledata, (obj) => {
        delete obj["item_id"];
      });
      let messages = _.forEach(messagestabledata, (obj) => {
        delete obj["item_id"];
      });
      dispatch(
        setGroundRulesMessagesData({
          groundRulesTabledata: groundRules,
          messagestabledata: messages,
        })
      );
      dispatch(
        setIsDataProcessedForSaveAsNew({
          groundRulesAndMessagesProcessed: true,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAsNew]);

  useEffect(() => {
    tabChangeHandler.current[Calc_Tabs.TAB_GroundRules] = {
      getTabData: getTabData,
    };
    getTabData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groundRulesTabledata, messagestabledata]);

  const editIcon = function () {
    return (
      "<img height='20px' width='20px' src='" +
      process.env.PUBLIC_URL +
      "/edit-icon.png'/>"
    );
  };

  const editItem = function (e, cell) {
    // setItemId(cell.getRow().getData().id);
    setShow(true);
  };

  const editIcon1 = function () {
    return (
      "<img height='20px' width='20px' src='" +
      process.env.PUBLIC_URL +
      "/edit-icon.png'/>"
    );
  };

  const editItem1 = function (e, cell) {
    // setItemId(cell.getRow().getData().id);
    setShowGroundrule(true);
  };

  const getTabData = function () {
    dispatch(
      setGroundRulesMessagesData({
        groundRulesTabledata: groundRulesTabledata,
        messagestabledata: messagestabledata,
      })
    );
  };

  const handleClose = () => {
    setShow(false);
    setEditorData(initialData[0]?.contents);
  };

  const groundrulehandleClose = () => {
    setShowGroundrule(false);

    setGroundRuleEditorData(groundRuleInitialData[0]?.contents);
  };

  const onModalSave = function () {
    if (contentType.trim() === "" && editorData.trim() === "") {
      Swal.fire({
        title: "Cannot save blank data!",
        icon: "warning",
      });
      return;
    }

    let updatedData = _.map(initialData, (o) => {
      return {
        content_type: o?.content_type,
        contents: editorData,
        created_at: o?.created_at,
        created_by: o?.created_by,
        deleted_by: o?.deleted_by,
        item_id: o.item_id,
        status: o?.status,
        updated_at: o?.updated_at,
        updated_by: o?.updated_by,
      };
    });
    setDataInitialize(false);
    setUpdatedData(updatedData);

    setShow(false);
  };

  const onModalSaveGroundrule = function () {
    if (groundRuleEditorData.trim() === "") {
      Swal.fire({
        title: "Cannot save blank data!",
        icon: "warning",
      });
      return;
    }

    let updatedData = _.map(groundRuleInitialData, (o) => {
      return {
        content_type: o?.content_type,
        contents: groundRuleEditorData,
        created_at: o?.created_at,
        created_by: o?.created_by,
        deleted_by: o?.deleted_by,
        item_id: o.item_id,
        status: o?.status,
        updated_at: o?.updated_at,
        updated_by: o?.updated_by,
      };
    });

    setGroundRuleDataInitialize(false);

    setGroundRuleUpdatedData(updatedData);

    setShowGroundrule(false);
  };

  const messagepopover = (
    <Popover id="popover-basic">
      <Popover.Body>
        To exclude Internal Message please leave the Content blank.
      </Popover.Body>
    </Popover>
  );

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        To add ground rules please click below edit icon.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="ground-rules">
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{
            border: "1px solid",
            boxShadow: "10px 10px 16px 0px rgba(0,0,0,0.75)",
            color: "black",
            backgroundColor: "#e6e6e6",
            fontWeight: "bold",
          }}
        />
      </div>
      <Row>
        <Col className="section-header" md={12}>
          Message Configuration
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            overlay={messagepopover}
          >
            <Button className="Info-btn-tbulator">i</Button>
          </OverlayTrigger>
        </Col>
      </Row>
      {initialData !== undefined ? (
        <ReactTabulator
          ref={(a) => (ref.current = a)}
          columns={messagesTableColumns}
          data={initialData}
          key={"messageConfig"}
          height="100%"
          layout="fitColumns"
        />
      ) : (
        ""
      )}
      <Row>
        <Col className="section-header" md={12}>
          Ground Rules
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            overlay={popover}
          >
            <Button className="Info-btn-tbulator">i</Button>
          </OverlayTrigger>
        </Col>
      </Row>

      {groundRuleInitialData !== undefined ? (
        <ReactTabulator
          ref={(a) => (ref.current = a)}
          columns={groundRulesTableColumns}
          data={groundRuleInitialData}
          key={"groundRules"}
          height="100%"
          layout="fitColumns"
        />
      ) : (
        ""
      )}

      {/* Message Configuration */}
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-type pc-content-box">
            <label className="pc-content-label">Content Type</label>&nbsp;&nbsp;
            <input
              type="text"
              value={contentType}
              className="pc-content-type"
              disabled={true}
            />
          </div>
          <div id="toolbar-container"></div>

          <TextEditor
            setContents={editorData}
            onChange={(value) => setEditorData(value)}
          />
          {/* <textarea value={editorData} className='editor'></textarea> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onModalSave}>
            Save
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Ground Rules */}
      <Modal
        size="xl"
        show={showGroundrule}
        onHide={groundrulehandleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-type pc-content-box">
            <label className="pc-content-label">Ground Rules</label>&nbsp;&nbsp;
            <input
              type="text"
              value="Ground Rules"
              //value={contentType}
              className="pc-content-type"
              disabled={true}
            />
          </div>
          <div id="toolbar-container"></div>

          <TextEditor
            setContents={groundRuleEditorData}
            onChange={(value) => setGroundRuleEditorData(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onModalSaveGroundrule}>
            Save
          </Button>
          <Button variant="primary" onClick={groundrulehandleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
