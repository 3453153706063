import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authentication/authConfig";
import { Provider, ReactReduxContext } from "react-redux";
import configureStore, { history } from "./store/configureStore";
import { ConnectedRouter } from "connected-react-router";
import App from "./App";

const store = configureStore();

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <MsalProvider instance={msalInstance}>
        <App></App>
      </MsalProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
