import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Popover, OverlayTrigger, Button } from "react-bootstrap";
import {
  fetchMasterData,
  setCalcConfigBasicData,
  setCalcConfigHideMultiplierData
} from "../actions/calcConfig";
import _ from "lodash";
import { Calc_Tabs } from "../helper/constants";
import Multiselect from "multiselect-react-dropdown";
import NumberFormat from "react-number-format";

export function CalculatorConfigBasic({ tabChangeHandler }) {
  const dispatch = useDispatch();

  let { calculatorid } = useParams();

  const languagesListObj = useRef();
  const currenciesListObj = useRef();
  const percentilesListObj = useRef();

  const [isEditing, setIsEditing] = useState(false);
  const [item_id, setItem_id] = useState("");
  const [calc_config_id, setCalc_config_id] = useState("");
  const [kolApplicable, setKolApplicable] = useState(false);
  const [translationApplicable, setTranslationApplicable] = useState(false);
  const [languageId, setLanguageId] = useState("");
  const [currencyId, setCurrencyId] = useState("");
  const [baseRate, setBaseRate] = useState("");
  const [minRange, setMinRange] = useState("");
  const [maxRange, setMaxRange] = useState("");
  const [roundOf, setRoundOf] = useState("10");
  const [decimalPointer, setDecimalPointer] = useState("point(.)");
  const [thousandPointer, setThousandPointer] = useState("comma(,)");
  const [percentileId, setPercentileId] = useState("");
  const [truevaultLink, setTruevaultLink] = useState("");
  const [comments, setComments] = useState("");
  // const multiselectRef = useRef(null);
  const translationLangList = useRef();
  const [selectedTransLang, setSelectedTransLang] = useState([]);
  // const [assignedTrans, setAssignedTrans] = useState([]);
  const [rangeOptionList] = useState([
    { id: "min", name: "Min" },
    { id: "mid", name: "Mid" },
    { id: "max", name: "Max" }
  ]);
  const [selectedRanges, setSelectedRanges] = useState([
    { id: "min", name: "Min" },
    { id: "mid", name: "Mid" },
    { id: "max", name: "Max" }
  ]);
  const [isMinApplicable, setIsMinApplicable] = useState(false);
  const [isMaxApplicable, setIsMaxApplicable] = useState(false);
  const [hideSpecialtyMultiplier, setHideSpecialtyMultiplier] = useState(false);
  const [hideStatureMultiplier, setHideStatureMultiplier] = useState(false);

  const calcConfigBasic = useSelector(
    state => state.calcConfig?.calcConfigData?.data?.calcConfigBasic
  );
  const languageMaster = useSelector(
    state => state.calcConfig.languageMasterData
  );
  const currencyMaster = useSelector(
    state => state.calcConfig.currencyMasterData
  );
  const percentileMaster = useSelector(
    state => state.calcConfig.percentileMasterData
  );
  const saveAsNew = useSelector(state => state.calcConfig.saveAsNew);

  // const regx_percent = /^100$|^\d{0,2}(\.\d{1,2})? *%?$/;
  const rgex_wholenumber = /^\d+$/;

  useEffect(() => {
    dispatch(fetchMasterData("languages"));
    dispatch(fetchMasterData("currencies"));
    dispatch(fetchMasterData("percentiles"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (calculatorid !== undefined) {
      setIsEditing(true);
    }
  }, [calculatorid]);

  useEffect(() => {
    if (languageMaster !== undefined) {
      if (
        _.find(languageMaster?.data, obj => {
          return obj.value.toUpperCase().trim() === "ENGLISH";
        })
      ) {
        setLanguageId(
          _.find(languageMaster?.data, obj => {
            return obj.value.toUpperCase().trim() === "ENGLISH";
          }).id
        );
      }
      let filteredTransLangs = _.filter(languageMaster?.data, item => {
        return item.translation_available === "Yes" || item.value === "English";
      });
      languagesListObj.current = filteredTransLangs?.map((obj, i) => {
        return (
          <option
            key={i}
            value={obj.id}
            id={"calcConfig-languages-option-" + obj.value}
          >
            {obj.value}
          </option>
        );
      });
      translationLangList.current = _.map(filteredTransLangs, item => {
        return { id: item.id, name: item.value };
      });
    }
  }, [languageMaster]);

  useEffect(() => {
    if (currencyMaster !== undefined) {
      if (
        _.find(currencyMaster?.data, obj => {
          return obj.value.toUpperCase().trim() === "USD";
        })
      ) {
        setCurrencyId(
          _.find(currencyMaster?.data, obj => {
            return obj.value.toUpperCase().trim() === "USD";
          }).id
        );
      }
      currenciesListObj.current = currencyMaster?.data.map((obj, i) => {
        return (
          <option
            key={i}
            value={obj.id}
            id={"calcConfig-currency-option-" + obj.value}
          >
            {obj.value}
          </option>
        );
      });
    }
  }, [currencyMaster]);

  useEffect(() => {
    if (percentileMaster !== undefined) {
      percentilesListObj.current = percentileMaster?.data.map((obj, i) => {
        return (
          <option
            key={i}
            value={obj.id}
            id={"calcConfig-percentiles-option-" + obj.value}
          >
            {obj.value}
          </option>
        );
      });
    }
  }, [percentileMaster]);

  useEffect(() => {
    if (calcConfigBasic !== undefined && isEditing) {
      setItem_id(calcConfigBasic?.item_id);
      setCalc_config_id(calcConfigBasic?.calc_config_id);
      setKolApplicable(
        calcConfigBasic?.is_kol_assessment_applicable !== null
          ? calcConfigBasic?.is_kol_assessment_applicable
          : false
      );
      setTranslationApplicable(
        calcConfigBasic?.is_translation_available !== null
          ? calcConfigBasic?.is_translation_available
          : false
      );
      setHideSpecialtyMultiplier(
        calcConfigBasic?.hide_speciality_multiplier !== null
          ? calcConfigBasic?.hide_speciality_multiplier
          : false
      );
      setHideStatureMultiplier(
        calcConfigBasic?.hide_stature_multiplier !== null
          ? calcConfigBasic?.hide_stature_multiplier
          : false
      );
      setLanguageId(
        calcConfigBasic?.lang_id !== null ? calcConfigBasic?.lang_id : ""
      );
      setCurrencyId(
        calcConfigBasic?.currency_id !== null
          ? calcConfigBasic?.currency_id
          : ""
      );
      setBaseRate(
        calcConfigBasic?.base_rate !== null ? calcConfigBasic?.base_rate : ""
      );
      setMinRange(
        calcConfigBasic?.min_range_percentage !== null
          ? calcConfigBasic?.min_range_percentage
          : ""
      );
      setMaxRange(
        calcConfigBasic?.max_range_percentage !== null
          ? calcConfigBasic?.max_range_percentage
          : ""
      );
      setRoundOf(
        calcConfigBasic?.round_of !== null ? calcConfigBasic?.round_of : ""
      );
      setDecimalPointer(calcConfigBasic?.punc_decimal_pointer);
      setThousandPointer(calcConfigBasic?.punc_thousand_pointer);
      setPercentileId(
        calcConfigBasic?.percentile !== null ? calcConfigBasic?.percentile : ""
      );
      setTruevaultLink(
        calcConfigBasic?.true_vault_link !== null
          ? calcConfigBasic?.true_vault_link
          : ""
      );
      setComments(
        calcConfigBasic?.comments !== null ? calcConfigBasic?.comments : ""
      );
      let available_translated_languages =
        calcConfigBasic?.available_translated_languages !== "" &&
        calcConfigBasic?.available_translated_languages !== null
          ? JSON.parse(calcConfigBasic?.available_translated_languages)
          : [];

      setSelectedTransLang(available_translated_languages);
      // setAssignedTrans(available_translated_languages);
      if (
        calcConfigBasic?.ranges_applicable &&
        calcConfigBasic?.ranges_applicable !== undefined
      ) {
        let ranges_array = JSON.parse(calcConfigBasic?.ranges_applicable);
        let range_Name = "";
        if (ranges_array?.length > 0) {
          let ranges_preselected = _.map(ranges_array, function(obj) {
            if (obj?.toLowerCase() === "min") {
              range_Name = "Min";
            }
            if (obj?.toLowerCase() === "mid") {
              range_Name = "Mid";
            }
            if (obj?.toLowerCase() === "max") {
              range_Name = "Max";
            }
            return { id: obj, name: range_Name };
          });
          setSelectedRanges(ranges_preselected);
          let minCheck = "",
            maxCheck = "";
          minCheck = _.find(ranges_preselected, o => {
            return o?.id?.toLowerCase() === "min";
          })?.id;
          if (minCheck === "" || minCheck === undefined) {
            setIsMinApplicable(true);
            // setMinRange("")
          }
          if (minCheck === "min") {
            setIsMinApplicable(false);
          }
          maxCheck = _.find(ranges_preselected, o => {
            return o?.id?.toLowerCase() === "max";
          })?.id;
          if (maxCheck === "" || maxCheck === undefined) {
            setIsMaxApplicable(true);
            // setMaxRange("")
          }
          if (maxCheck === "max") {
            setIsMaxApplicable(false);
          }
        }
      }
    }
  }, [calcConfigBasic, isEditing, saveAsNew]);

  useEffect(() => {
    if (saveAsNew) {
      setItem_id("");
      setCalc_config_id("");
    }
  }, [saveAsNew]);

  useEffect(() => {
    tabChangeHandler.current[Calc_Tabs.TAB_CalculatorConfigBasic] = {
      getTabData: getTabData
    };
    getTabData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    kolApplicable,
    translationApplicable,
    languageId,
    selectedTransLang,
    currencyId,
    baseRate,
    minRange,
    maxRange,
    roundOf,
    decimalPointer,
    thousandPointer,
    percentileId,
    truevaultLink,
    comments,
    selectedRanges,
    hideSpecialtyMultiplier,
    hideStatureMultiplier
  ]);

  const getTabData = function() {
    let trans = [];
    _.map(selectedTransLang, item => {
      trans.push(item);
    });
    let rangesData = [];
    _.map(selectedRanges, obj => {
      rangesData.push(obj?.id);
    });
    dispatch(
      setCalcConfigBasicData({
        ...(item_id !== undefined && item_id !== ""
          ? { item_id: item_id }
          : {}),
        ...(calc_config_id !== undefined && calc_config_id !== ""
          ? { calc_config_id: calc_config_id }
          : {}),
        ...(currencyId !== undefined && currencyId !== ""
          ? { currency_id: currencyId }
          : {}),
        base_rate: baseRate,
        min_range_percentage: minRange,
        max_range_percentage: maxRange,
        round_of: roundOf,
        is_kol_assessment_applicable: kolApplicable,
        is_translation_available: translationApplicable,
        ...(languageId !== undefined && languageId !== ""
          ? { lang_id: languageId }
          : {}),
        available_translated_languages: JSON.stringify(trans),
        punc_decimal_pointer: decimalPointer,
        punc_thousand_pointer: thousandPointer,
        percentile: percentileId,
        true_vault_link: truevaultLink,
        comments: comments,
        ranges_applicable: rangesData
      })
    );
    dispatch(
      setCalcConfigHideMultiplierData({
        hideSpecialityMultiplierData: hideSpecialtyMultiplier,
        hideStatureMultiplierData: hideStatureMultiplier
      })
    );
  };

  const DropDownComp = props => {
    if (props.listObj !== undefined) {
      return (
        <select
          className="select-config"
          value={props.objId}
          onChange={e => props.callOnChange(e.target.value)}
          disabled={props.disabled}
          id={props.selecteId}
        >
          <option value="">Select</option>
          {props.listObj}
        </select>
      );
    }
    return (
      <select>
        <option>Select</option>
      </select>
    );
  };

  const transLanguageOnSelect = (selectedList, selectedItem) => {
    setSelectedTransLang(selectedList);
    getTabData();
  };
  const transLanguageOnRemove = (selectedList, removedItem) => {
    setSelectedTransLang(selectedList);
    getTabData();
  };

  const handleOnClear = item => {
    setSelectedTransLang([]);
    // multiselectRef.current.resetSelectedValues();
  };

  const handleKeyDown = event => {
    if (event.key === "-" || event.key === "+" || event.key === "e") {
      event.preventDefault();
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        General configuration for the calculator where currency, base rate,
        ranges applicable, minimum, maximum, comments are mandatory fields.
      </Popover.Body>
    </Popover>
  );

  const rangeOnSelect = (selectedList, selectedItem) => {
    setSelectedRanges(selectedList);
    let minCheck = "",
      maxCheck = "";
    minCheck = _.find(selectedList, o => {
      return o?.id?.toLowerCase() === "min";
    })?.id;
    if (minCheck === "" || minCheck === undefined) {
      setIsMinApplicable(true);
      setMinRange("");
    }
    if (minCheck === "min") {
      setIsMinApplicable(false);
    }
    maxCheck = _.find(selectedList, o => {
      return o?.id?.toLowerCase() === "max";
    })?.id;
    if (maxCheck === "" || maxCheck === undefined) {
      setIsMaxApplicable(true);
      setMaxRange("");
    }
    if (maxCheck === "max") {
      setIsMaxApplicable(false);
    }
  };

  const rangeOnRemove = (selectedList, removedItem) => {
    setSelectedRanges(selectedList);
    let minCheck = "",
      maxCheck = "";
    minCheck = _.find(selectedList, o => {
      return o?.id?.toLowerCase() === "min";
    })?.id;
    if (minCheck === "" || minCheck === undefined) {
      setIsMinApplicable(true);
      setMinRange("");
    }
    if (minCheck === "min") {
      setIsMinApplicable(false);
    }
    maxCheck = _.find(selectedList, o => {
      return o?.id?.toLowerCase() === "max";
    })?.id;
    if (maxCheck === "" || maxCheck === undefined) {
      setIsMaxApplicable(true);
      setMaxRange("");
    }
    if (maxCheck === "max") {
      setIsMaxApplicable(false);
    }
  };

  const OnChangeDecimalSeparator = e => {
    setDecimalPointer(e.target.value);
    setThousandPointer(e.target.value !== "comma(,)" ? "comma(,)" : "point(.)");
  };

  return (
    <div className="general-config">
      <Row>
        <Col className="section-header" md={12}>
          Market Identifier and Settings
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            overlay={popover}
          >
            <Button className="Info-btn-tbulator">i</Button>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          'KOL Assessment' applicable?
        </Col>
        <Col md={3}>
          <select
            className="select-config"
            value={kolApplicable ? "1" : "0"}
            onChange={e =>
              setKolApplicable(e.target.value === "1" ? true : false)
            }
            id="calcConfig-basic-kol-applicable-id"
          >
            <option value="0" id="calcConfig-basic-kol-applicable-No-id">
              No
            </option>
            <option value="1" id="calcConfig-basic-kol-applicable-Yes-id">
              Yes
            </option>
          </select>
        </Col>
        <Col className="label-name" md={2}>
          'Translation' applicable?
        </Col>
        <Col md={3}>
          <select
            className="select-config"
            value={translationApplicable ? "1" : "0"}
            onChange={e => {
              setTranslationApplicable(e.target.value === "1" ? true : false);
              handleOnClear();
            }}
            id="calcConfig-basic-translation-applicable-id"
          >
            <option
              value="0"
              id="calcConfig-basic-translation-applicable-No-id"
            >
              No
            </option>
            <option
              value="1"
              id="calcConfig-basic-translation-applicable-Yes-id"
            >
              Yes
            </option>
          </select>
        </Col>
      </Row>
      <Row className="margintop">
        <Col className="label-name" md={2}>
          Default language
        </Col>
        <Col md={3}>
          <DropDownComp
            listObj={languagesListObj.current}
            callOnChange={setLanguageId}
            objId={languageId}
            disabled={translationApplicable ? false : true}
            selecteId={"calcConfig-basic-default-language-id"}
          ></DropDownComp>
        </Col>
        <Col className="label-name" md={2}>
          Select translations languages
        </Col>
        <Col md={3}>
          {selectedTransLang !== undefined && selectedTransLang !== [] ? (
            <Multiselect
              options={translationLangList.current}
              selectedValues={selectedTransLang}
              onSelect={transLanguageOnSelect}
              onRemove={transLanguageOnRemove}
              displayValue="name"
              // ref={multiselectRef}
              disable={translationApplicable ? false : true}
              id="calcConfig-basic-translation-languages-id"
              avoidHighlightFirstOption={true}
            />
          ) : (
            <Multiselect
              options={translationLangList.current}
              onSelect={transLanguageOnSelect}
              onRemove={transLanguageOnRemove}
              displayValue="name"
              // ref={multiselectRef}
              disable={translationApplicable ? false : true}
              id="calcConfig-basic-translation-languages-id"
              avoidHighlightFirstOption={true}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col className="section-header" md={12}>
          Base Rate
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          <span>Currency</span>
          <span className="asterisk-mandatory">*</span>
        </Col>
        <Col md={3}>
          <div id="currencyRedId">
            <DropDownComp
              listObj={currenciesListObj.current}
              callOnChange={setCurrencyId}
              objId={currencyId}
              selecteId={"calcConfig-basic-currency-id"}
            ></DropDownComp>
          </div>
        </Col>
        <Col className="label-name" md={2}>
          <span>Base rate</span>
          <span className="asterisk-mandatory">*</span>
        </Col>
        <Col md={3}>
          {/* <input
            // id="baseRateRedId"
            type="number"
            value={baseRate}
            min={0}
            onKeyDown={handleKeyDown}
            onChange={(e) => setBaseRate(e.target.value)}
          /> */}
          <div id="baseRateRedId">
            <NumberFormat
              thousandsGroupStyle="thousand"
              value={baseRate}
              decimalSeparator="."
              displayType="input"
              type="text"
              thousandSeparator={true}
              onValueChange={values => {
                setBaseRate(values.value);
              }}
              id="calcConfig-basic-base-rate-id"
              allowNegative={false}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="section-header" md={12}>
          Range Inputs
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          <span>Ranges applicable</span>
          <span className="asterisk-mandatory">*</span>
        </Col>
        <Col md={3}>
          <div id="rangesApplicableId">
            {selectedRanges &&
            selectedRanges !== undefined &&
            selectedRanges?.length > 0 ? (
              <Multiselect
                options={rangeOptionList}
                onSelect={rangeOnSelect}
                onRemove={rangeOnRemove}
                displayValue="name"
                selectedValues={selectedRanges}
                // ref={multiselectRef}
                id="ranges_applicable_id"
                avoidHighlightFirstOption={true}
              />
            ) : (
              <Multiselect
                options={rangeOptionList}
                onSelect={rangeOnSelect}
                onRemove={rangeOnRemove}
                displayValue="name"
                // ref={multiselectRef}
                id="ranges_applicable_id"
                avoidHighlightFirstOption={true}
              />
            )}
          </div>
        </Col>
        {!isMinApplicable ? (
          <>
            <Col className="label-name" md={2}>
              <span>Minimum %</span>
              {!isMinApplicable ? (
                <span className="asterisk-mandatory">*</span>
              ) : (
                ""
              )}
            </Col>
            <Col md={2}>
              <input
                id="minRangeRedId"
                type="number"
                min={0}
                value={minRange}
                onKeyDown={handleKeyDown}
                disabled={isMinApplicable ? true : false}
                onChange={
                  e => setMinRange(e.target.value)
                  // regx_percent.test(e.target.value) && setMinRange(e.target.value)
                }
                title=""
              />
            </Col>
          </>
        ) : (
          ""
        )}
        {!isMaxApplicable ? (
          <>
            <Col className="label-name" md={!isMinApplicable ? 1 : 2}>
              <span>Maximum %</span>
              {!isMaxApplicable ? (
                <span className="asterisk-mandatory">*</span>
              ) : (
                ""
              )}
            </Col>
            <Col md={2}>
              <input
                id="maxRangeRedId"
                type="number"
                min={0}
                value={maxRange}
                onKeyDown={handleKeyDown}
                disabled={isMaxApplicable ? true : false}
                onChange={
                  e => setMaxRange(e.target.value)
                  // regx_percent.test(e.target.value) && setMaxRange(e.target.value)
                }
                title=""
              />
            </Col>
          </>
        ) : (
          ""
        )}
      </Row>

      <Row>
        <Col className="section-header" md={12}>
          Rounding
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          Round to multiple of
        </Col>
        <Col md={3}>
          {/* <input
            type="number"
            min={0}
            value={roundOf}
            onKeyDown={handleKeyDown}
            // onChange={(e) => setRoundOf(e.target.value)}
            onChange={(e) =>
              rgex_wholenumber.test(e.target.value) &&
              setRoundOf(e.target.value)
            }
          /> */}
          <NumberFormat
            thousandsGroupStyle="thousand"
            value={roundOf}
            decimalSeparator={false}
            displayType="input"
            type="text"
            thousandSeparator={true}
            onValueChange={values => {
              rgex_wholenumber.test(values.value) && setRoundOf(values.value);
            }}
            id="calcConfig-basic-round-of-id"
            allowNegative={false}
          />
        </Col>
      </Row>

      <Row>
        <Col className="section-header" md={12}>
          Punctuation and Percentile Used
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          Decimal Separator
        </Col>
        <Col md={3}>
          <select
            className="select-config"
            value={decimalPointer}
            onChange={e => OnChangeDecimalSeparator(e)}
            id="calcConfig-basic-decimal-pointer-id"
          >
            <option
              value="point(.)"
              id="calcConfig-basic-decimal-option-point-id"
            >
              Point(.)
            </option>
            <option
              value="comma(,)"
              id="calcConfig-basic-decimal-option-comma-id"
            >
              Comma(,)
            </option>
          </select>
        </Col>
        <Col className="label-name" md={2}>
          Thousand Separator
        </Col>
        <Col md={2}>
          <select
            className="select-config"
            value={thousandPointer}
            onChange={e => setThousandPointer(e.target.value)}
            id="calcConfig-basic-thousand-pointer-id"
          >
            {decimalPointer !== "comma(,)" ? (
              <option
                value="comma(,)"
                id="calcConfig-basic-thousand-option-comma-id"
              >
                Comma(,)
              </option>
            ) : (
              ""
            )}
            {decimalPointer === "comma(,)" ? (
              <option
                value="point(.)"
                id="calcConfig-basic-thousand-option-point-id"
              >
                Point(.)
              </option>
            ) : (
              ""
            )}
            {decimalPointer === "comma(,)" ? (
              <option
                value="blank(' ')"
                id="calcConfig-basic-thousand-option-blanck-id"
              >
                Blank(' ')
              </option>
            ) : (
              ""
            )}
          </select>
        </Col>
        <Col className="label-name" md={1}>
          Percentile
        </Col>
        <Col md={2}>
          <DropDownComp
            listObj={percentilesListObj.current}
            callOnChange={setPercentileId}
            objId={percentileId}
            selecteId={"calcConfig-basic-percentile-id"}
          ></DropDownComp>
        </Col>
      </Row>
      <Row>
        <Col className="section-header" md={12}>
          Show/Hide Multiplier
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          Hide Specialty Multiplier
        </Col>
        <Col md={3}>
          <select
            className="select-config"
            value={hideSpecialtyMultiplier ? "1" : "0"}
            onChange={e =>
              setHideSpecialtyMultiplier(e.target.value === "1" ? true : false)
            }
            id="calcConfig-hide-specialty-multiplier-id"
          >
            <option value="0" id="calcConfig-hide-specialty-multiplier-No-id">
              No
            </option>
            <option value="1" id="calcConfig-hide-specialty-multiplier-Yes-id">
              Yes
            </option>
          </select>
        </Col>
        <Col className="label-name" md={2}>
          Hide Stature Multiplier
        </Col>
        <Col md={3}>
          <select
            className="select-config"
            value={hideStatureMultiplier ? "1" : "0"}
            onChange={e => {
              setHideStatureMultiplier(e.target.value === "1" ? true : false);
            }}
            id="calcConfig-basic-hide-stature-multiplier-id"
          >
            <option
              value="0"
              id="calcConfig-basic-hide-stature-multiplier-No-id"
            >
              No
            </option>
            <option
              value="1"
              id="calcConfig-basic-hide-stature-multiplier-Yes-id"
            >
              Yes
            </option>
          </select>
        </Col>
      </Row>

      <Row>
        <Col className="section-header" md={12}>
          Additional Information
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          TruVault Link
        </Col>
        <Col md={8}>
          <input
            className="text-link"
            type="text"
            value={truevaultLink}
            onChange={e => setTruevaultLink(e.target.value)}
            id="calcConfig-basic-truVault-link-id"
          />
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          <span>Comments</span>
          <span className="asterisk-mandatory">*</span>
        </Col>
        <Col md={8}>
          <textarea
            id="commentsRedId"
            rows="3"
            value={comments}
            onChange={e => setComments(e.target.value)}
          ></textarea>
        </Col>
      </Row>
    </div>
  );
}
