import apiAction from "./apiActionMethod";

export function GetCalculatorConfigHistory(calcId) {
  return apiAction.getAPI({
    url: process.env.REACT_APP_API_BASE_URL + "calcHistory/" + calcId,
    onSuccess: getCalcHistory,
    onFailure: err =>
      console.log("Error occured in getting calculator history. ", err),
    label: "FATCH_CALCULATOR_HISTORY"
  });
}

function getCalcHistory(data) {
  return {
    type: "GET_CALCULATOR_HISTORY",
    payload: data
  };
}
