import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// import CustomUploadAdapterPlugin from "../helper/customUploadEditorPlugin";
// import ClassicEditor from '../lib/ckeditor5/ckeditor'
// import '../lib/ckeditor5/ckstyles.css'
import DOMPurify from "dompurify";
import _ from "lodash";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css";
// import add from "../assets/add.svg";
import { ReactTabulator } from "react-tabulator";
import Swal from "sweetalert2";
import {
  fetchPages,
  fetchPageConfigData,
  fetchPageConfigDetail,
  updatePageConfigData,
  onClear
} from "../actions/pageConfig";
import { TextEditor } from "../helper/textEditor";

export function PageConfiguration() {
  const dispatch = useDispatch();

  const [initializer, setInitializer] = useState(false);
  const [pageid, setPageId] = useState("");
  const [show, setShow] = useState(false);
  const [itemid, setItemId] = useState("");
  const [contentType, setContentType] = useState("");
  const [editorData, setEditorData] = useState("");
  const [content, setContent] = useState("");
  const [isAccess, setIsAccess] = useState(false);

  const pageConfig = useSelector(state => state.pageConfig);
  const isDetailLoaded = useSelector(state => state.pageConfig.isDetailLoaded);
  const dataUpdated = useSelector(state => state.pageConfig.dataUpdated);
  const userInformation = useSelector(state => state.userInformation);

  const listObj = useRef(null);
  const tableRef = useRef(null);
  const postMethod = useRef("");
  const accessUrl = useRef("");

  // const ckConfig = useRef({
  //   extraPlugins: [CustomUploadAdapterPlugin]
  // });

  useEffect(() => {
    if (userInformation !== undefined && userInformation?.data !== undefined) {
      let pagesAccess = userInformation?.data?.users[0]?.accessPages;
      if (pagesAccess !== undefined) {
        _.map(pagesAccess, o => {
          if (o === "Master Data Management") {
            setIsAccess(true);
          }
        });
        accessUrl.current = "/accessdenied";
      }
    }
  }, [userInformation]);

  useEffect(() => {
    if (!initializer) {
      dispatch(fetchPages());
    }
    setInitializer(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializer]);

  useEffect(() => {
    if (pageConfig !== undefined) {
      listObj.current = pageConfig?.pages?.data.map((obj, i) => {
        return (
          <option key={i} value={obj.id}>
            {obj.page_name}
          </option>
        );
      });
      if (pageConfig?.data?.data) {
        _.forEach(pageConfig?.data?.data, o => {
          if (o.content_type === "Page Content") {
            o.contents = o.contents.replace(
              "[APIBASE]",
              process.env.REACT_APP_API_BASE_URL
            );
          }
          return o;
        });
      }
    }
  }, [pageConfig]);

  useEffect(() => {
    if (pageid !== undefined && pageid !== "") {
      dispatch(fetchPageConfigData(pageid));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageid, dataUpdated]);

  useEffect(() => {
    if (isDetailLoaded && pageConfig?.configDetail?.data !== undefined) {
      setContentType(pageConfig?.configDetail?.data?.pageData.content_type);
      setEditorData(
        pageConfig?.configDetail?.data?.pageData.contents.replace(
          "[APIBASE]",
          process.env.REACT_APP_API_BASE_URL
        )
      );
      setContent(pageConfig?.configDetail?.data?.pageData.contents);
      setShow(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDetailLoaded]);

  // useEffect(() => {
  //   if (show) {
  //     ClassicEditor
  //       .create(document.querySelector('.editor'), {
  //         licenseKey: '',
  //         extraPlugins: [CustomUploadAdapterPlugin]
  //       })
  //       .then(editor => {
  //         window.editor = editor;
  //         editor.model.document.on('change', () => {
  //           setEditorData(editor.getData())
  //         });
  //       })
  //       .catch(error => {
  //         console.error(error);
  //       });
  //   }
  // }, [show])

  const DropDownComp = () => {
    return (
      <select
        className="select-page"
        value={pageid}
        onChange={e => setPageId(e.target.value)}
      >
        <option value="">Select a page</option>
        {listObj.current}
      </select>
    );
  };

  const editIcon = function() {
    return (
      "<img height='20px' width='20px' src='" +
      process.env.PUBLIC_URL +
      "/edit-icon.png'/>"
    );
  };

  const editItem = function(e, cell) {
    setItemId(cell.getRow().getData().id);
    dispatch(fetchPageConfigDetail(cell.getRow().getData().id));
  };

  // const addNewItem = function() {
  //   setItemId("");
  //   setShow(true);
  // };

  // const deleteIcon = function() {
  //   return (
  //     "<img height='20px' width='20px' src='" +
  //     process.env.PUBLIC_URL +
  //     "/delete-icon.png'/>"
  //   );
  // };

  // const deleteItem = function(e, cell) {
  //   postMethod.current = "DELETE";

  //   Swal.fire({
  //     title: "Are you sure you want to delete?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete.",
  //     showClass: {
  //       popup: "animate__animated animate__fadeInDown"
  //     },
  //     hideClass: {
  //       popup: "animate__animated animate__fadeOutUp"
  //     }
  //   }).then(result => {
  //     if (result.isConfirmed) {
  //       dispatch(
  //         updatePageConfigData(
  //           postMethod.current,
  //           null,
  //           cell.getRow().getData().id
  //         )
  //       );
  //       Swal.fire("Item has been Deleted.", "", "success");
  //     }
  //   });
  // };

  const handleClose = () => {
    if (editorData !== content) {
      Swal.fire({
        html:
          "<strong>Changes made have not been saved, are you sure you want to continue?</strong>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(result => {
        if (result.isConfirmed) {
          setShow(false);
          setContentType("");
          setEditorData("");
          dispatch(onClear());
        }
      });
    } else {
      setShow(false);
      setContentType("");
      setEditorData("");
      dispatch(onClear());
    }
  };

  const onModalSave = function() {
    if (itemid === undefined || itemid === "") {
      if (
        _.find(
          pageConfig.data.data,
          o => {
            return (
              o.content_type.trim().toLowerCase() ===
              contentType.trim().toLowerCase()
            );
          },
          0
        ) !== undefined
      ) {
        Swal.fire({
          html:
            "<strong>An item with same Content Type already exists!</strong>",
          icon: "error"
        });
        return;
      }
    }
    if (contentType.trim() === "" && editorData.trim() === "") {
      Swal.fire({
        title: "Cannot save blank data.",
        icon: "warning"
      });
      return;
    }
    postMethod.current =
      itemid === undefined || itemid === "" ? "POST" : "PATCH";
    let data = {
      content_type: contentType,
      contents: editorData.replace(
        process.env.REACT_APP_API_BASE_URL,
        "[APIBASE]"
      ),
      page_id: pageid
    };
    dispatch(updatePageConfigData(postMethod.current, data, itemid));

    Swal.fire({
      title: "Item updated successfully.",
      icon: "success"
    });

    setShow(false);
    setContentType("");
    setEditorData("");
    dispatch(onClear());
  };

  let tableColumns = [
    {
      title: "Key",
      field: "content_type",
      headerFilter: "input",
      width: 200
    },
    {
      title: "Content",
      field: "contents",
      headerFilter: "input",
      formatter: function(cell, formatterParams, onRendered) {
        return DOMPurify.sanitize(cell.getValue());
      }
    },
    {
      title: " ",
      hozAlign: "center",
      width: 45,
      headerSort: false,
      formatter: editIcon,
      cellClick: editItem
    }
    // {
    //     title: " ",
    //     hozAlign: "left",
    //     width: 45,
    //     headerSort: false,
    //     formatter: deleteIcon,
    //     cellClick: deleteItem
    // }
  ];

  return (
    <>
      {accessUrl.current !== "" &&
        (!isAccess ? <Redirect to={accessUrl.current} /> : "")}
      {isAccess ? (
        <div className="page-config">
          <div className="table-heading">
            <DropDownComp />
            &nbsp;&nbsp;
            {/* <Image src={add} className="add-item" alt="Add New Item" onClick={addNewItem}></Image> */}
          </div>
          <div className="tabulator-wrapper">
            {pageid !== "" &&
            pageConfig !== undefined &&
            pageConfig.data !== undefined ? (
              <ReactTabulator
                tableRef={a => (tableRef.current = a)}
                columns={tableColumns}
                data={pageConfig?.data?.data}
                key={pageid}
                height="100%"
                layout="fitColumns"
              />
            ) : (
              <div className="pages-blank-placeholder">
                Please select a page to configure!
              </div>
            )}
          </div>

          <Modal
            size="xl"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="content-type pc-content-box">
                <label className="pc-content-label">Content Type</label>
                &nbsp;&nbsp;
                <input
                  type="text"
                  value={contentType}
                  onChange={e => setContentType(e.target.value)}
                  className="pc-content-type"
                  disabled={itemid !== undefined && itemid !== ""}
                />
              </div>
              <div id="toolbar-container"></div>
              {/* <CKEditor
                editor={DecoupledEditor} 
                config={ckConfig.current}
                data={editorData}
                onReady={(editor) => {
                  // The toolbar needs to be explicitly appended
                  document
                    .querySelector("#toolbar-container")
                    .appendChild(editor.ui.view.toolbar.element);
                  window.editor = editor;
                }}
                onChange={(event, editor) => setEditorData(editor.getData())}
              /> */}
              <TextEditor
                setContents={editorData}
                onChange={value => setEditorData(value)}
              />

              {/* <textarea value={editorData} className='editor'></textarea> */}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={onModalSave}>
                Save
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
