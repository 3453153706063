import apiAction from "./apiActionMethod";

export function fetchLanguageMaster() {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "masterData/languages/ACTIVE/dashboard",
    onSuccess: getLanguageMaster,
    onFailure: err =>
      console.log(
        "Error occured in fetching language master config data.",
        err
      ),
    label: "FETCH_MASTER_LANGUAGE_DATA"
  });
}

function getLanguageMaster(data) {
  return {
    type: "GET_LANGUAGE_MASTER",
    payload: data
  };
}

export function fetchLanguageTranslation(languageId) {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL + "masterData/languages/" + languageId,
    onSuccess: getLanguageTranslation,
    onFailure: err =>
      console.log("Error occured in fetching language translation data.", err),
    label: "FETCH_LANGUAGE_TRANSLATION"
  });
}

function getLanguageTranslation(data) {
  return {
    type: "GET_LANGUAGE_TRANSLATION",
    payload: data
  };
}

export function updateLanguageTranslation(dataToProcess, languageId) {
  return apiAction.updateAPI({
    url:
      process.env.REACT_APP_API_BASE_URL + "masterData/languages/" + languageId,
    method: "PATCH",
    data: dataToProcess,
    onSuccess: postLanguageTranslation,
    onFailure: err =>
      console.log("Error occured in updating language translation. ", err),
    label: "UPDATE_LANGUAGE_TRANSLATION"
  });
}

function postLanguageTranslation(data) {
  return {
    type: "POST_LANGUAGE_TRANSLATION",
    payload: data
  };
}
