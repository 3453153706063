const calculatorHistoryReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_CALCULATOR_HISTORY":
      return {
        ...state,
        Data: action.payload
      };
    case "API_START":
      if (action.payload === "FATCH_CALCULATOR_HISTORY") {
        return {
          ...state,
          isLoadingHistory: true
        };
      }
      break;
    case "API_END":
      if (action.payload === "FATCH_CALCULATOR_HISTORY") {
        return {
          ...state,
          isLoadingHistory: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default calculatorHistoryReducer;
