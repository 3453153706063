const masterDataConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_MASTER_CONFIG_DATA":
      return {
        ...state,
        data: action.payload,
        dataUpdated: false,
      };
    case "GET_ACTIVITY_LIST_DATA":
      return {
        ...state,
        activityList: action.payload,
        dataUpdated: false,
      };
    case "GET_DISTANCE_LIST_DATA":
      return {
        ...state,
        distanceList: action.payload,
        dataUpdated: false,
      };
    case "GET_SECTOR_LIST_DATA":
      return {
        ...state,
        sectorList: action.payload,
        dataUpdated: false,
      };
    case "GET_REGION_COUNTRY_DATA":
      return {
        ...state,
        regionCountryList: action.payload,
        dataUpdated: false,
      };
    case "GET_REGIONS_MASTER":
      return {
        ...state,
        regionsmaster: action.payload,
        dataUpdated: false,
      };
    case "GET_HCPSPECIALTY_LIST_MASTER":
      return {
        ...state,
        hcpSpecialtyListMaster: action.payload,
        dataUpdated: false,
      };
    case "CHECK_DUPLICATE_RECORDS":
      return {
        ...state,
        dataUpdated: false,
        duplicatecount: action.payload,
      };
    case "POST_MASTER_CONFIG_DATA":
      return {
        ...state,
        updatedData: action.payload,
      };
    case "GET_USERS_MASTER":
      return {
        ...state,
        usersmaster: action.payload,
        dataUpdated: false,
      };
    case "GET_ROLES_MASTER":
      return {
        ...state,
        rolesmaster: action.payload,
        dataUpdated: false,
      };
    case "GET_All_USERLIST_MASTER":
      return {
        ...state,
        alluserlist: action.payload,
      };
    case "API_START":
      if (action.payload === "FETCH_MASTER_CONFIG_DATA") {
        return {
          ...state,
          isLoadingData: true,
        };
      } else if (action.payload === "FETCH_DISTANCE_LIST_DATA") {
        return {
          ...state,
          isLoadingData: true,
        };
      } else if (action.payload === "FETCH_SECTOR_LIST_DATA") {
        return {
          ...state,
          isLoadingData: true,
        };
      } //
      else if (action.payload === "FETCH_REGION_COUNTRY_LIST_DATA") {
        return {
          ...state,
          isLoadingData: true,
        };
      } else if (action.payload === "UPDATE_MASTER_CONFIG_DATA") {
        return {
          ...state,
          isUpdatingData: true,
          dataUpdated: false,
        };
      } else if (action.payload === "FETCH_ACTIVITY_LIST_DATA") {
        return {
          ...state,
          isLoadingData: true,
        };
      } else if (action.payload === "CHECK_DUPLICATE") {
        return {
          ...state,
          isChecking: true,
          dataUpdated: false,
        };
      } else if (action.payload === "FETCH_MASTER_USERS_DATA") {
        return {
          ...state,
          isLoadingUserData: true,
        };
      } else if (action.payload === "FETCH_MASTER_ROLES_DATA") {
        return {
          ...state,
          isLoadingRolesData: true,
        };
      } else if (action.payload === "FETCH_ALL_USERLIST_DATA") {
        return {
          ...state,
          isLoadingAllUsrData: true,
        };
      }
      break;
    case "API_END":
      if (action.payload === "FETCH_MASTER_CONFIG_DATA") {
        return {
          ...state,
          isLoadingData: false,
        };
      } else if (action.payload === "UPDATE_MASTER_CONFIG_DATA") {
        return {
          ...state,
          isUpdatingData: false,
          dataUpdated: true,
        };
      } else if (action.payload === "CHECK_DUPLICATE") {
        return {
          ...state,
          isChecking: false,
          dataUpdated: false,
        };
      } else if (action.payload === "FETCH_MASTER_USERS_DATA") {
        return {
          ...state,
          isLoadingUserData: false,
        };
      } else if (action.payload === "FETCH_MASTER_ROLES_DATA") {
        return {
          ...state,
          isLoadingRolesData: false,
        };
      } else if (action.payload === "FETCH_ALL_USERLIST_DATA") {
        return {
          ...state,
          isLoadingAllUsrData: false,
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default masterDataConfigReducer;
