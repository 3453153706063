import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import addImage from "../assets/add.svg";
import deleteicon from "../assets/delete-icon.png";
import _ from "lodash";
import Swal from "sweetalert2";
import { ReactTabulator } from "react-tabulator";
import { ShowLoader } from "../helper/showLoader";
import {
  fetchKOLSectionDetail,
  fetchKOLAssessmentDetail,
  updateKOLAssessmentConfig,
  deleteKOLAssessmentConfig
} from "../actions/kolAssessment";

export function KOLAssessmentConfiguration() {
  let { itemtype } = useParams();
  let { posttype } = useParams();
  let { itemid } = useParams();

  const [sectionId, setSectionId] = useState("");
  const [section, setSection] = useState("");
  const [heading, setHeading] = useState("");
  const [sectionOrder, setSectionOrder] = useState("");
  const [isAddSection, setIsAddSection] = useState(false);
  const [isUpdateSection, setIsUpdateSection] = useState(false);
  const [isAddAssessment, setIsAddAssessment] = useState(false);
  const [isUpdateAssessment, setIsUpdateAssessment] = useState(false);
  const [isFreeQuestion, setIsFreeQuestion] = useState(false);

  const [sectionValidation, setSectionValidation] = useState(false);
  const [headingValidation, setHeadingValidation] = useState(false);
  const [assessmentValidation, setAssessmentValidation] = useState(false);

  const [assessmentId, setAssessmentId] = useState("");
  const [assessment, setAssessment] = useState("");
  const [assessmentOrder, setAssessmentOrder] = useState("");
  const [assessmentType, setAssessmentType] = useState("radio");
  const [assessmentHasOption, setAssessmentHasOption] = useState(false);
  const [assessmentScore, setAssessmentScore] = useState("");
  const [optionsTableColumns, setOptionsTableColumns] = useState([]);
  const [optionsTableData, setOptionsTableData] = useState([{}]);
  const [rowSelected, setRowSelected] = useState(false);

  const tableRef = useRef(null);
  const postMethod = useRef("");

  const kolSectionDetail = useSelector(
    state => state.kolAssessment.kolSectionDetail
  );
  const kolAssessmentDetail = useSelector(
    state => state.kolAssessment.kolAssessmentDetail
  );
  const kolAssessmentConfig = useSelector(
    state => state.kolAssessment.kolAssessmentConfig
  );
  const kolConfigDeleted = useSelector(
    state => state.kolAssessment.kolConfigDeleted
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (itemtype && itemtype !== null && itemid && itemid !== null) {
      if (itemtype.toUpperCase() === "SECTION") {
        if (posttype.toUpperCase() === "UPDATE") {
          setIsUpdateSection(true);
          setIsAddAssessment(false);
        } else {
          setIsUpdateSection(false);
          setIsAddAssessment(true);
        }
        dispatch(fetchKOLSectionDetail(itemid));
      } else if (itemtype.toUpperCase() === "ASSESSMENT") {
        setIsUpdateAssessment(true);
        dispatch(fetchKOLAssessmentDetail(itemid));
      }
    } else {
      setIsAddSection(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemtype, posttype, itemid]);

  useEffect(() => {
    if (kolSectionDetail && kolSectionDetail?.data) {
      setSectionId(kolSectionDetail?.data?.id);
      setSection(kolSectionDetail?.data?.section);
      setHeading(kolSectionDetail?.data?.heading);
      setSectionOrder(kolSectionDetail?.data?.order);

      getTableColumns();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kolSectionDetail]);

  useEffect(() => {
    if (kolAssessmentDetail && kolAssessmentDetail?.data) {
      dispatch(fetchKOLSectionDetail(kolAssessmentDetail?.data?.section_id));

      setAssessmentId(kolAssessmentDetail?.data?.assessment_id);
      setAssessment(kolAssessmentDetail?.data?.assessment);
      setAssessmentOrder(kolAssessmentDetail?.data?.order);
      setAssessmentType(
        kolAssessmentDetail?.data?.type !== null
          ? kolAssessmentDetail?.data?.type
          : "radio"
      );
      setAssessmentHasOption(kolAssessmentDetail?.data?.has_options);
      setAssessmentScore(kolAssessmentDetail?.data?.score);
      setOptionsTableData(
        _.sortBy(kolAssessmentDetail?.data?.options, "order")
      );
      getTableColumns();
      if (
        kolAssessmentDetail?.data?.type &&
        kolAssessmentDetail?.data?.type?.toUpperCase() === "FREE QUESTION"
      ) {
        setIsFreeQuestion(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kolAssessmentDetail]);

  useEffect(() => {
    if (kolAssessmentConfig && kolAssessmentConfig?.data) {
      if (isAddSection || isUpdateSection || isAddAssessment) {
        redirectToKOLDashboard();
      } else {
        window.location.reload();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kolAssessmentConfig]);

  useEffect(() => {
    if (kolConfigDeleted && kolConfigDeleted?.data) {
      if (
        kolConfigDeleted?.data?.options &&
        kolConfigDeleted?.data?.options.length > 0
      ) {
        let rowsToDelete = tableRef.current.table.getSelectedRows();
        _.forEach(rowsToDelete, o => {
          o.delete();
        });
        setRowSelected(false);
        Swal.fire(
          "",
          "<strong>Option deleted successfully.</strong>",
          "success"
        );
      } else {
        Swal.fire("", "<strong>Error in deleting options</strong>", "error");
      }
    }
  }, [kolConfigDeleted]);

  const getTableColumns = () => {
    let columns = [
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 10,
        cellClick: function(e, cell) {
          cell.getRow().toggleSelect();
        }
      },
      {
        title: "Option",
        field: "option",
        editor: "input",
        headerSort: false,
        tooltip: true
      },
      {
        title: "Score",
        field: "score",
        editor: "number",
        editorParams: { min: 0 },
        headerSort: false,
        tooltip: true,
        width: 150,
        validator: [
          {
            type: checkNegative
          }
        ]
      },
      {
        title: "Order",
        field: "order",
        editor: "number",
        editorParams: { min: 0 },
        headerSort: false,
        tooltip: true,
        width: 150,
        validator: [
          {
            type: checkNegative
          }
        ]
      }
    ];
    setOptionsTableColumns(columns);
  };

  const checkNegative = function(cell, value, parameters) {
    let check = true;
    if (value) {
      check = value > 0;
    }
    return check;
  };

  const OnChangeAssessmentType = e => {
    setAssessmentType(e.target.value);

    if (e.target.value.toUpperCase() === "YES/NO") {
      setAssessmentScore("");
      setAssessmentHasOption(true);
      setIsFreeQuestion(false);

      let options = [{ option: "Yes", score: "" }, { option: "No", score: "" }];
      setOptionsTableData(options);
    } else if (e.target.value.toUpperCase() === "FREE QUESTION") {
      setIsFreeQuestion(true);
      setAssessmentScore("");
    } else {
      setIsFreeQuestion(false);
      setAssessmentScore(kolAssessmentDetail?.data?.score);
      setAssessmentHasOption(kolAssessmentDetail?.data?.has_options);
      setOptionsTableData(
        _.sortBy(kolAssessmentDetail?.data?.options, "order")
      );
    }
  };

  const OnCheckHasOptions = e => {
    if (e.target.checked) {
      setAssessmentHasOption(true);
      setAssessmentScore("");
    } else {
      setAssessmentHasOption(false);
      setOptionsTableData([{}]);
    }
  };

  const addNewOption = () => {
    tableRef.current.table.addRow({}, true);
  };

  const deleteItem = () => {
    let dataToDelete = _.filter(tableRef.current.table.getSelectedData(), o => {
      return o.option_id !== undefined && o.option_id !== null;
    });
    if (dataToDelete.length > 0) {
      Swal.fire({
        html:
          "<strong>Are you sure you want to delete the selected item(s)?</strong>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#808080",
        focusConfirm: false,
        confirmButtonText: "Yes, delete.",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(result => {
        if (result.isConfirmed) {
          let data = {
            ids: _.map(dataToDelete, "option_id")
          };
          dispatch(deleteKOLAssessmentConfig(data, "option"));
        }
      });
    } else {
      let rowsToDelete = tableRef.current.table.getSelectedRows();
      _.forEach(rowsToDelete, o => {
        o.delete();
      });
      setRowSelected(false);
    }
  };

  const saveKOLConfiguration = () => {
    let validate = false;

    if (isAddSection || isUpdateSection) {
      if (section === null || section === "") {
        setSectionValidation(true);
        validate = true;
      } else {
        setSectionValidation(false);
      }

      if (heading === null || heading === "") {
        setHeadingValidation(true);
        validate = true;
      } else {
        setHeadingValidation(false);
      }
    } else {
      if (assessment === null || assessment === "") {
        setAssessmentValidation(true);
        validate = true;
      } else {
        setAssessmentValidation(false);
      }
    }
    if (validate) return;

    let dataToProcess = formatDataToProcess();

    postMethod.current = isAddSection || isAddAssessment ? "POST" : "PATCH";
    dispatch(
      updateKOLAssessmentConfig(
        postMethod.current,
        dataToProcess,
        itemtype,
        itemid
      )
    );
  };

  const formatDataToProcess = () => {
    let dataToProcess = {};

    if (isAddSection || isUpdateSection) {
      let sectionData = {
        section: section,
        heading: heading,
        order: sectionOrder
      };
      dataToProcess = {
        kolSection: sectionData
      };
      return dataToProcess;
    } else {
      let tabledata = [];
      if (assessmentHasOption) {
        tabledata = tableRef.current.table.getData();
        if (tabledata && tabledata.length > 0) {
          setOptionsTableData(tabledata);
        }
      }
      let assessmentData = {
        assessment: assessment,
        order: assessmentOrder,
        type: assessmentType,
        has_options: assessmentHasOption,
        score: assessmentScore,
        kolAssessmentOptions: tabledata && tabledata.length > 0 ? tabledata : []
      };

      if (isUpdateAssessment) {
        dataToProcess = {
          kolAssessments: [assessmentData]
        };
        return dataToProcess;
      } else if (isAddAssessment) {
        let sectionData = {
          section_id: sectionId,
          section: section,
          heading: heading,
          order: sectionOrder
        };
        dataToProcess = {
          kolSection: sectionData,
          kolAssessments: [assessmentData]
        };
        return dataToProcess;
      }
    }
  };

  const redirectToKOLDashboard = () => {
    window.location.href = "/kolassessments";
  };

  const handleKeyDown = event => {
    if (event.key === "-" || event.key === "+" || event.key === "e") {
      event.preventDefault();
    }
  };

  return (
    <div className="assessment-config">
      <ShowLoader></ShowLoader>
      <div>
        <Container className="assessment-config-header">
          <Row>
            <Col md={12}>
              <h4>KOL Assessment Configuration</h4>
            </Col>
          </Row>
        </Container>

        <Container className="assessment">
          <Row>
            <Col className="assessment-header" md={12}>
              Section Detail
            </Col>
          </Row>
          <Row>
            <Col md={1} className="label-name">
              Section
            </Col>
            <Col md={3}>
              <input
                id="kolassconfig_section_id"
                type="text"
                value={section}
                onChange={e => setSection(e.target.value)}
                className={sectionValidation ? "cal-val-red" : ""}
                disabled={isAddAssessment || isUpdateAssessment ? true : false}
              />
            </Col>
            <Col md={3}>
              <span
                className={
                  sectionValidation ? "calc-validation-msg" : "no-show"
                }
              >
                *Please enter Section
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={1} className="label-name">
              Heading
            </Col>
            <Col md={3}>
              <input
                id="kolassconfig_heading_id"
                type="text"
                value={heading}
                onChange={e => setHeading(e.target.value)}
                className={headingValidation ? "cal-val-red" : ""}
                disabled={isAddAssessment || isUpdateAssessment ? true : false}
              />
            </Col>
            <Col md={3}>
              <span
                className={
                  headingValidation ? "calc-validation-msg" : "no-show"
                }
              >
                *Please enter Heading
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={1} className="label-name">
              Order
            </Col>
            <Col md={2}>
              <input
                id="kolassconfig_order_id"
                type="number"
                min={0}
                onKeyDown={handleKeyDown}
                value={sectionOrder}
                onChange={e => setSectionOrder(e.target.value)}
                disabled={isAddAssessment || isUpdateAssessment ? true : false}
              />
            </Col>
          </Row>
        </Container>

        {isAddAssessment || isUpdateAssessment ? (
          <Container className="assessment">
            <Row>
              {assessmentId && assessmentId !== null ? (
                <Col className="assessment-header" md={12}>
                  Assessment Detail
                </Col>
              ) : (
                <Col className="assessment-header" md={12}>
                  Add Assessment
                </Col>
              )}
            </Row>
            <Row>
              <Col md={2} className="label-name">
                Assessment
              </Col>
              <Col md={8}>
                <input
                  id="kolassconfig_assessment_id"
                  type="text"
                  value={assessment}
                  onChange={e => setAssessment(e.target.value)}
                  className={assessmentValidation ? "cal-val-red" : ""}
                />
              </Col>
              <Col md={2}>
                <span
                  className={
                    assessmentValidation ? "calc-validation-msg" : "no-show"
                  }
                >
                  *Please enter Assessment
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={2} className="label-name">
                Order
              </Col>
              <Col md={2}>
                <input
                  id="kolassconfig_assessment_order_id"
                  type="number"
                  min={0}
                  onKeyDown={handleKeyDown}
                  value={assessmentOrder}
                  onChange={e => setAssessmentOrder(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={2} className="label-name">
                Type
              </Col>
              <Col md={2}>
                <select
                  className="select-config"
                  value={assessmentType}
                  onChange={OnChangeAssessmentType}
                  id="kolassconfig_assessment_type_id"
                >
                  <option
                    id="kolassconfig_assessmenttype_radio_id"
                    value="radio"
                  >
                    Radio
                  </option>
                  <option
                    id="kolassconfig_assessmenttype_yesno_id"
                    value="Yes/No"
                  >
                    Yes/No
                  </option>
                  <option
                    id="kolassconfig_assessmenttype_freequestion_id"
                    value="Free Question"
                  >
                    Free Question
                  </option>
                </select>
              </Col>
            </Row>
            {!isFreeQuestion ? (
              <Row>
                <Col md={2} className="label-name">
                  Has Options?
                </Col>
                <Col md={2}>
                  <div className="input-check">
                    <input
                      id="kolassconfig_hasoption_id"
                      type="checkbox"
                      onChange={OnCheckHasOptions}
                      checked={assessmentHasOption}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
            {!assessmentHasOption && !isFreeQuestion ? (
              <Row>
                <Col md={2} className="label-name">
                  Score
                </Col>
                <Col md={2}>
                  <input
                    id="kolassconfig_assessment_score_id"
                    type="number"
                    min={0}
                    onKeyDown={handleKeyDown}
                    value={assessmentScore}
                    onChange={e => setAssessmentScore(e.target.value)}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
            {assessmentHasOption && !isFreeQuestion ? (
              <Container className="options-container">
                <div className="add-option-header">
                  {assessmentType.toUpperCase() !== "YES/NO" ? (
                    <div className="add-option">
                      <Image
                        src={addImage}
                        className="add-item"
                        alt="Add New Option"
                        onClick={() => addNewOption()}
                      ></Image>{" "}
                      &nbsp;&nbsp;
                      <label className="label-name">Add New Option</label>
                    </div>
                  ) : (
                    ""
                  )}
                  {rowSelected ? (
                    <div className="delete-item">
                      <button onClick={() => deleteItem()}>
                        <Image src={deleteicon} alt="Delete"></Image>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <ReactTabulator
                  ref={a => (tableRef.current = a)}
                  columns={optionsTableColumns}
                  data={optionsTableData}
                  key={"optionstable"}
                  height="100%"
                  layout="fitColumns"
                  rowSelectionChanged={function(data, rows) {
                    if (rows.length > 0) setRowSelected(true);
                    else setRowSelected(false);
                  }}
                />
              </Container>
            ) : (
              ""
            )}
          </Container>
        ) : (
          ""
        )}
      </div>

      <div className="calc-config-buttons">
        <Button variant="secondary" onClick={redirectToKOLDashboard}>
          Back
        </Button>
        {isAddSection || isAddAssessment ? (
          <Button variant="success" onClick={saveKOLConfiguration}>
            Save
          </Button>
        ) : (
          <Button variant="success" onClick={saveKOLConfiguration}>
            Update
          </Button>
        )}
      </div>
    </div>
  );
}
