import apiAction from "./apiActionMethod";

export function checkMasterConfigDataDependency(configListName, dataToProcess) {
  return apiAction.updateAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "masterData/common/dependencyCheck/" +
      configListName.toLowerCase(),
    method: "POST",
    data: dataToProcess,
    onSuccess: getMasterConfigDataDependency,
    onFailure: err =>
      console.log(
        "Error occured in checking master config data dependency.",
        err
      ),
    label: "CHECK_MASTER_DATA_DEPENDENCY"
  });
}

function getMasterConfigDataDependency(data) {
  return {
    type: "GET_MASTER_DATA_DEPENDENCY",
    payload: data
  };
}
