import apiAction from "./apiActionMethod";

export function fetchPages() {
  return apiAction.getAPI({
    url: process.env.REACT_APP_API_BASE_URL + "pages",
    onSuccess: getPages,
    onFailure: err => console.log("Error occured in fetching pages.", err),
    label: "FETCH_PAGES"
  });
}
function getPages(data) {
  return {
    type: "GET_PAGES",
    payload: data
  };
}

export function fetchPageConfigData(pageId) {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "pageConfig/ACTIVE/" +
      pageId +
      "/dashboard",
    onSuccess: getPageConfigData,
    onFailure: err =>
      console.log("Error occured in fetching page config data.", err),
    label: "FETCH_PAGE_CONFIG_DATA"
  });
}
function getPageConfigData(data) {
  return {
    type: "GET_PAGE_CONFIG_DATA",
    payload: data
  };
}

export function fetchPageConfigDetail(contentId) {
  return apiAction.getAPI({
    url: process.env.REACT_APP_API_BASE_URL + "pageConfig/" + contentId,
    onSuccess: getPageConfigDetail,
    onFailure: err =>
      console.log("Error occured in fetching page config detail.", err),
    label: "FETCH_PAGE_CONFIG_DETAIL"
  });
}
function getPageConfigDetail(data) {
  return {
    type: "GET_PAGE_CONFIG_DETAIL",
    payload: data
  };
}

export function updatePageConfigData(postMethod, dataToProcess, itemId) {
  let apiURL =
    postMethod.toUpperCase() === "POST"
      ? process.env.REACT_APP_API_BASE_URL + "pageConfig/"
      : process.env.REACT_APP_API_BASE_URL + "pageConfig/" + itemId;

  return apiAction.updateAPI({
    url: apiURL,
    method: postMethod,
    data: dataToProcess,
    onSuccess: postPageConfigData,
    onFailure: err =>
      console.log("Error occured in updating page config data.", err),
    label: "UPDATE_PAGE_CONFIG_DATA"
  });
}
function postPageConfigData(data) {
  return {
    type: "POST_PAGE_CONFIG_DATA",
    payload: data
  };
}

export function onClear() {
  return {
    type: "CLEAR_DATA"
  };
}
