import React, { Component } from "react";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true
    };
  }
  render() {
    if (this.state.hasError) {
      if (window.location.href.includes("fmvcalculator")) {
        return <h2>There are validation errors in this calculator.</h2>;
      } else {
        return (
          <div className="error-boundary-wrapper">
            <div className="error-boundary">
              <h4>There has been an issue with connecting to the website.</h4>
              <h5>
                Please try refreshing this page. If the problem persists, please
                close your browser and re-load.
              </h5>
            </div>
          </div>
        );
      }
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
