//GET_USER_INFO_AUTH
const userInfoAuthReducer = (state = {}, action) => {
    switch (action.type) {
      case "GET_USER_INFO_AUTH":
        return {
          ...state,
          data: action.payload
        };
        case 'GET_USER_INFO_AUTH_USER_DETAILS':
         return {
            ...state,
            data1: action.payload
         }   
      /*case "API_START":
        return {
          ...state,
          isLoadingData: true
        };
      case "API_END":
        return {
          ...state,
          isLoadingData: false
        };*/
      default:
        return state;
    }  
  };
  
  export default userInfoAuthReducer;
  