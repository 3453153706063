import apiAction from "./apiActionMethod";
import { getAuthToken } from "../authentication/authToken";
//import { useDispatch } from "react-redux";

export function fetchUserInformation(userId) {
  return apiAction.getAPI({
    url: process.env.REACT_APP_API_BASE_URL + "ldaps/" + userId,
    onSuccess: getUserInformation,
    onFailure: err =>
      console.log("Error occured in fetching user information.", err),
    label: "FETCH_USER_INFO"
  });
}

function getUserInformation(data) {
  return {
    type: "GET_USER_INFO",
    payload: data,
  };
}