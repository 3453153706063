const dependencyCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_MASTER_DATA_DEPENDENCY":
      return {
        ...state,
        dependencycheckdata: action.payload
      };
    case "API_START":
      if (action.payload === "CHECK_MASTER_DATA_DEPENDENCY") {
        return {
          ...state,
          isLoadingDependency: true
        };
      }
      break;
    case "API_END":
      if (action.payload === "CHECK_MASTER_DATA_DEPENDENCY") {
        return {
          ...state,
          isLoadingDependency: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default dependencyCheckReducer;
