const translationReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_LANGUAGE_MASTER":
      return {
        ...state,
        languagemaster: action.payload
      };
    case "GET_LANGUAGE_TRANSLATION":
      return {
        ...state,
        languageTranslation: action.payload
      };
    case "POST_LANGUAGE_TRANSLATION":
      return {
        ...state,
        languageTranslationData: action.payload
      };
    case "API_START":
      if (action.payload === "FETCH_MASTER_LANGUAGE_DATA") {
        return {
          ...state,
          isLoadingLanguagesData: true
        };
      } else if (action.payload === "FETCH_LANGUAGE_TRANSLATION") {
        return {
          ...state,
          isLoadingTranslationData: true
        };
      } else if (action.payload === "UPDATE_LANGUAGE_TRANSLATION") {
        return {
          ...state,
          isUpdatingData: true
        };
      }
      break;
    case "API_END":
      if (action.payload === "FETCH_MASTER_LANGUAGE_DATA") {
        return {
          ...state,
          isLoadingLanguagesData: false
        };
      } else if (action.payload === "FETCH_LANGUAGE_TRANSLATION") {
        return {
          ...state,
          isLoadingTranslationData: false
        };
      } else if (action.payload === "UPDATE_LANGUAGE_TRANSLATION") {
        return {
          ...state,
          isUpdatingData: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default translationReducer;
