import apiAction from "./apiActionMethod";

export function UpdateEndUserActivity(dataToProcess) {
  return apiAction.updateAPI({
    url: process.env.REACT_APP_API_BASE_URL + "endUserActivity",
    method: "POST",
    data: dataToProcess,
    onSuccess: postEndUserActivity,
    onFailure: err =>
      console.log("Error occured in updating end user activity. ", err),
    label: "UPDATE_END_USER_ACTIVITY"
  });
}

function postEndUserActivity(data) {
  return {
    type: "POST_END_USER_ACTIVITY",
    payload: data
  };
}

export function setEndUserSessionUuid(data) {
  return {
    type: "SET_END_USER_SESSION_UUID",
    payload: data
  };
}
