const kolAssessmentReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_KOL_DASHBOARD_DATA":
      return {
        ...state,
        kolDashboardData: action.payload
      };
    case "GET_KOL_SECTION_DETAIL":
      return {
        ...state,
        kolSectionDetail: action.payload
      };
    case "GET_KOL_ASSESSMENT_DETAIL":
      return {
        ...state,
        kolAssessmentDetail: action.payload
      };
    case "POST_KOL_ASSESSMENT_CONFIG":
      return {
        ...state,
        kolAssessmentConfig: action.payload
      };
    case "DELETE_KOL_CONFIG_DATA":
      return {
        ...state,
        kolConfigDeleted: action.payload
      };

    case "API_START":
      if (
        action.payload === "FETCH_KOL_DASHBOARD_DATA" ||
        action.payload === "FETCH_KOL_SECTION_DETAIL" ||
        action.payload === "FETCH_KOL_ASSESSMENT_DETAIL"
      ) {
        return {
          ...state,
          isLoadingKolData: true
        };
      } else if (action.payload === "UPDATE_KOL_ASSESSMENT_CONFIG") {
        return {
          ...state,
          isUpdatingKolData: true
        };
      } else if (action.payload === "DELETE_KOL_ASSESSMENT_CONFIG") {
        return {
          ...state,
          isDeletingKolData: true
        };
      }
      break;
    case "API_END":
      if (
        action.payload === "FETCH_KOL_DASHBOARD_DATA" ||
        action.payload === "FETCH_KOL_SECTION_DETAIL" ||
        action.payload === "FETCH_KOL_ASSESSMENT_DETAIL"
      ) {
        return {
          ...state,
          isLoadingKolData: false
        };
      } else if (action.payload === "UPDATE_KOL_ASSESSMENT_CONFIG") {
        return {
          ...state,
          isUpdatingKolData: false
        };
      } else if (action.payload === "DELETE_KOL_ASSESSMENT_CONFIG") {
        return {
          ...state,
          isDeletingKolData: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default kolAssessmentReducer;
