const appMessagesReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_APP_MESSAGE_PAGES":
      return {
        ...state,
        appMessagePages: action.payload
      };
    case "GET_APP_CONFIG_MESSAGES_DATA":
      return {
        ...state,
        pageAppMessageData: action.payload
      };
    case "API_START":
      if (
        action.payload === "FETCH_APP_MESSAGE_PAGES" ||
        action.payload === "FETCH_APP_CONFIG_MESSAGES_DATA"
      ) {
        return {
          ...state,
          isAppMessageLoadingData: true
        };
      }
      break;
    case "API_END":
      if (
        action.payload === "FETCH_APP_MESSAGE_PAGES" ||
        action.payload === "FETCH_APP_CONFIG_MESSAGES_DATA"
      ) {
        return {
          ...state,
          isAppMessageLoadingData: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default appMessagesReducer;
