import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Button,
  Popover,
  OverlayTrigger
} from "react-bootstrap";
import { ReactTabulator } from "react-tabulator";
import {
  fetchMasterData,
  setHCPSpecialtyData,
  setIsDataProcessedForSaveAsNew,
  setIsExecutingAction
} from "../actions/calcConfig";
import _ from "lodash";
import { Calc_Tabs } from "../helper/constants";
import Swal from "sweetalert2";
import { ShowLoader } from "../helper/showLoader";

export function HCPSpecialty({ tabChangeHandler }) {
  const dispatch = useDispatch();

  const tableRef = useRef(null);
  // const tableSourceRef = useRef(null);
  const segmentOptions = useRef({});
  const segmentFilterOptions = useRef({});
  const specialtyGroupOptions = useRef({});

  const [sourceData, setSourceData] = useState([]);
  // const [senderData, setSenderData] = useState([]);
  const [specialityMapped, setSpecialityMapped] = useState(false);
  const [receiverData, setReceiverData] = useState([]);
  const [rowSelected, setRowSelected] = useState(false);
  // const [senderColumns, setSenderColumns] = useState([]);
  const [receiverColumns, setReceiverColumns] = useState([]);
  const [tabledata, setTableData] = useState(null);
  const [sourceHeader, setSourceHeader] = useState("");
  // const [showRemoveFilter, setShowRemoveFilter] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [initializeData, setInitializeData] = useState(false);
  const [assignSegment, setAssignSegment] = useState("");
  const [isAssignedSegmentchanged, setIsAssignedSegmentchanged] = useState(
    false
  );
  const [startAssigningSegment, setStartAssigningSegment] = useState(false);
  const [isUnAssignedSegmentclicked, setIsUnAssignedSegmentclicked] = useState(
    false
  );
  const [startUnAssigningSegment, setStartUnAssigningSegment] = useState(false);
  const [selectedSpecialtyGroup, setSelectedSpecialtyGroup] = useState("");
  const [isSpecialtyGroupSelected, setIsSpecialtyGroupSelected] = useState(
    false
  );
  const [startLoadingSpecialty, setStartLoadingSpecialty] = useState(false);

  const calcConfigHCPSpecialty = useSelector(
    state => state.calcConfig?.calcConfigData?.data?.calcConfigHcpSpeciality
  );
  const calcConfigGroupId = useSelector(
    state =>
      state.calcConfig?.calcConfigData?.data?.calcConfigBasic
        ?.hcp_speciality_group
  );
  const hcpSpecialtyMaster = useSelector(
    state => state.calcConfig.hcpSpecialtyMasterData
  );
  const hcpSpecialtyGroupMaster = useSelector(
    state => state.calcConfig.hcpSpecialtyGroupMasterData
  );
  const segmentsMaster = useSelector(
    state => state.calcConfig.segmentsMasterData
  );
  const saveAsNew = useSelector(state => state.calcConfig.saveAsNew);
  const isExecuting = useSelector(state => state.calcConfig.isExecuting);

  useEffect(() => {
    dispatch(fetchMasterData("hcp_specialities"));
    dispatch(fetchMasterData("hcp_speciality_list"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (segmentsMaster !== undefined) {
      segmentFilterOptions.current = _.mapValues(
        _.keyBy(segmentsMaster?.data, "id"),
        "value"
      );

      segmentOptions.current = segmentsMaster?.data.map((obj, i) => {
        return (
          <Dropdown.Item key={i} eventKey={obj.id} value={obj.id}>
            {obj.value}
          </Dropdown.Item>
        );
      });
    }
  }, [segmentsMaster]);

  useEffect(() => {
    // let tableColumns = [
    //   {
    //     title: "HCP Specialty",
    //     field: "hcp_speciality",
    //     headerFilter: "input",
    //     headerFilterPlaceholder: "Search by Name",
    //     tooltip: true
    //   }
    // ];
    // setSenderColumns(tableColumns);

    let table2Columns = [
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        hozAlign: "center",
        headerSort: false,
        width: 10,
        cellClick: function(e, cell) {
          cell.getRow().toggleSelect();
        }
      },
      {
        title: "HCP Specialty",
        field: "hcp_speciality",
        headerFilter: "input",
        headerFilterPlaceholder: "Search by Name",
        tooltip: true
      },
      {
        title: "Market consensus",
        field: "segment_id",
        editor: "select",
        formatter: "lookup",
        formatterParams: segmentFilterOptions.current,
        editorParams: {
          allowEmpty: false,
          // values: true,
          values: segmentFilterOptions.current
        },
        headerFilter: "select",
        headerFilterPlaceholder: "Filter",
        headerFilterParams: { values: segmentFilterOptions.current },
        tooltip: true,
        width: 180
      },
      {
        title: "Comments",
        field: "comments",
        editor: "input",
        headerSort: false,
        headerFilter: "input",
        headerFilterPlaceholder: "Search",
        tooltip: true
      }
    ];
    setReceiverColumns(table2Columns);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentFilterOptions.current]);

  useEffect(() => {
    if (hcpSpecialtyGroupMaster !== undefined) {
      specialtyGroupOptions.current = hcpSpecialtyGroupMaster?.data.map(
        (obj, i) => {
          return (
            <Dropdown.Item key={i} eventKey={obj.id} value={obj.id}>
              {obj.value}
            </Dropdown.Item>
          );
        }
      );
    }
  }, [hcpSpecialtyGroupMaster]);

  useEffect(() => {
    if (hcpSpecialtyMaster !== undefined) {
      setSourceData(
        _.map(hcpSpecialtyMaster.data, row => {
          row.hcp_speciality_id = row.id;
          return row;
        })
      );
      setSourceHeader("Select Specialty Group");
    }
  }, [hcpSpecialtyMaster]);

  useEffect(() => {
    if (
      calcConfigGroupId !== undefined &&
      calcConfigGroupId !== null &&
      calcConfigGroupId !== ""
    ) {
      setGroupId(calcConfigGroupId);
      let data = _.filter(hcpSpecialtyMaster?.data, row => {
        return (
          _.find(row.mapping, obj => {
            return obj.id === calcConfigGroupId;
          }) !== undefined
        );
      });
      let srcData = [],
        tempdata = [];
      if (receiverData?.length > 0 && initializeData) {
        setSpecialityMapped(true);
        receiverData.map(o => {
          return data?.map((i, index) => {
            if (o.id === i.id) {
              srcData = _.filter(data, s => s.id !== i.id);
              data = srcData;
            }
            return data;
          });
        });
        tempdata = receiverData;
      }
      if (data?.length > 0 && initializeData) {
        _.map(data, o => tempdata.push(o));
        setReceiverData(tempdata);
        tableRef.current?.table.replaceData(tempdata);
      }
      setSourceHeader(
        "Specialties for " +
          _.find(hcpSpecialtyGroupMaster?.data, obj => {
            return obj.id === calcConfigGroupId;
          })?.value
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcConfigGroupId, initializeData]);

  useEffect(() => {
    if (calcConfigHCPSpecialty !== undefined) {
      _.forEach(calcConfigHCPSpecialty, function(obj) {
        _.map(sourceData, row => {
          if (row.hcp_speciality_id === obj.hcp_speciality_id) {
            Object.assign(row, obj);
            row.isReceived = true;
          }
          return row;
        });
      });
      setSourceData(
        _.filter(sourceData, obj => {
          return !obj.isReceived;
        })
      );

      setReceiverData(
        _.filter(sourceData, obj => {
          return obj.isReceived;
        })
      );
      setTableData(
        _.filter(sourceData, obj => {
          return obj.isReceived;
        })
      );
      setInitializeData(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcConfigHCPSpecialty]);

  useEffect(() => {
    if (saveAsNew) {
      let propsToDelete = [
        "item_id",
        "calc_config_id",
        "calc_config_basic_id",
        "updated_at",
        "updated_by"
      ];

      let receiver_data = _.forEach(receiverData, obj => {
        _.forEach(propsToDelete, prop => {
          delete obj[prop];
        });
      });
      let table_data = _.forEach(tabledata, obj => {
        _.forEach(propsToDelete, prop => {
          delete obj[prop];
        });
      });
      setReceiverData(receiver_data);
      setTableData(table_data);
      dispatch(
        setHCPSpecialtyData({
          tableData: table_data,
          hcpSpecialtyGroupIdData: groupId
        })
      );
      dispatch(setIsDataProcessedForSaveAsNew({ hcpSpecialtyProcessed: true }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAsNew]);

  useEffect(() => {
    tabChangeHandler.current[Calc_Tabs.TAB_HCPSpecialty] = {
      getTabData: getTabData
    };
    // if(tableSourceRef.current && tableSourceRef.current.table){
    //     tableSourceRef.current.table.on('movableRowsElementDrop', ()=>{ alert(arguments)})
    // }
    getTabData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabledata, groupId]);

  useEffect(() => {
    if (
      isExecuting &&
      isAssignedSegmentchanged &&
      assignSegment !== undefined &&
      assignSegment !== null &&
      assignSegment !== ""
    ) {
      setTimeout(() => {
        setStartAssigningSegment(true);
      }, 1000);
    }
    if (isExecuting && isUnAssignedSegmentclicked) {
      setTimeout(() => {
        setStartUnAssigningSegment(true);
      }, 1000);
    }
    if (
      isExecuting &&
      isSpecialtyGroupSelected &&
      selectedSpecialtyGroup !== undefined &&
      selectedSpecialtyGroup !== null &&
      selectedSpecialtyGroup !== ""
    ) {
      setTimeout(() => {
        setStartLoadingSpecialty(true);
      }, 1000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isExecuting,
    assignSegment,
    isAssignedSegmentchanged,
    isSpecialtyGroupSelected,
    selectedSpecialtyGroup
  ]);

  useEffect(() => {
    if (startAssigningSegment) {
      let allrows = tableRef.current.table.getSelectedRows();
      _.forEach(allrows, function(rowObj) {
        rowObj?.getCell("segment_id")?.setValue(assignSegment);
      });
      dispatch(setIsExecutingAction(false));
      setAssignSegment("");
      setIsAssignedSegmentchanged(false);
      setStartAssigningSegment(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAssigningSegment]);

  useEffect(() => {
    if (startUnAssigningSegment) {
      _.forEach(tableRef.current.table.getSelectedRows(), function(rowObj) {
        if (
          rowObj?.getData().segment_id &&
          rowObj?.getData().segment_id !== undefined &&
          rowObj?.getData().segment_id !== ""
        ) {
          let val = "";
          rowObj?.getCell("segment_id")?.setValue(val);
        }
      });
      dispatch(setIsExecutingAction(false));
      setIsUnAssignedSegmentclicked(false);
      setStartUnAssigningSegment(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startUnAssigningSegment]);

  useEffect(() => {
    if (startLoadingSpecialty) {
      setSourceHeader(
        "Specialties for " +
          _.find(hcpSpecialtyGroupMaster.data, obj => {
            return obj.id === selectedSpecialtyGroup;
          }).value
      );
      setReceiverData(
        _.filter(hcpSpecialtyMaster?.data, row => {
          return (
            _.find(row.mapping, obj => {
              return obj.id === selectedSpecialtyGroup;
            }) !== undefined
          );
        })
      );
      setGroupId(selectedSpecialtyGroup);
      tableRef.current?.table.replaceData(
        _.filter(hcpSpecialtyMaster?.data, row => {
          return (
            _.find(row.mapping, obj => {
              return obj.id === selectedSpecialtyGroup;
            }) !== undefined
          );
        })
      );
      dispatch(setIsExecutingAction(false));
      setSelectedSpecialtyGroup("");
      setIsSpecialtyGroupSelected(false);
      setStartLoadingSpecialty(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startLoadingSpecialty]);

  const getTabData = function() {
    dispatch(
      setHCPSpecialtyData({
        tableData: tabledata,
        hcpSpecialtyGroupIdData: groupId
      })
    );
  };

  function onCellEdited(cell) {
    if (
      cell
        .getRow()
        .getTable()
        .getData() !== undefined
    ) {
      setSpecialityMapped(true);
      let selectedData = _.filter(
        cell
          .getRow()
          .getTable()
          .getData(),
        o => {
          return o.segment_id !== undefined && o.segment_id !== "";
        },
        0
      );

      let mappedData = [];

      if (selectedData !== undefined && selectedData.length > 0) {
        mappedData = _.map(selectedData, o => {
          return {
            item_id: o.item_id,
            calc_config_id: o.calc_config_id,
            calc_config_basic_id: o.calc_config_basic_id,
            hcp_speciality_id: o.hcp_speciality_id,
            segment_id: o.segment_id,
            comments: o.comments
          };
        });
        setTableData(mappedData);
      }
      dispatch(setHCPSpecialtyData({ tableData: mappedData }));
    }
  }

  const filterByGroup = e => {
    // let data = hcpSpecialtyMaster?.data;
    if (groupId === e) {
      let groupname = _.find(hcpSpecialtyGroupMaster.data, obj => {
        return obj.id === e;
      }).value;
      let mssg =
        "<strong>Specialties for " +
        "'" +
        groupname +
        "'" +
        " already selected.</strong>";
      Swal.fire("", mssg, "warning");
    } else {
      if (tableRef.current?.table?.getData()?.length > 0 && specialityMapped) {
        // html: "<strong>Are you sure you want to change the filter?</strong><h6> It will remove all the associated specialties and segments and you will have to mapped from scratch</h6>",
        Swal.fire({
          html:
            "<strong>Are you sure you want to change the filter?</strong><h6> It will remove all the associated specialties.</h6>",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#808080",
          focusConfirm: false,
          confirmButtonText: "Yes!",
          showClass: {
            popup: "animate__animated animate__fadeInDown"
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp"
          }
        }).then(result => {
          if (result.isConfirmed) {
            setSelectedSpecialtyGroup(e);
            setIsSpecialtyGroupSelected(true);
            dispatch(setIsExecutingAction(true));
          }
        });
      } else {
        setSelectedSpecialtyGroup(e);
        setIsSpecialtyGroupSelected(true);
        dispatch(setIsExecutingAction(true));
      }
    }
    // setShowRemoveFilter(true);
  };

  // const handleRemoveFilter = e => {
  //   if (e.target.checked) {
  //     let data = hcpSpecialtyMaster?.data;
  //     let srcData = [];
  //     if (tableRef.current?.table?.getData()?.length > 0) {
  //       tableRef.current?.table?.getData()?.map(o => {
  //         data?.map((i, index) => {
  //           if (o.id === i.id) {
  //             srcData = _.filter(data, s => s.id !== i.id);
  //             data = srcData;
  //           }
  //         });
  //       });
  //     }
  //     setSourceData(data);
  //     setSourceHeader("All HCP Specialties");
  //     // setShowRemoveFilter(false);
  //   }
  // };

  const assignSegmentOnClick = e => {
    setAssignSegment(e);
    setIsAssignedSegmentchanged(true);
    dispatch(setIsExecutingAction(true));
  };

  const unAssignSegmentOnClick = () => {
    setIsUnAssignedSegmentclicked(true);
    dispatch(setIsExecutingAction(true));
  };

  // const optionsSender = {
  //     movableRows: true,
  //     movableRowsConnectedTables: "#example-table-receiver",
  //     // movableRowsElementDrop: ()=>{ alert(arguments)},
  //     movableRowsReceiver: "add",
  //     movableRowsSender: "delete",
  // };
  // const optionsReceiver = {
  //     movableRows: true,
  //     movableRowsConnectedTables: "#example-table-sender",
  //     // movableRowsElementDrop: ()=>{ alert(arguments)},
  //     movableRowsReceiver: "add",
  //     movableRowsSender: "delete"
  // };

  // const addAllSpecialties = () => {
  //   let data = tableSourceRef?.current?.table?.getData();
  //   let FilteredData = tableRef?.current?.table?.getData();
  //   let srcData = [];
  //   if (data !== undefined) {
  //     tableRef.current?.table?.getData()?.map(o => {
  //       data?.map((i, index) => {
  //         if (o.hcp_speciality_id === i.hcp_speciality_id) {
  //           srcData = _.filter(data, s => s.id !== i.id);
  //           data = srcData;
  //         }
  //       });
  //     });
  //     if (data !== undefined) {
  //       _.map(data, obj => {
  //         FilteredData.push(obj);
  //       });
  //     }
  //     setReceiverData(FilteredData);
  //     // setSenderData([]);
  //     tableSourceRef.current?.table.replaceData([]);
  //   }
  // };

  const selectUnassignedSpecialty = () => {
    if (tableRef.current.table.getData() !== undefined) {
      let rows = tableRef.current.table.getRows();
      _.map(tableRef.current?.table.getData(), (o, index) => {
        if (o.segment_id === undefined || o.segment_id === "") {
          // let cells = rows[index].getCells();
          rows[index].select();
        } else {
          rows[index].deselect();
        }
      });
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Please filter specialties by group and select row to assign or unassign
        segment.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="hcp-specialty">
      <ShowLoader></ShowLoader>
      <Row>
        <Col md={12}>
          <div className="child-receiver">
            <div className="receiver-header">
              <div className="child-header">
                {sourceHeader}
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  rootClose={true}
                  overlay={popover}
                >
                  <Button className="Info-btn-tbulator specialty-info-icon">
                    i
                  </Button>
                </OverlayTrigger>
                <DropdownButton
                  as={ButtonGroup}
                  key="start"
                  id="filter-by-group"
                  drop="start"
                  variant="secondary"
                  title="Filter by Group"
                  className="hcp-filter-btn"
                  onSelect={filterByGroup}
                >
                  {specialtyGroupOptions.current}
                </DropdownButton>
                {tableRef?.current?.table?.getData()?.length > 0 ? (
                  <Button
                    variant="secondary"
                    className="hcp-filter-btn addAllSpeciality"
                    onClick={selectUnassignedSpecialty}
                  >
                    Select Unassigned Specialties
                  </Button>
                ) : (
                  ""
                )}
                {rowSelected ? (
                  <Button
                    variant="secondary"
                    className="hcp-filter-btn addAllSpeciality"
                    onClick={unAssignSegmentOnClick}
                  >
                    Unassign Segment
                  </Button>
                ) : (
                  ""
                )}
              </div>

              <div className="child-header-button">
                {rowSelected ? (
                  <DropdownButton
                    as={ButtonGroup}
                    key="start"
                    id="assign-segment"
                    drop="start"
                    variant="secondary"
                    title="Assign Segment"
                    className="hcp-filter-btn"
                    onSelect={assignSegmentOnClick}
                  >
                    {segmentOptions.current}
                  </DropdownButton>
                ) : (
                  ""
                )}
              </div>
            </div>

            {hcpSpecialtyMaster !== undefined && hcpSpecialtyMaster?.data ? (
              <div className="tabulator-speciality-div">
                <ReactTabulator
                  ref={a => (tableRef.current = a)}
                  columns={receiverColumns}
                  data={receiverData}
                  placeholder={"Please filter by group"}
                  rowSelectionChanged={function(data, rows) {
                    if (rows.length > 0) {
                      setRowSelected(true);
                    } else {
                      setRowSelected(false);
                    }
                  }}
                  cellEdited={onCellEdited}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
