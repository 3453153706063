import apiAction from "./apiActionMethod";

export function fetchCountrySectorOptions() {
  return apiAction.getAPI({
    url: process.env.REACT_APP_API_BASE_URL + "endUserCalcCountriesAndSectors",
    onSuccess: getCountrySectorOptions,
    onFailure: err =>
      console.log(
        "Error occured in fetching Market-Sector Options of all Published Calculators. ",
        err
      ),
    label: "FETCH_COUNTRY_SECTOR_OPTIONS"
  });
}

function getCountrySectorOptions(data) {
  return {
    type: "GET_COUNTRY_SECTOR_OPTIONS",
    payload: data
  };
}

export function fetchCalculatorData(dataToProcess) {
  return apiAction.updateAPI({
    url: process.env.REACT_APP_API_BASE_URL + "endUserCalc",
    method: "POST",
    data: dataToProcess,
    onSuccess: getCalculatorData,
    onFailure: err =>
      console.log("Error occured in fetching Calculator data. ", err),
    label: "FETCH_CALCULATOR_DATA"
  });
}

function getCalculatorData(data) {
  return {
    type: "GET_CALCULATOR_DATA",
    payload: data
  };
}

export function previewCalculator(dataToProcess) {
  return apiAction.updateAPI({
    url: process.env.REACT_APP_API_BASE_URL + "previewCalc",
    method: "POST",
    data: dataToProcess,
    onSuccess: previewCalculatorData,
    onFailure: err =>
      console.log("Error occured in preview Calculator data. ", err),
    label: "PREVIEW_CALCULATOR_DATA"
  });
}

function previewCalculatorData(data) {
  return {
    type: "PREVIEW_END_USER_CALCULATOR_DATA",
    payload: data
  };
}

export function setSelectedTransLanguage(data) {
  return {
    type: "SET_SELECTED_TRANS_LANGUAGE",
    payload: data
  };
}

export function setSelectedTranslationData(data) {
  return {
    type: "SET_SELECTED_TRANSLATION_DATA",
    payload: data
  };
}

export function setCountrySectorNameData(data) {
  return {
    type: "SET_COUNTRY_SEACTOR_NAME",
    payload: data
  };
}

export function setIsPreviewCalculatorData(data) {
  return {
    type: "SET_ISPREVIEW_CALCULATOR_DATA",
    payload: data
  };
}

export function setDraftVersionStatus(data) {
  return {
    type: "SET_DRAFT_VERSION_STATUS",
    payload: data
  };
}

export function setCalculatorHeaderData(data) {
  return {
    type: "SET_CALCULATOR_HEADER_DATA",
    payload: data
  };
}

export function resetCalculatorData(data) {
  return {
    type: "RESET_CALCULATOR_DATA",
    payload: data
  };
}
