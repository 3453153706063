import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import showtoast from "../helper/showToastAlert";
import _ from "lodash";
import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css"; // use Theme(s)
import { ReactTabulator } from "react-tabulator";
import { Image, Popover, OverlayTrigger, Button } from "react-bootstrap";
import add from "../assets/add.svg";
import deleteicon from "../assets/delete-icon.png";
import MultiValueFormatter from "react-tabulator/lib/formatters/MultiValueFormatter";
import MultiSelectEditor from "react-tabulator/lib/editors/MultiSelectEditor"; 
import {
  fetchMasterConfigData,
  fetchRegionsMaster,
  fetchHCPSpecialtyListMaster,
  checkDuplicate,
  updateMasterConfigData,
  fetchActivityList,
  fetchDistanceList,
  fetchSectorList,
  fetchRegionCountryList,
} from "../actions/masterDataConfig";
import { checkMasterConfigDataDependency } from "../actions/dependencyCheck";
import { ShowLoader } from "../helper/showLoader";

let id1 = 1;

export function MasterDataConfiguration() {
  let { listname } = useParams();
  const dispatch = useDispatch();

  const [tableColumns, setTableColumns] = useState([]);
  const [regionLoaded, setRegionLoaded] = useState(false);
  const [hcpSpecialtyListLoaded, setHCPSpecialtyListLoaded] = useState(false);
  const [duplicateChecked, setduplicateChecked] = useState(false);
  const [rowSelected, setRowSelected] = useState(false);
  const [isAccess, setIsAccess] = useState(false);

  const masterData = useSelector((state) => state.masterDataConfig);
  const activityList = useSelector(
    (state) => state.masterDataConfig.activityList
  );
  const distanceList = useSelector(
    (state) => state.masterDataConfig.distanceList
  );
  const sectorList = useSelector((state) => state.masterDataConfig.sectorList);
  const regionCountryList = useSelector(
    (state) => state.masterDataConfig.regionCountryList
  );

  const regionsMaster = useSelector(
    (state) => state.masterDataConfig.regionsmaster
  );

  const hcpSpecialtyListMaster = useSelector(
    (state) => state.masterDataConfig.hcpSpecialtyListMaster
  );
  const duplicateResponse = useSelector(
    (state) => state.masterDataConfig.duplicatecount
  );
  const dataUpdated = useSelector(
    (state) => state.masterDataConfig.dataUpdated
  );
  const userInformation = useSelector((state) => state.userInformation);
  const dependencyCheckData = useSelector(
    (state) => state.dependencyCheck?.dependencycheckdata
  );

  const regionOptions = useRef({});
  const hcpSpecialtyListOptions = useRef({});
  const postMethod = useRef("");
  const postItemId = useRef("");
  const postData = useRef({});
  const refCell = useRef({});
  const cols = useRef([]);
  const tableName = useRef("");
  const ref = useRef(null);
  const redirectUrl = useRef("");

  // eslint-disable-next-line no-useless-escape
  const regx_url =     /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  
  //activityType for travel_time
  const [activityType, setActivityType] = useState();
  useEffect(() => {
    dispatch(fetchActivityList("activity_types"));
  }, []);

  useEffect(() => {
    let obj = {};
    if (activityList?.data?.length) {
      activityList.data.forEach((activity) => {
        obj[activity.id] = activity.value;
      });
      setActivityType(obj);
    }
  }, [activityList]);

  //distanceList for travel_time
  const [distanceInMiles, setDistanceInMiles] = useState();

  useEffect(() => {
    dispatch(fetchDistanceList("travel_distances"));
  }, []);

  useEffect(() => {
    let obj = {};
    if (distanceList?.data?.length) {
      distanceList.data.forEach((distance) => {
        obj[distance.id] = distance.title;
      });
      setDistanceInMiles(obj);
    }
  }, [distanceList]);

  //sectorList for travel_time
  const [sectors, setSectors] = useState();

  useEffect(() => {
    dispatch(fetchSectorList("sectors"));
  }, []);

  useEffect(() => {
    let obj = {};
    if (sectorList?.data?.length) {
      sectorList.data.forEach((sector) => {
        obj[sector.id] = sector.value;
      });
      setSectors(obj);
    }
  }, [sectorList]);

  //countryRegionList for travel_time
  const [regions, setRegions] = useState();
  const [countries, setCountries] = useState();
  const [regionCountries, setRegionCountries] = useState();

  useEffect(() => {
    dispatch(fetchRegionCountryList("countries"));
  }, []);

  useEffect(() => {
    if (regionCountryList?.data?.length) {
      let obj = {},
        obj1 = {};
      regionCountryList.data.forEach((regionCountry) => {
        obj[regionCountry.region_id] = regionCountry.region;
        obj1[regionCountry.id] = regionCountry.country;
      });
      setRegions(obj);
      setCountries(obj1);
      setRegionCountries(regionCountryList?.data);
    }
  }, [regionCountryList]);

  useEffect(() => {
    if (
      regions &&
      regionCountries &&
      listname === "travel_time" &&
      sectors &&
      activityType &&
      distanceInMiles
    ) {
      dispatch(fetchMasterConfigData(listname));
    }
  }, [
    regions,
    regionCountries,
    listname,
    sectors,
    activityType,
    distanceInMiles,
    dataUpdated,
  ]);

  //user information
  useEffect(() => {
    if (userInformation !== undefined && userInformation?.data !== undefined) {
      let pagesAccess = userInformation?.data?.users[0]?.accessPages;
      if (pagesAccess !== undefined) {
        _.map(pagesAccess, (o) => {
          if (o === "Master Data Management") {
            setIsAccess(true);
          }
        });
        redirectUrl.current = "/accessdenied";
      }
    }
  }, [userInformation]);

  useEffect(() => {
    switch (listname.toLowerCase()) {
      case "activity_types":
        tableName.current = "Activity Type";
        break;
      case "currencies":
        tableName.current = "Currency";
        break;
      case "countries":
        tableName.current = "Market";
        break;
      case "nav_links":
        tableName.current = "External Links";
        break;
      case "hcp_specialities":
        tableName.current = "HCP Specialty";
        break;
      case "hcp_speciality_list":
        tableName.current = "HCP Specialty Groups";
        break;
      case "hcp_statures":
        tableName.current = "KOL HCP Stature";
        break;
      case "hcp_kol_stature_question_defns":
        tableName.current = "KOL Definition";
        break;
      case "languages":
        tableName.current = "Language";
        break;
      case "percentiles":
        tableName.current = "Percentile";
        break;
      case "reference_categories":
        tableName.current = "Reference Category";
        break;
      case "regions":
        tableName.current = "Region";
        break;
      case "sectors":
        tableName.current = "Sector";
        break;
      case "segments":
        tableName.current = "Segment";
        break;
      case "travel_distances":
        tableName.current = "Travel Distance";
        break;

      case "travel_time":
        tableName.current = "Travel Time Adjustment";
        break;
      default:
        tableName.current = listname;
        break;
    }
  }, [listname]);

  useEffect(() => {
    if (
      listname.toLowerCase() === "countries" ||
      listname.toLowerCase() === "travel_time"
    ) {
      dispatch(fetchRegionsMaster());
    } else if (listname.toLowerCase() === "hcp_specialities") {
      dispatch(fetchHCPSpecialtyListMaster());
    } else {
      setRegionLoaded(true);
      setHCPSpecialtyListLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listname]);

  useEffect(() => {
    if (
      masterData !== undefined &&
      masterData.data !== undefined &&
      listname !== "variables"
    ) {
      getTableColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  //dependencyCheckData
  useEffect(() => {
    if (
      postMethod.current === "DELETE" &&
      dependencyCheckData !== undefined &&
      dependencyCheckData?.data !== undefined
    ) {
      let datadependent = [],
        dataIndependent = [],
        itemDependent = [],
        dataToDelete = [];

      switch (listname.toLowerCase()) {
        case "activity_types":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.actType?.activity_type;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.actType?.id;
              }),
            };
          }
          break;
        case "currencies":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );

          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.currency?.currency;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.currency?.id;
              }),
            };
          }
          break;
        case "countries":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.country?.country;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.country?.id;
              }),
            };
          }
          break;
        case "hcp_specialities":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.speciality?.speciality;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.speciality?.id;
              }),
            };
          }
          break;
        case "hcp_speciality_list":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) =>
              (obj?.calc_count > 0 && obj?.calculators?.length > 0) ||
              (obj?.specilaities_count > 0 && obj?.specialities?.length > 0)
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) =>
              obj?.calc_count === 0 &&
              obj?.calculators?.length === 0 &&
              obj?.specilaities_count === 0 &&
              obj?.specialities?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.hcp_speciality_list?.hcp_speciality_list;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.hcp_speciality_list?.id;
              }),
            };
          }
          break;
        case "hcp_statures":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.hcp_sttaure?.hcp_stature;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.hcp_sttaure?.id;
              }),
            };
          }
          break;
        case "languages":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.language?.language;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.language?.id;
              }),
            };
          }
          break;
        case "percentiles":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.percentile?.percentile;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.percentile?.id;
              }),
            };
          }
          break;
        case "regions":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) =>
              (obj?.calc_count > 0 && obj?.calculators?.length > 0) ||
              (obj?.country_count > 0 && obj?.countries?.length > 0)
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) =>
              obj?.calc_count === 0 &&
              obj?.calculators?.length === 0 &&
              obj?.country_count === 0 &&
              obj?.countries?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.region?.region;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.region?.id;
              }),
            };
          }
          break;
        case "sectors":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.sector?.sector;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.sector?.id;
              }),
            };
          }
          break;
        case "segments":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.segment?.segment;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.segment?.id;
              }),
            };
          }
          break;
        case "travel_distances":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.region?.dist_miles + "|" + o?.region?.dist_kms;
            })?.join(", ");
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.region?.id;
              }),
            };
          }
          break;
        case "travel_time":
          datadependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count > 0 && obj?.calculators?.length > 0
          );
          dataIndependent = _.filter(
            dependencyCheckData?.data,
            (obj) => obj?.calc_count === 0 && obj?.calculators?.length === 0
          );
          if (datadependent?.length > 0) {
            itemDependent = _.map(datadependent, (o) => {
              return o?.region?.value;
            });
          }
          if (dataIndependent?.length > 0) {
            dataToDelete = {
              ids: _.map(dataIndependent, (o) => {
                return o?.region?.id;
              }),
            };
          }
          break;
        default:
          datadependent = [];
          dataIndependent = [];
          itemDependent = [];
          dataToDelete = [];
          break;
      }

      if (dataToDelete?.ids?.length > 0) {
        dispatch(
          updateMasterConfigData(postMethod.current, listname, dataToDelete, "")
        );
      }
      if (datadependent?.length > 0) {
        if (itemDependent !== undefined) {
          let msg = "";
          if (datadependent?.length === 1) {
            if (listname === "travel_time") {
              msg =
                "<strong>Cannot delete this travel time adjustment value  as it is used in calculator.</strong>";
            } else {
              msg =
                "<strong>Cannot delete " +
                itemDependent +
                " as it is used in calculator.</strong>";
            }
          } else if (datadependent?.length > 1) {
            if (listname === "travel_time") {
              msg =
                "<strong>Cannot delete these travel time adjustment value  as they are used in calculator.</strong>";
            } else {
              msg =
                "<strong>Cannot delete " +
                itemDependent +
                " as they are used in calculators.</strong>";
            }
          }
          Swal.fire("", msg, "warning");
        }
      } else if (datadependent?.length === 0 && dataToDelete?.ids?.length > 0) {
        let deletedMssg =
          dataToDelete?.ids.length > 1
            ? "<strong>Items have been Deleted!</strong>"
            : "<strong>Item has been Deleted!</strong>";
        Swal.fire("", deletedMssg, "success");
        ref.current.table.clearFilter(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencyCheckData]);

  useEffect(() => {
    if (regionsMaster !== undefined) {
      regionOptions.current = _.mapValues(
        _.keyBy(regionsMaster?.data, "id"),
        "value"
      );

      getTableColumns();
      setRegionLoaded(true);
      setHCPSpecialtyListLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionsMaster]);

  useEffect(() => {
    if (hcpSpecialtyListMaster !== undefined) {
      hcpSpecialtyListOptions.current = _.map(
        hcpSpecialtyListMaster?.data,
        function (obj) {
          return { id: obj.id, name: obj.value };
        }
      );

      getTableColumns();
      setRegionLoaded(true);
      setHCPSpecialtyListLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hcpSpecialtyListMaster]);

  useEffect(() => {
    if (regionLoaded && hcpSpecialtyListLoaded && listname !== "travel_time") {
      dispatch(fetchMasterConfigData(listname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listname, regionLoaded, hcpSpecialtyListLoaded, dataUpdated]);

  useEffect(() => {
    if (duplicateResponse !== undefined) {
      setduplicateChecked(true);
    }
  }, [duplicateResponse]);

  useEffect(() => {
    if (duplicateChecked) {
      if (duplicateResponse.count > 0) {
        refCell.current.getRow().select();
        showtoast.showError("An item with same entry already exists.");
      } else {
        if (
          postMethod.current &&
          postMethod.current !== "" &&
          postMethod.current !== undefined
        ) {
          refCell.current.getRow().deselect();
          dispatch(
            updateMasterConfigData(
              postMethod.current,
              listname,
              postData.current,
              postItemId.current
            )
          );
          if (postMethod.current.toUpperCase() === "POST") {
            showtoast.showSuccess("Item added successfully.");
            ref.current.table.clearFilter(true);
          } else {
            showtoast.showSuccess("Item updated successfully.");
            ref.current.table.clearFilter(true);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateChecked]);

  const saveIcon = function (cell, formatterParams, onRendered) {
    if (cell.getValue()) {
      return (
        "<img height='28px' width='28px' src='" +
        process.env.PUBLIC_URL +
        "/green-save-icon.png'/>"
      );
    }
    return "";
  };

  const commonColumnForSelect = [
    {
      formatter: "rowSelection",
      titleFormatter: "rowSelection",
      hozAlign: "center",
      headerSort: false,
      width: 10,
      cellClick: function (e, cell) {
        cell.getRow().toggleSelect();
      },
    },
  ];

  const commonColumn = [
    {
      field: "SaveButton",
      title: " ",
      hozAlign: "center",
      width: 50,
      headerSort: false,
      formatter: saveIcon,
      cellClick: saveItem,
    },
  ];

  const getTableColumns = () => {
    let tabulatorColumns = [];

    switch (listname.toLowerCase()) {
      case "hcp_statures":
        tabulatorColumns = [
          {
            title: "Name",
            field: "value",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
          {
            title: "KOL Assessment Min Score",
            field: "min",
            editor: "number",
            headerFilter: "input",
            editorParams: { min: 0 },
            headerFilterPlaceholder: "Search by min value",
            validator: "required",
            tooltip: true,
          },
          {
            title: "KOL Assessment Max Score",
            field: "max",
            editor: "number",
            headerFilter: "input",
            editorParams: { min: 0 },
            headerFilterPlaceholder: "Search by max value",
            validator: ["required", "integer"],
            tooltip: true,
          },
          {
            title: "Order",
            field: "orders",
            editor: "number",
            headerFilter: "input",
            headerFilterPlaceholder: "Search",
            validator: ["required", "integer"],
            tooltip: true,
            width: 100,
            hozAlign: "center",
          },
        ];
        break;
      case "currencies":
        tabulatorColumns = [
          {
            title: "Name",
            field: "value",
            editor: "input",
            editorParams: {
              elementAttributes: {
                maxlength: "3",
              },
            },
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            tooltip: true,
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
          },
        ];
        break;
      case "countries":
        tabulatorColumns = [
          {
            title: "Market",
            field: "country",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
          {
            title: "Region",
            field: "region_id",
            editor: "select",
            validator: "required",
            formatter: "lookup",
            formatterParams: regionOptions.current,
            editorParams: {
              allowEmpty: false,

              values: regionOptions.current,
            },
            headerFilter: "select",
            headerFilterPlaceholder: "Filter",
            headerFilterParams: { values: regionOptions.current },
            tooltip: true,
          },
        ];
        break;
      case "hcp_specialities":
        tabulatorColumns = [
          {
            title: "HCP Specialty",
            field: "hcp_speciality",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },

          {
            title: "Associated Groups",
            field: "mapping",
            sorter: (a, b) => a.toString().localeCompare(b.toString()),
            editor: MultiSelectEditor,
            editorParams: paramLookup,
            formatter: MultiValueFormatter,
            formatterParams: { style: "PILL" },
            validator: "required",
            tooltip: false,
          },
        ];
        break;
      case "travel_distances":
        tabulatorColumns = [
          {
            title: "Distance in KMs",
            field: "dist_kms",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
          {
            title: "Distance in Miles",
            field: "dist_miles",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
          {
            title: "Default Value",
            field: "default_val",
            editor: "number",
            headerFilter: "input",
            headerFilterPlaceholder: "Search",
            tooltip: true,
          },
          {
            title: "Order",
            field: "order",
            editor: "number",
            headerFilter: "input",
            headerFilterPlaceholder: "Search",
            validator: ["required", "integer"],
            tooltip: true,
            width: 100,
            hozAlign: "center",
          },
        ];
        break;
      case "travel_time":
        tabulatorColumns = [
          {
            title: "Region",
            field: "region_id",
            editor: "select",
            editorParams: paramLookup1,
            headerFilter: "select",
            headerFilterPlaceholder: "Filter",
            headerFilterParams: { values: regions },
            formatter: "lookup",
            formatterParams: regions,
            validator: "required",
          },
          {
            title: "Market",
            field: "country_id",
            editor: "select",
            editorParams: paramLookup2,
            headerFilter: "select",
            headerFilterPlaceholder: "Filter",
            headerFilterParams: { values: countries },
            validator: "required",
            formatter: "lookup",
            formatterParams: countries,
          },
          {
            title: "Sector",
            field: "sector_id",
            editor: "select",
            editorParams: { allowEmpty: false, values: sectors },
            headerFilter: "select",
            headerFilterPlaceholder: "Filter",
            headerFilterParams: { values: sectors },
            formatter: "lookup",
            formatterParams: sectors,
            validator: "required",
          },
          {
            title: "Activity Type",
            field: "activity_type_id",
            editor: "select",
            editorParams: { allowEmpty: false, values: activityType },
            headerFilter: "select",
            formatter: "lookup",
            formatterParams: activityType,
            headerFilterPlaceholder: "Filter",
            headerFilterParams: { values: activityType },
            validator: "required",
          },
          {
            title: "Distance in Miles",
            field: "travel_distance_id",
            editor: "select",
            editorParams: { allowEmpty: false, values: distanceInMiles },
            formatter: "lookup",
            formatterParams: distanceInMiles,
            headerFilter: "select",
            headerFilterPlaceholder: "Filter",
            headerFilterParams: { values: distanceInMiles },
            validator: "required",
          },
          {
            title: "Travel Time Adjustment",
            field: "value",
            editor: "number",
            headerFilter: "input",
            headerFilterPlaceholder: "Search",

            validator: "required",
          },
        ];
        break;
      case "nav_links":
        tabulatorColumns = [
          {
            title: "Display Text",
            field: "name",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Text",
            tooltip: true,
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
          },
          {
            title: "Link",
            field: "links",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Text",
            tooltip: true,
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
          },
        ];
        break;
      case "hcp_kol_stature_question_defns":
        tabulatorColumns = [
          {
            title: "Definition Type",
            field: "defn_type",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
          {
            title: "Local Kol Definition",
            field: "local_kol_defn",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
          {
            title: "National Kol Definition",
            field: "national_kol_defn",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
          {
            title: "International Kol Definition",
            field: "international_kol_defn",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
          {
            title: "Order",
            field: "order",
            editor: "number",
            headerFilter: "input",
            headerFilterPlaceholder: "Search",
            validator: ["required", "integer"],
            tooltip: true,
            width: 100,
            hozAlign: "center",
          },
        ];
        break;

      case "languages":
        tabulatorColumns = [
          {
            title: "Name",
            field: "value",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
          {
            title: "Translation Available",
            field: "translation_available",
            tooltip: true,
          },
        ];
        break;
      default:
        tabulatorColumns = [
          {
            title: "Name",
            field: "value",
            editor: "input",
            headerFilter: "input",
            headerFilterPlaceholder: "Search by Name",
            validator: [
              {
                type: blankValidation,
                parameters: {},
              },
            ],
            tooltip: true,
          },
        ];
        break;
    }

    cols.current = tabulatorColumns;
    setTableColumns([
      ...(listname.toLowerCase() !== "variables" ? commonColumnForSelect : ""),
      ...tabulatorColumns,
      ...commonColumn,
    ]);
  };

  const blankValidation = function (cell, value, parameters) {
    return value && value.trim() !== "";
  };

  function paramLookup(cell) {
    let c = cell.getData();
    let data = hcpSpecialtyListOptions.current;
    let srcData = [];
    if (cell.getData().mapping !== undefined && cell.getData().mapping !== "") {
      _.map(c.mapping, (o) => {
        _.map(data, (i) => {
          if (o.name === i.name) {
            srcData = _.filter(data, (s) => s.name !== i.name);
            data = srcData;
          }
        });
      });
    }
    let r = { values: data };
    return r;
  }

  //paramLookup for travel_time region
  const paramLookup1 = (cell) => {
    return { values: regions };
  };
  //paramLookup for travel_time countries
  const paramLookup2 = (cell) => {
    let c = cell.getData();

    if (c.region_id) {
      let obj = {};
      regionCountries.forEach((item) => {
        if (c.region_id === item.region_id) {
          obj[item.id] = item.country;
        }
      });
      return { values: obj };
    } else {
      return { values: {} };
    }
  };

  function addNewRow() {
    ref.current.table.addRow({ id1: id1++ }, true);
  }

  function saveItem(e, cell) {
    let data = {};
    let rangeMinCheck = false,
      rangeMaxCheck = false,
      checkNegative = false,
      isMinRangeGreater = false;
    if (listname.toLowerCase() === "hcp_statures") {
      if (masterData?.data?.data !== undefined) {
        let cellStatureData = cell.getRow().getData();
        if (
          cellStatureData?.min < 0 ||
          cellStatureData?.max < 0 ||
          cellStatureData?.orders < 0
        ) {
          checkNegative = true;
        } else {
          if (
            cellStatureData?.min >= 0 &&
            cellStatureData?.max >= 0 &&
            cellStatureData?.min > cellStatureData?.max
          ) {
            isMinRangeGreater = true;
          } else {
            _.map(masterData?.data?.data, (o) => {
              if (cellStatureData?.id !== o?.id) {
                if (
                  Number(cellStatureData?.min) >= Number(o?.min) &&
                  Number(cellStatureData?.min) <= Number(o?.max)
                ) {
                  rangeMinCheck = true;
                }
              }
            });
            _.map(masterData?.data?.data, (o) => {
              if (cellStatureData?.id !== o?.id) {
                if (
                  Number(cellStatureData?.max) >= Number(o?.min) &&
                  Number(cellStatureData?.max) <= Number(o?.max)
                ) {
                  rangeMaxCheck = true;
                }
              }
            });
          }
        }
      }
    }
    let linkcheck = true;
    if (
      listname.toLowerCase() === "nav_links" &&
      cell.getRow().getData().links !== undefined &&
      cell.getRow().getData().links !== ""
    ) {
      linkcheck = regx_url.test(cell.getRow().getData().links);
    }
    if (
      !rangeMinCheck &&
      !rangeMaxCheck &&
      !checkNegative &&
      !isMinRangeGreater
    ) {
      if (
        (listname.toLowerCase() === "nav_links" && linkcheck) ||
        listname.toLowerCase() !== "nav_links"
      ) {
        postMethod.current =
          cell.getRow().getData().id === undefined ? "POST" : "PATCH";

        switch (listname.toLowerCase()) {
          case "countries":
            data = {
              value: cell.getRow().getData().country,
              country: cell.getRow().getData().country,
              region_id: cell.getRow().getData().region_id,
              id: cell.getRow().getData().id,
            };
            break;
          case "hcp_specialities":
            let mapdata;
            if (cell.getRow().getData().mapping !== undefined) {
              let lstValues = _.mapValues(
                cell.getRow().getData().mapping,
                "name"
              );
              mapdata = _.map(lstValues, function (obj) {
                return _.find(hcpSpecialtyListOptions.current, (o) => {
                  return o.name.toLowerCase() === obj.toLowerCase();
                }).id;
              });
            }
            data = {
              id: cell.getRow().getData().id,
              value: cell.getRow().getData().hcp_speciality,
              hcp_speciality: cell.getRow().getData().hcp_speciality,
              list_ids: mapdata,
              mapping: mapdata,
            };
            break;
          case "travel_distances":
            data = {
              dist_kms: cell.getRow().getData().dist_kms,
              dist_miles: cell.getRow().getData().dist_miles,
              default_val: cell.getRow().getData().default_val,
              id: cell.getRow().getData().id,
              order: cell.getRow().getData().order,
            };
            break;
          case "travel_time":
            data = {
              region_id: cell.getRow().getData().region_id,
              country_id: cell.getRow().getData().country_id,
              sector_id: cell.getRow().getData().sector_id,
              activity_type_id: cell.getRow().getData().activity_type_id,
              travel_distance_id: cell.getRow().getData().travel_distance_id,
              value: String(cell.getRow().getData().value),
              id: cell.getRow().getData().id,
            };
            break;
          case "nav_links":
            data = {
              name: cell.getRow().getData().name,
              links: cell.getRow().getData().links,
              id: cell.getRow().getData().id,
            };
            break;
          case "hcp_kol_stature_question_defns":
            data = {
              defn_type: cell.getRow().getData().defn_type,
              local_kol_defn: cell.getRow().getData().local_kol_defn,
              national_kol_defn: cell.getRow().getData().national_kol_defn,
              international_kol_defn: cell.getRow().getData()
                .international_kol_defn,
              order: cell.getRow().getData().order,
              id: cell.getRow().getData().id,
            };
            break;
          case "hcp_statures":
            data = {
              value: cell.getRow().getData().value,
              orders: cell.getRow().getData().orders,
              min_value: cell.getRow().getData().min,
              max_value: cell.getRow().getData().max,
              id: cell.getRow().getData().id,
            };
            break;
          case "variables":
            data = {
              key: cell.getRow().getData().key,
              value: String(cell.getRow().getData().value),
              id: cell.getRow().getData().id,
            };
            break;
          default:
            data = {
              value: cell.getRow().getData().value,
              id: cell.getRow().getData().id,
            };
            break;
        }
        postData.current = data;
        postItemId.current = cell.getRow().getData().id;
        refCell.current = cell;

        const requiredFields = _.mapKeys(
          _.filter(cols.current, "validator"),
          "field"
        );
        let checkRes = _.find(
          _.keys(data),
          (o) => {
            return (
              requiredFields.hasOwnProperty(o) &&
              (data[o] === undefined || data[o].toString().trim() === "")
            );
          },
          0
        );
        if (checkRes === undefined) {
          const row = cell.getRow()._row;
          checkRes = _.find(row.cells, (o) => {
            return (
              o.modules && o.modules.validate && o.modules.validate.invalid
            );
          });
        }
        if (checkRes === undefined) {
          setduplicateChecked(false);
          dispatch(checkDuplicate(listname, data));
        } else {
          cell.getRow().validate();
        }
      } else if (
        listname.toLowerCase() === "nav_links" &&
        !regx_url.test(cell.getRow().getData().links)
      ) {
        if (
          cell.getRow().getData().links !== "" &&
          cell.getRow().getData().links !== undefined
        ) {
          showtoast.showError("You have specified an invalid URL.");
        }
      }
    } else if (listname.toLowerCase() === "hcp_statures") {
      let msg = "";
      if (checkNegative) {
        msg = "Please enter positive values.";
      } else {
        if (isMinRangeGreater) {
          msg = "Min score should be less then max score.";
        } else {
          if (rangeMinCheck && rangeMaxCheck) {
            msg =
              "Min score and max score should not overlap any of the HCP Stature score range.";
          } else if (rangeMinCheck) {
            msg =
              "Min score should not overlap any of the HCP Stature score range.";
          } else if (rangeMaxCheck) {
            msg =
              "Max score should not overlap any of the HCP Stature score range.";
          }
        }
      }
      showtoast.showError(msg);
    }
  }

  const deleteItem = function () {
    let selectedData = ref.current.table.getSelectedData();

    if (selectedData.length === 0) {
      Swal.fire(
        "",
        "<strong>Please select an item to delete!</strong>",
        "warning"
      );
    } else {
      postMethod.current = "DELETE";
      let confirmationMsg =
        selectedData?.length > 1
          ? "<strong>Are you sure you want to delete " +
            selectedData?.length +
            " selected items?</strong>"
          : selectedData[0]?.title === undefined
          ? "<strong>Are you sure you want to delete the selected item?</strong>"
          : '<strong>Are you sure you want to delete "' +
            selectedData[0]?.title +
            '"?</strong>';
      Swal.fire({
        html: confirmationMsg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#808080",
        focusConfirm: false,
        confirmButtonText: "Yes, delete.",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          //local delete
          let itemsNotSaved = _.filter(
            ref.current.table.getSelectedRows(),
            (o) => {
              return o._row.data.id === undefined;
            }
          );
          _.forEach(itemsNotSaved, (row) => {
            row.delete();
          });
          if (selectedData.length !== itemsNotSaved.length) {
            let dataToDel = _.filter(selectedData, (o) => {
              return o.id !== undefined;
            });
            let data = {
              ids: _.map(dataToDel, "id"),
            };
            //db delete
            if (
              listname?.toLowerCase() === "activity_types" ||
              listname?.toLowerCase() === "currencies" ||
              listname?.toLowerCase() === "countries" ||
              listname?.toLowerCase() === "hcp_specialities" ||
              listname?.toLowerCase() === "hcp_speciality_list" ||
              listname?.toLowerCase() === "hcp_statures" ||
              listname?.toLowerCase() === "languages" ||
              listname?.toLowerCase() === "percentiles" ||
              listname?.toLowerCase() === "regions" ||
              listname?.toLowerCase() === "sectors" ||
              listname?.toLowerCase() === "segments" ||
              listname?.toLowerCase() === "travel_distances" ||
              listname?.toLowerCase() === "travel_time"
            ) {
              dispatch(checkMasterConfigDataDependency(listname, data));
            } else if (
              listname?.toLowerCase() === "nav_links" ||
              listname?.toLowerCase() === "hcp_kol_stature_question_defns" ||
              listname?.toLowerCase() === "reference_categories" ||
              listname?.toLowerCase() === "variables"
            ) {
              dispatch(
                updateMasterConfigData(postMethod.current, listname, data, "")
              );

              let deletedMssg =
                selectedData.length > 1
                  ? "<strong>Items have been Deleted!</strong>"
                  : "<strong>Item has been Deleted!</strong>";
              Swal.fire("", deletedMssg, "success");
              ref.current.table.clearFilter(true);
            }
          }
        }
      });
    }
  };

  const onCellClick = function (e, cell) {
    let currentRow = cell.getRow();
    let currentTable = cell.getTable();
    let rows = currentTable.getRows();
    let currentData = cell.getRow().getData();

    //set showSave icon colom to false for all other rows
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].getData().id !== currentData.id) {
        //except current row
        let cells = rows[i].getCells();
        if (cells[cells.length - 1]) cells[cells.length - 1].setValue(false);
        rows[i].reformat();
      }
    }
    //show save icon for current row only
    let cells = currentRow.getCells();
    if (cells[cells.length - 1]) cells[cells.length - 1].setValue(true);
  };

  //cellEdited for travel_time
  const onCellEdited = (cell1) => {
    if (listname === "travel_time") {
      let currentRow = cell1.getRow();
      let currentData = cell1.getRow().getData();

      let changeCountryList = false;
      const countries = regionCountries?.filter(
        (data) => data.region_id === currentData.region_id
      );

      countries?.forEach((data) => {
        if (data.id === currentData.country_id) {
          changeCountryList = true;
        }
      });
      if (!changeCountryList && countries) {
        const cells = currentRow.getCells();
        cells[2].setValue("");
      }
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        After editing any row, Please ensure to save changes before moving to
        next row.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {redirectUrl.current !== "" &&
        (!isAccess ? <Redirect to={redirectUrl.current} /> : "")}
      {isAccess ? (
        <div className="masterdata-config">
          <ShowLoader></ShowLoader>

          <div>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              toastStyle={{
                border: "1px solid",
                boxShadow: "10px 10px 16px 0px rgba(0,0,0,0.75)",
                color: "black",
                backgroundColor: "#e6e6e6",
                fontWeight: "bold",
              }}
            />
          </div>
          <div className="table-heading">
            <span>{tableName.current}</span> &nbsp;&nbsp;
            <div>
              {listname.toLowerCase() !== "variables" ? (
                <Image
                  src={add}
                  className="add-item"
                  alt="Add New Item"
                  onClick={() => addNewRow()}
                ></Image>
              ) : (
                ""
              )}
            </div>
            <OverlayTrigger
              trigger="click"
              placement="right"
              rootClose={true}
              overlay={popover}
            >
              <Button className="Info-btn-tbulator">i</Button>
            </OverlayTrigger>
            <div className="delete-item">
              {rowSelected ? (
                listname.toLowerCase() !== "variables" ? (
                  <button onClick={() => deleteItem()}>
                    <Image src={deleteicon} alt="Delete"></Image>
                  </button>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="tabulator-wrapper">
            {masterData !== undefined && masterData.data !== undefined ? (
              <ReactTabulator
                ref={(a) => (ref.current = a)}
                columns={tableColumns}
                data={masterData?.data?.data}
                key={listname}
                cellClick={onCellClick}
                cellEdited={onCellEdited}
                rowSelectionChanged={function (data, rows) {
                  //row - row component for the selected row
                  if (rows.length > 0) {
                    setRowSelected(true);
                  } else {
                    setRowSelected(false);
                  }
                }}
                height="100%"
                layout="fitColumns"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
