import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import showtoast from "../helper/showToastAlert";
import _ from "lodash";
import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css"; // use Theme(s)
import { ReactTabulator } from "react-tabulator";
import {
  Image,
  Container,
  Row,
  Col,
  Button,
  Popover,
  OverlayTrigger
} from "react-bootstrap";
import add from "../assets/add.svg";
import deleteicon from "../assets/delete-icon.png";
import MultiValueFormatter from "react-tabulator/lib/formatters/MultiValueFormatter";
import MultiSelectEditor from "react-tabulator/lib/editors/MultiSelectEditor";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Multiselect from "multiselect-react-dropdown";
import {
  fetchRegionsMaster,
  fetchUsersMaster,
  fetchRolesMaster,
  checkDuplicate,
  updateMasterConfigData,
  fetchAllUserList
} from "../actions/masterDataConfig";

export function UserRolesDashboard() {
  const dispatch = useDispatch();

  const [tableColumns, setTableColumns] = useState([]);
  const [userData, setUserData] = useState([]);
  const [duplicateChecked, setduplicateChecked] = useState(false);
  const [rowSelected, setRowSelected] = useState(false);
  // const [rowCount, setRowCount] = useState([]);
  const [userName, setUserName] = useState("");
  const [wwid, setWwid] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [regionId, setRegionId] = useState("");
  const [isRegion, setIsRegion] = useState(false);
  const [showEmailBorder, setShowEmailBorder] = useState(false);
  const [showRolesBorder, setShowRolesBorder] = useState(false);
  const [showRegionBorder, setShowRegionBorder] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isAccess, setIsAccess] = useState(false);

  const usersMasterData = useSelector(
    state => state.masterDataConfig.usersmaster
  );
  const regionsMaster = useSelector(
    state => state.masterDataConfig.regionsmaster
  );
  const rolesMasterData = useSelector(
    state => state.masterDataConfig.rolesmaster
  );
  const duplicateResponse = useSelector(
    state => state.masterDataConfig.duplicatecount
  );
  const dataUpdated = useSelector(state => state.masterDataConfig.dataUpdated);
  const allUserList = useSelector(state => state.masterDataConfig.alluserlist);
  const userInformation = useSelector(state => state.userInformation);

  const regionOptions = useRef();
  const regionList = useRef();
  const postMethod = useRef("");
  const postItemId = useRef("");
  const postData = useRef({});
  // const refCell = useRef({});
  const cols = useRef([]);
  const ref = useRef(null);
  const rolesList = useRef();
  // const usersList = useRef([]);
  // const userSelected = useRef([]);
  const isBusinessAdminId = useRef("");
  const accessUrl = useRef("");
  const multiselectRef = useRef(null);

  useEffect(() => {
    if (userInformation !== undefined && userInformation?.data !== undefined) {
      let pagesAccess = userInformation?.data?.users[0]?.accessPages;
      if (pagesAccess !== undefined) {
        _.map(pagesAccess, o => {
          if (o === "Master Data Management") {
            setIsAccess(true);
          }
        });
        accessUrl.current = "/accessdenied";
      }
    }
  }, [userInformation]);

  useEffect(() => {
    dispatch(fetchUsersMaster());
    dispatch(fetchRegionsMaster());
    dispatch(fetchRolesMaster());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdated]);

  useEffect(() => {
    if (regionsMaster !== undefined) {
      regionOptions.current = regionsMaster?.data.map((obj, i) => {
        return (
          <option key={i} value={obj.id}>
            {obj.value}
          </option>
        );
      });
      regionList.current = _.mapValues(
        _.keyBy(regionsMaster?.data, "id"),
        "value"
      );
    }
  }, [regionsMaster]);

  // useEffect(() => {
  //   if (allUserList !== undefined) {
  //     userSelected.current = allUserList?.users;
  //     let usrList = _.map(allUserList?.users, function (obj) {
  //       return { id: obj.employeeID, name: obj.mail };
  //     });
  //     usersList.current = usrList;
  //   } else {
  //     usersList.current = [];
  //   }
  // }, [allUserList]);

  useEffect(() => {
    if (rolesMasterData !== undefined) {
      rolesList.current = _.map(rolesMasterData?.data, obj => {
        return { id: obj.id, name: obj.name };
      });
      _.map(rolesMasterData?.data, o => {
        if (o?.name?.includes("Business")) {
          isBusinessAdminId.current = o?.id;
        }
      });
    }
  }, [rolesMasterData]);

  useEffect(() => {
    if (usersMasterData !== undefined) {
      let data = [];
      _.map(usersMasterData?.data, o => {
        let i = {
          id: o.id,
          name: o.name,
          email: o.email,
          wwid: o.wwid,
          region_id: o.region_id,
          region: o.region,
          roles: rolesFunc(o.roles)
        };
        data.push(i);
      });
      function rolesFunc(roles) {
        let roledata = [];
        _.map(roles, o => {
          let i = { id: o.role_id, name: o.role, item_id: o?.item_id };
          roledata.push(i);
        });
        return roledata;
      }
      setUserData(data);
    }
  }, [usersMasterData]);

  useEffect(() => {
    if (duplicateResponse !== undefined) {
      setduplicateChecked(true);
    }
  }, [duplicateResponse]);

  useEffect(() => {
    if (duplicateChecked) {
      if (duplicateResponse.count === 1) {
        // refCell.current.getRow().select();
        showtoast.showError("A user with same details already exists.");
      } else {
        if (
          postMethod.current &&
          postMethod.current !== "" &&
          postMethod.current !== undefined
        ) {
          // refCell.current.getRow().deselect();
          dispatch(
            updateMasterConfigData(
              postMethod.current,
              "users",
              postData.current,
              postItemId.current
            )
          );
          if (postMethod.current.toUpperCase() === "POST") {
            showtoast.showSuccess("User details added successfully.");
            setShowForm(false);
            ref.current.table.clearFilter(true);
          } else {
            showtoast.showSuccess("User details updated successfully.");
            setShowForm(false);
            ref.current.table.clearFilter(true);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateChecked]);

  const saveIcon = function(cell, formatterParams, onRendered) {
    if (cell.getValue()) {
      return (
        "<img height='20px' width='20px' src='" +
        process.env.PUBLIC_URL +
        "/green-save-icon.png'/>"
      );
    }
    return "";
  };

  const commonColumnForSelect = [
    {
      formatter: "rowSelection",
      titleFormatter: "rowSelection",
      hozAlign: "center",
      headerSort: false,
      width: 10,
      cellClick: function(e, cell) {
        cell.getRow().toggleSelect();
      }
    }
  ];

  const commonColumn = [
    {
      field: "SaveButton",
      title: " ",
      hozAlign: "center",
      width: 45,
      headerSort: false,
      formatter: saveIcon,
      cellClick: saveItem
    }
  ];

  useEffect(() => {
    let tabulatorColumns = [
      {
        title: "Email ID",
        field: "email",
        // editor: "input",
        headerFilter: "input",
        headerFilterPlaceholder: "Search by Email ID",
        validator: "required",
        tooltip: true
      },
      {
        title: "User Name",
        field: "name",
        // editor: "input",
        headerFilter: "input",
        headerFilterPlaceholder: "Search by Name",
        validator: "required",
        tooltip: true
      },
      {
        title: "WWID",
        field: "wwid",
        // editor: "input",
        headerFilter: "input",
        headerFilterPlaceholder: "Search by WWID",
        validator: "required",
        tooltip: true,
        width: 150
      },
      {
        title: "User Roles",
        field: "roles",
        cellClick: onCellClick,
        sorter: (a, b) => a.toString().localeCompare(b.toString()),
        editor: MultiSelectEditor,
        editorParams: paramLookup,
        tooltip: false,
        // {
        //     values: rolesList.current
        // },
        formatter: MultiValueFormatter,
        formatterParams: { style: "PILL" },
        validator: "required",
        // tooltip: true,
        minWidth: 431,
        // headerFilter: 'select',
        headerFilterFunc: "in",
        headerFilterParams: {
          values: rolesList.current,
          // values: true,
          sortValuesList: "asc",
          multiselect: true
        },
        headerSort: false
      },
      {
        title: "BA Region",
        field: "region_id",
        editor: "select",
        // validator: "required",
        formatter: "lookup",
        formatterParams: regionList.current,
        editorParams: {
          // allowEmpty: false,
          // values: true,
          values: regionList.current
        },
        headerFilter: "select",
        headerFilterPlaceholder: "Filter",
        headerFilterParams: { values: regionList.current },
        tooltip: true,
        width: 125
      }
    ];
    cols.current = tabulatorColumns;
    setTableColumns([
      ...commonColumnForSelect,
      ...tabulatorColumns,
      ...commonColumn
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionsMaster]); 

  // function regionpParamLookup(cell) {
  //   let c = cell.getData();
  //   let data = regionOptions.current;
  //   if (cell.getData().roles !== undefined && cell.getData().roles !== "") {
  //     _.map(c.roles, o => {
  //       if (o.name === "System Admin") {
  //         data = [];
  //       }
  //     });
  //   }
  //   return data;
  // }

  function paramLookup(cell) {
    let c = cell.getData();
    let data = rolesList.current;
    let srcData = [];
    if (cell.getData().roles !== undefined && cell.getData().roles !== "") {
      _.map(c.roles, o => {
        _.map(data, i => {
          if (o.name === i.name) {
            srcData = _.filter(data, s => s.name !== i.name);
            data = srcData;
          }
        });
      });
    }
    let r = { values: data };
    return r;
  }

  function addNewRow() {
    setShowForm(true);
  }

  const validateData = () => {
    let isvalid = true;
    if (!userEmail || selectedRoles.length === 0 || (isRegion && !regionId)) {
      if (!userEmail) {
        setShowEmailBorder(true);
      } else {
        setShowEmailBorder(false);
      }
      if (selectedRoles.length === 0) {
        setShowRolesBorder(true);
      } else {
        setShowRolesBorder(false);
      }
      if (isRegion && !regionId) {
        setShowRegionBorder(true);
      } else {
        setShowRegionBorder(false);
      }
      isvalid = false;
    } else {
      setShowEmailBorder(false);
      setShowRolesBorder(false);
      setShowRegionBorder(false);
    }
    if (!isvalid) {
      document.documentElement.scrollTop = 0;
    }
    return isvalid;
  };

  function onAddRole() {
    if (validateData()) {
      let data = {};
      let selectedData = ref?.current?.table?.getSelectedData();
      postMethod.current = "POST";

      let mapdata;
      if (selectedRoles !== undefined) {
        let isCheckdata = selectedRoles;
        mapdata = _.map(isCheckdata, function(obj) {
          return _.find(rolesList.current, o => {
            return o?.id === obj?.id;
          })?.id;
        });
      }
      data = {
        name: userName,
        email: userEmail,
        wwid: wwid,
        role_ids: mapdata,
        region_id: regionId,
        id: selectedData?.id
      };

      postData.current = data;
      postItemId.current = selectedData[0]?.id;
      // refCell.current = cell;
      const requiredFields = _.mapKeys(
        _.filter(cols.current, "validator"),
        "field"
      );
      const checkRes = _.find(
        _.keys(data),
        o => {
          return requiredFields.hasOwnProperty(o) && data[o] === undefined;
        },
        0
      );
      if (checkRes === undefined) {
        setduplicateChecked(false);
        dispatch(checkDuplicate("users", data));
      } else {
        // selectedData.getRow().validate();
      }
    }
  }

  function saveItem(e, cell) {
    let rolesValidCount = 0,
      validateRoles = true;
    if (cell?.getRow()?.getData()?.roles?.length > 0) {
      _.map(cell?.getRow()?.getData()?.roles, obj => {
        _.map(rolesList.current, o => {
          if (o?.name?.toLowerCase() === obj?.name?.toLowerCase()) {
            rolesValidCount++;
          }
        });
      });
      if (rolesValidCount !== cell?.getRow()?.getData()?.roles?.length) {
        validateRoles = false;
      }
    }
    if (!validateRoles) {
      showtoast.showError("Please select defined role from the dropdown list.");
    }

    let validateData = _.filter(cell?.getRow()?.getData()?.roles, o =>
      o.name.includes("Business")
    );
    let isvalid = true,
      regiondata = "";
    if (cell?.getRow()?.getData()?.roles?.length > 0 && validateRoles) {
      if (validateData?.length > 0) {
        if (!cell?.getRow()?.getData()?.region_id) {
          showtoast.showError(
            "Please select a Region for Business Admin role."
          );
          isvalid = false;
        } else {
          regiondata = cell?.getRow()?.getData()?.region_id;
          isvalid = true;
        }
      } else {
        isvalid = true;
        regiondata = "";
      }
    } else {
      isvalid = false;
    }
    if (isvalid && validateRoles) {
      let data = {};
      // let selectedData = ref?.current?.table?.getSelectedData();
      postMethod.current = "PATCH";

      let mapdata = [];
      if (cell?.getRow()?.getData()?.roles !== undefined) {
        let isCheckdata = cell?.getRow()?.getData()?.roles;
        let rdata = [];
        _.map(isCheckdata, obj => {
          _.map(rolesList.current, o => {
            if (o?.name?.toLowerCase() === obj?.name?.toLowerCase()) {
              let i = { role_id: o.id };
              rdata.push(i);
            }
          });
        });
        _.map(isCheckdata, obj => {
          _.map(rdata, o => {
            if (o?.role_id === obj?.id) {
              let i = { role_id: o?.role_id, id: obj?.item_id };
              mapdata.push(i);
            }
          });
        });
        let srcData = [];
        mapdata?.map(o => {
          rdata?.map((i, index) => {
            if (o.role_id === i.role_id) {
              srcData = _.filter(rdata, s => s.role_id !== i.role_id);
              rdata = srcData;
            }
            return rdata;
          });
          return rdata;
        });
        _.map(rdata, o => {
          mapdata?.push(o);
        });
      }
      data = {
        name: cell?.getRow()?.getData()?.name,
        email: cell?.getRow()?.getData()?.email,
        wwid: cell?.getRow()?.getData()?.wwid,
        role_ids: mapdata,
        region_id: regiondata,
        id: cell?.getRow()?.getData()?.id
      };

      postData.current = data;
      postItemId.current = cell?.getRow()?.getData()?.id;
      // refCell.current = cell;

      const requiredFields = _.mapKeys(
        _.filter(cols.current, "validator"),
        "field"
      );
      const checkRes = _.find(
        _.keys(data),
        o => {
          return requiredFields.hasOwnProperty(o) && data[o] === undefined;
        },
        0
      );
      if (checkRes === undefined) {
        setduplicateChecked(false);
        dispatch(checkDuplicate("users", data));
      } else {
        // selectedData.getRow().validate();
      }
    }
  }

  const deleteItem = function() {
    let selectedData = ref.current.table.getSelectedData();

    if (selectedData.length === 0) {
      Swal.fire(
        "",
        "<strong>Please select a user to delete.</strong>",
        "warning"
      );
    } else {
      let postMethod = "DELETE";
      let confirmationMsg =
        selectedData?.length > 1
          ? "<strong>Are you sure you want to delete " +
            selectedData?.length +
            " selected users?</strong>"
          : '<strong>Are you sure you want to delete "' +
            selectedData[0]?.email +
            '"?</strong>';
      Swal.fire({
        html: confirmationMsg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#808080",
        focusConfirm: false,
        confirmButtonText: "Yes, delete.",
        showClass: {
          popup: "animate__animated animate__fadeInDown"
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp"
        }
      }).then(result => {
        if (result.isConfirmed) {
          let data = {
            ids: _.map(selectedData, "id")
          };
          dispatch(updateMasterConfigData(postMethod, "users", data, ""));

          let deletedMssg =
            selectedData.length > 1
              ? "<strong>Users details have been Deleted.</strong>"
              : "<strong>User details has been Deleted.</strong>";
          Swal.fire("", deletedMssg, "success");
          ref.current.table.clearFilter(true);
        }
      });
    }
  };

  const onCellClick = function(e, cell) {
    let currentRow = cell.getRow();
    let currentTable = cell.getTable();
    let rows = currentTable.getRows();
    let currentData = cell.getRow().getData();

    //set showSave icon colom to false for all other rows
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].getData().id !== currentData.id) {
        //except current row
        let cells = rows[i].getCells();
        if (cells[cells.length - 1]) cells[cells.length - 1].setValue(false);
        rows[i].reformat();
      }
    }
    //show save icon for current row only
    let cells = currentRow.getCells();
    if (cells[cells.length - 1]) cells[cells.length - 1].setValue(true);
  };

  const handleOnSearch = (string, results) => {
    if (string.length >= 3) {
      dispatch(fetchAllUserList(string, true));
    } else {
      handleOnClear();
    }
  };

  const handleOnSelect = item => {
    // the item selected
    // let obj = _.filter(userSelected?.current, (o) => o.mail === item?.name);
    // if (obj !== undefined) {
    //   setUserEmail(obj[0]?.mail);
    //   setUserName(obj[0]?.givenName + " " + obj[0]?.sn);
    //   setWwid(obj[0]?.employeeID);
    // }
    if (item !== undefined) {
      setUserEmail(item?.name);
      setUserName(item?.givenName + " " + item?.sn);
      setWwid(item?.id);
    }
  };

  const handleOnClear = item => {
    // the item unselected
    setUserEmail("");
    setUserName("");
    setWwid("");
    setSelectedRoles([]);
    setRegionId("");
    multiselectRef.current.resetSelectedValues();
    if (allUserList) {
      allUserList.users = [];
    }
  };

  const handleOnFocus = () => {};

  const sectorOnSelect = (selectedList, selectedItem) => {
    setSelectedRoles(selectedList);
    let baCheck = _.find(selectedList, o => {
      return o?.name === "Business Admin";
    })?.name;
    if (baCheck === "Business Admin") {
      setIsRegion(true);
    } else {
      setIsRegion(false);
      setRegionId("");
      setShowRegionBorder(false);
    }
  };
  const sectorOnRemove = (selectedList, removedItem) => {
    setSelectedRoles(selectedList);
    let baCheck = _.find(selectedList, o => {
      return o?.name === "Business Admin";
    })?.name;
    if (baCheck === "Business Admin") {
      setIsRegion(true);
    } else {
      setIsRegion(false);
      setRegionId("");
      setShowRegionBorder(false);
    }
  };

  const DropDownComp = props => {
    if (props.listObj !== undefined) {
      return (
        <select
          className={props.className}
          value={props.objId}
          onChange={e => props.callOnChange(e.target.value)}
          disabled={props.disabled}
        >
          <option value="">Select</option>
          {props.listObj}
        </select>
      );
    }
    return (
      <select>
        <option>Select</option>
      </select>
    );
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        After editing any row, Please ensure to save changes before moving to
        next row.
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {accessUrl.current !== "" &&
        (!isAccess ? <Redirect to={accessUrl.current} /> : "")}
      {isAccess ? (
        <div className="masterdata-config">
          <div>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              toastStyle={{
                border: "1px solid",
                boxShadow: "10px 10px 16px 0px rgba(0,0,0,0.75)",
                color: "black",
                backgroundColor: "#e6e6e6",
                fontWeight: "bold"
              }}
            />
          </div>
          <div className="table-heading">
            <span>Assign User Roles</span> &nbsp;&nbsp;
            <div>
              <Image
                src={add}
                className="add-item"
                alt="Add New Item"
                onClick={() => addNewRow()}
              ></Image>
            </div>
            <OverlayTrigger
              trigger="click"
              placement="right"
              rootClose={true}
              overlay={popover}
            >
              <Button className="Info-btn-tbulator">i</Button>
            </OverlayTrigger>
            <div className="delete-item">
              {/* {rowCount ?.length === 1 ?
                        <button onClick={() => editItem()} className="usr-edit-btn">
                            <Image src={process.env.PUBLIC_URL + '/edit-icon.png'} alt="edit"></Image>
                        </button> : ''
                    } */}
              {rowSelected ? (
                <button onClick={() => deleteItem()}>
                  <Image src={deleteicon} alt="Delete"></Image>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          {showForm ? (
            <Container className="user-roles-form">
              <Row>
                <Col className="label-name" md={1}>
                  Email ID
                </Col>
                <Col md={4}>
                  <div
                    className={
                      showEmailBorder ? "auto-select-check" : undefined
                    }
                    id="userroles_emailid_id"
                  >
                    <ReactSearchAutocomplete
                      items={allUserList?.users}
                      onSearch={handleOnSearch}
                      // onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      onFocus={handleOnFocus}
                      inputDebounce="400"
                      onClear={handleOnClear}
                      autoFocus
                      // formatResult={formatResult}
                      inputSearchString={userEmail}
                    />
                  </div>
                </Col>
                <Col className="label-name" md={1}>
                  Name
                </Col>
                <Col md={3}>
                  <input
                    type="text"
                    value={userName}
                    disabled
                    className="user-roles-input"
                    id="userroles_username_id"
                  />
                </Col>
                <Col className="label-name" md={1}>
                  WWID
                </Col>
                <Col md={2}>
                  <input
                    type="text"
                    value={wwid}
                    disabled
                    className="user-roles-input"
                    id="userroles_wwid_id"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="label-name" md={1}>
                  Roles
                </Col>
                <Col
                  md={4}
                  className={showRolesBorder ? "multiselectborder" : undefined}
                >
                  {/* {isEditing ? (
                                <Multiselect
                                    options={rolesList.current}
                                    selectedValues={selectedRoles}
                                    onSelect={sectorOnSelect}
                                    onRemove={sectorOnRemove}
                                    displayValue="name"
                                />
                            ) : ( */}
                  <Multiselect
                    options={rolesList.current}
                    onSelect={sectorOnSelect}
                    onRemove={sectorOnRemove}
                    displayValue="name"
                    ref={multiselectRef}
                    id="userroles_role_id"
                    avoidHighlightFirstOption={true}
                  />
                  {/* )} */}
                </Col>
                <Col className="label-name user-baregion" md={1}>
                  BA Region
                </Col>
                <Col md={3}>
                  <DropDownComp
                    listObj={regionOptions.current}
                    callOnChange={setRegionId}
                    objId={regionId}
                    disabled={!isRegion}
                    className={
                      showRegionBorder
                        ? "select-config user-roles-input user-region-select"
                        : "select-config user-roles-input"
                    }
                  ></DropDownComp>
                </Col>
                <Col md={3} className="usr-add-button">
                  <Button variant="secondary" onClick={onAddRole}>
                    Add User Details
                  </Button>
                </Col>
              </Row>
            </Container>
          ) : (
            ""
          )}
          <div className="tabulator-wrapper">
            {userData !== undefined ? (
              <ReactTabulator
                ref={a => (ref.current = a)}
                columns={tableColumns}
                data={userData}
                key={"userRoles"}
                cellClick={onCellClick}
                rowSelectionChanged={function(data, rows) {
                  //row - row component for the selected row
                  if (rows.length > 0) {
                    // if (rows.length === 1) { setIsEditing(true) } else { setIsEditing(false) }
                    if (rows.length > 1) {
                      setShowForm(false);
                    }
                    setRowSelected(true);
                  } else {
                    setRowSelected(false);
                  }
                }}
                height="100%"
                layout="fitColumns"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
