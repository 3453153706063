import XLSX from "xlsx";

export function ExcelRenderer(file, callback) {
  return new Promise(function(resolve, reject) {
    var reader = new FileReader();
    var rABS = !!reader.readAsBinaryString;

    reader.onload = function(e) {
      /* Parse data */
      var bstr = e.target.result;
      var wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

      var allData = {};
      for (var index = 0; index < wb.SheetNames.length; index++) {
        /* Get each worksheet */
        var wsname = wb.SheetNames[index];
        var worksheet = wb.Sheets[wsname];

        /* Convert array of arrays */
        var json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        var cols = make_cols(worksheet["!ref"]);

        var data = { rows: json, cols: cols };
        resolve(data);

        allData[wsname] = data;
      }

      resolve(allData);
      return callback(null, allData);
    };
    if (file && rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
}

function make_cols(refstr) {
  var o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) {
    o[i] = { name: XLSX.utils.encode_col(i), key: i };
  }
  return o;
}
