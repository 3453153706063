const pageConfigReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_PAGE_CONFIG_DATA":
      return {
        ...state,
        data: action.payload
      };
    case "GET_PAGES":
      return {
        ...state,
        pages: action.payload
      };
    case "GET_PAGE_CONFIG_DETAIL":
      return {
        ...state,
        configDetail: action.payload,
        isDetailLoaded: true
      };
    case "POST_PAGE_CONFIG_DATA":
      return {
        ...state,
        updatedData: action.payload,
        dataUpdated: true
      };
    case "CLEAR_DATA":
      return {
        ...state,
        isDetailLoaded: false
      };
    case "API_START":
      if (
        action.payload === "FETCH_PAGES" ||
        action.payload === "FETCH_PAGE_CONFIG_DATA" ||
        action.payload === "FETCH_PAGE_CONFIG_DETAIL"
      ) {
        return {
          ...state,
          isLoadingData: true
        };
      } else if (action.payload === "UPDATE_PAGE_CONFIG_DATA") {
        return {
          ...state,
          isUpdatingData: true,
          dataUpdated: false
        };
      }
      break;
    case "API_END":
      if (
        action.payload === "FETCH_PAGES" ||
        action.payload === "FETCH_PAGE_CONFIG_DATA" ||
        action.payload === "FETCH_PAGE_CONFIG_DETAIL"
      ) {
        return {
          ...state,
          isLoadingData: false
        };
      } else if (action.payload === "UPDATE_PAGE_CONFIG_DATA") {
        return {
          ...state,
          isUpdatingData: false,
          dataUpdated: true
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default pageConfigReducer;
