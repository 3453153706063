import apiAction from "./apiActionMethod";

export function fetchRateCardData(dataToProcess) {
  return apiAction.updateAPI({
    url: process.env.REACT_APP_API_BASE_URL + "rateCard",
    method: "POST",
    data: dataToProcess,
    onSuccess: getRateCardData,
    onFailure: err =>
      console.log("Error occured in fetching rate card data. ", err),
    label: "FETCH_RATE_CARD_DATA"
  });
}

function getRateCardData(data) {
  return {
    type: "GET_RATE_CARD_DATA",
    payload: data
  };
}

export function previewCalcRateCardData(dataToProcess) {
  return apiAction.updateAPI({
    url: process.env.REACT_APP_API_BASE_URL + "previewRateCard",
    method: "POST",
    data: dataToProcess,
    onSuccess: previewRateCardData,
    onFailure: err =>
      console.log("Error occured in preview rate card data. ", err),
    label: "PREVIEW_RATE_CARD_DATA"
  });
}

function previewRateCardData(data) {
  return {
    type: "PREVIEW_RATE_CARD_DATA",
    payload: data
  };
}

export function setReferenceNumberData(data) {
  return {
    type: "SET_REFERENCE_NUMBER_DATA",
    payload: data
  };
}
