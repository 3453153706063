const rateCardReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_RATE_CARD_DATA":
      return {
        ...state,
        rateCardData: action.payload
      };
    case "PREVIEW_RATE_CARD_DATA":
      return {
        ...state,
        rateCardData: action.payload
      };
    case "SET_REFERENCE_NUMBER_DATA":
      return {
        ...state,
        referenceNumberData: action.payload
      };
    case "API_START":
      if (action.payload === "FETCH_RATE_CARD_DATA") {
        return {
          ...state,
          isLoadingRateCardData: true
        };
      }
      break;
    case "API_END":
      if (action.payload === "FETCH_RATE_CARD_DATA") {
        return {
          ...state,
          isLoadingRateCardData: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default rateCardReducer;
