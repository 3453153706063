import userInfoReducer from "./userInfo";
import masterDataConfigReducer from "./masterDataConfig";
import pageConfigReducer from "./pageConfig";
import calcConfigReducer from "./calcConfig";
import calculatorReducer from "./calculator";
import kolAssessmentReducer from "./kolAssessment";
import appMessagesReducer from "./appMessages";
import rateCardReducer from "./rateCard";
import kolDefinitionReducer from "./kolDefinition";
import translationReducer from "./translation";
import endUserActivityReducer from "./endUserActivity";
import calculatorHistoryReducer from "./calculatorHistory";
import dependencyCheckReducer from "./dependencyCheck";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import userInfoAuthReducer from "./UserInfoAuth";

const rootReducer = history =>
  combineReducers({
    userInformation: userInfoReducer,
    userInformationAuth: userInfoAuthReducer,
    masterDataConfig: masterDataConfigReducer,
    pageConfig: pageConfigReducer,
    calcConfig: calcConfigReducer,
    calculator: calculatorReducer,
    kolAssessment: kolAssessmentReducer,
    appMessages: appMessagesReducer,
    rateCard: rateCardReducer,
    kolDefinition: kolDefinitionReducer,
    translation: translationReducer,
    endUserActivity: endUserActivityReducer,
    calculatorHistory: calculatorHistoryReducer,
    dependencyCheck: dependencyCheckReducer,
    router: connectRouter(history)
  });

export default rootReducer;