import apiAction from "./apiActionMethod";

export function fetchMasterData(listname) {
  let onSuccessMethod;

  // eslint-disable-next-line default-case
  switch (listname.toLowerCase()) {
    case "sectors":
      onSuccessMethod = getSectorsMasterData;
      break;
    case "regions":
      onSuccessMethod = getRegionsMasterData;
      break;
    case "countries":
      onSuccessMethod = getCountriesMasterData;
      break;
    case "languages":
      onSuccessMethod = getLanguagesMasterData;
      break;
    case "currencies":
      onSuccessMethod = getCurrenciesMasterData;
      break;
    case "percentiles":
      onSuccessMethod = getPercentilesMasterData;
      break;
    case "segments":
      onSuccessMethod = getSegmentsMasterData;
      break;
    case "hcp_statures":
      onSuccessMethod = getHCPStatureMasterData;
      break;
    case "activity_types":
      onSuccessMethod = getActivityTypeMasterData;
      break;
    case "travel_distances":
      onSuccessMethod = getTravelDistanceMasterData;
      break;
    case "travel_time":
      onSuccessMethod = getTravelTimeMasterData;
      break;
    case "hcp_specialities":
      onSuccessMethod = getHCPSpecialtiesMasterData;
      break;
    case "hcp_speciality_list":
      onSuccessMethod = getHCPSpecialtyGroupMasterData;
      break;
    case "variables":
      onSuccessMethod = getVariablesMasterData;
      break;
    case "reference_categories":
      onSuccessMethod = getReferenceCategoryMasterData;
      break;
  }
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "masterData/" +
      listname.toLowerCase() +
      "/ACTIVE/dashboard",
    onSuccess: onSuccessMethod,
    onFailure: (err) =>
      console.log(
        "Error occured in fetching master data for calculator configuration. ",
        err
      ),
    label: "FETCH_MASTER_DATA",
  });
}

function getSectorsMasterData(data) {
  return {
    type: "GET_SECTORS_MASTER_DATA",
    payload: data,
  };
}
function getRegionsMasterData(data) {
  return {
    type: "GET_REGIONS_MASTER_DATA",
    payload: data,
  };
}
function getCountriesMasterData(data) {
  return {
    type: "GET_COUNTRY_MASTER_DATA",
    payload: data,
  };
}
function getLanguagesMasterData(data) {
  return {
    type: "GET_LANGUAGE_MASTER_DATA",
    payload: data,
  };
}
function getCurrenciesMasterData(data) {
  return {
    type: "GET_CURRENCY_MASTER_DATA",
    payload: data,
  };
}
function getPercentilesMasterData(data) {
  return {
    type: "GET_PERCENTILE_MASTER_DATA",
    payload: data,
  };
}
function getSegmentsMasterData(data) {
  return {
    type: "GET_SEGMENTS_MASTER_DATA",
    payload: data,
  };
}
function getHCPStatureMasterData(data) {
  return {
    type: "GET_HCPSTATURE_MASTER_DATA",
    payload: data,
  };
}
function getActivityTypeMasterData(data) {
  return {
    type: "GET_ACTIVITY_TYPE_MASTER_DATA",
    payload: data,
  };
}
function getVariablesMasterData(data) {
  return {
    type: "GET_VARIABLES_MASTER_DATA",
    payload: data,
  };
}
function getTravelDistanceMasterData(data) {
  return {
    type: "GET_TRAVEL_DISTANCE_MASTER_DATA",
    payload: data,
  };
}
// get travel time percentage
function getTravelTimeMasterData(data) {
  return {
    type: "GET_TRAVEL_TIME_MASTER_DATA",
    payload: data,
  };
}
function getHCPSpecialtiesMasterData(data) {
  return {
    type: "GET_HCP_SPECIALTY_MASTER_DATA",
    payload: data,
  };
}
function getHCPSpecialtyGroupMasterData(data) {
  return {
    type: "GET_HCP_SPECIALTY_GROUP_MASTER_DATA",
    payload: data,
  };
}
function getReferenceCategoryMasterData(data) {
  return {
    type: "GET_REFERENCE_CATEGORY_MASTER_DATA",
    payload: data,
  };
}
// fetching data for calculator configuration
export function fetchCalcConfigData(calculatorId, calculatorVersion) {
  let apiURL =
    calculatorVersion !== undefined && calculatorVersion !== ""
      ? process.env.REACT_APP_API_BASE_URL +
        "calcConfigs/" +
        calculatorId +
        "/" +
        calculatorVersion
      : process.env.REACT_APP_API_BASE_URL + "calcConfigs/" + calculatorId;

  return apiAction.getAPI({
    url: apiURL,
    onSuccess: getCalcConfigData,
    onFailure: (err) =>
      console.log("Error occured in fetching calculator config data. ", err),
    label: "FETCH_CALC_CONFIG_DATA",
  });
}

function getCalcConfigData(data) {
  
  return {
    type: "GET_CALC_CONFIG_DATA",
    payload: data,
  };
}

export function setCalcConfigMain(data) {
  return {
    type: "SET_CALC_CONFIG_MAIN",
    payload: data,
  };
}
export function setCalcConfigBasicData(data) {
  return {
    type: "SET_CALC_CONFIG_BASIC_DATA",
    payload: data,
  };
}
export function setCalcConfigHideMultiplierData(data) {
  return {
    type: "SET_CALC_CONFIG_HIDE_MULTIPLIER_DATA",
    payload: data,
  };
}
export function setHCPSegmentData(data) {
  return {
    type: "SET_HCP_SEGMENT_DATA",
    payload: data,
  };
}
export function setHCPStatureData(data) {
  return {
    type: "SET_HCP_STATURE_DATA",
    payload: data,
  };
}
export function setCountrySpecLimitData(data) {
  return {
    type: "SET_COUNTRY_SPEC_LIMIT_DATA",
    payload: data,
  };
}
export function setActivityTypeAndCapsData(data) {
  return {
    type: "SET_ACTIVITY_TYPE_AND_CAPS_DATA",
    payload: data,
  };
}
export function setTravelTimeData(data) {
  return {
    type: "SET_TRAVEL_TIME_DATA",
    payload: data,
  };
}
export function setHCPSpecialtyData(data) {
  return {
    type: "SET_HCP_SPECIALTY_DATA",
    payload: data,
  };
}
export function setGroundRulesMessagesData(data) {
  return {
    type: "SET_GROUND_RULES_DATA",
    payload: data,
  };
}

export function checkDuplicate(dataToCheck) {
  return apiAction.updateAPI({
    url: process.env.REACT_APP_API_BASE_URL + "calcConfigs/duplicateCheck",
    method: "POST",
    data: dataToCheck,
    onSuccess: checkDuplicateItem,
    onFailure: (err) =>
      console.log(
        "Error occured while checking duplicate for calculator config. ",
        err
      ),
    label: "CHECK_DUPLICATE",
  });
}
function checkDuplicateItem(data) {
  return {
    type: "CHECK_DUPLICATE_ITEM",
    payload: data,
  };
}

export function saveCalcConfigData(data) {
  return {
    type: "SAVE_CALC_CONFIG_DATA",
    payload: data,
  };
}

export function saveAsNewCalculatorData(data) {
  return {
    type: "SAVE_AS_NEW_CALC_DATA",
    payload: data,
  };
}

export function setIsDataProcessedForSaveAsNew(data) {
  return {
    type: "SET_SAVE_AS_NEW_DATA_PROCESSED",
    payload: data,
  };
}

export function updateCalcConfigData(
  postMethod,
  dataToProcess,
  calculatorId,
  isUnpublish,
  isUnpublishToPublish = false
) {
  let apiURL =
    calculatorId !== undefined && calculatorId !== ""
      ? isUnpublishToPublish
        ? process.env.REACT_APP_API_BASE_URL +
          "calcConfigs/unpublishToPublish/" +
          calculatorId
        : isUnpublish
        ? process.env.REACT_APP_API_BASE_URL +
          "calcConfigs/unpublish/" +
          calculatorId
        : process.env.REACT_APP_API_BASE_URL + "calcConfigs/" + calculatorId
      : process.env.REACT_APP_API_BASE_URL + "calcConfigs";

  return apiAction.updateAPI({
    url: apiURL,
    method: postMethod,
    data: dataToProcess,
    onSuccess: postCalcConfigData,
    onFailure: (err) =>
      console.log("Error occured in updating calculator config data. ", err),
    label: "UPDATE_CALC_CONFIG_DATA",
  });
}
function postCalcConfigData(data) {
  return {
    type: "POST_CALC_CONFIG_DATA",
    payload: data,
  };
}

export function fetchCalcDashboardData() {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "calcConfigs/fetch/allItems/dashboard/ACTIVE",
    onSuccess: getCalcDashboardData,
    onFailure: (err) =>
      console.log("Error occured in fetching calculator dashboard data.", err),
    label: "FETCH_CALC_DASHBOARD_DATA",
  });
}

function getCalcDashboardData(data) {
  return {
    type: "GET_CALC_DASHBOARD_DATA",
    payload: data,
  };
}

export function fetchPagesMessages() {
  return apiAction.getAPI({
    url: process.env.REACT_APP_API_BASE_URL + "pages",
    onSuccess: getPages,
    onFailure: (err) => console.log("Error occured in fetching pages.", err),
    label: "FETCH_MESSAGES_PAGES",
  });
}

function getPages(data) {
  return {
    type: "GET_MESSAGES_PAGES",
    payload: data,
  };
}

export function fetchPageConfigMessagesData(pageId) {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "pageConfig/ACTIVE/" +
      pageId +
      "/dashboard",
    onSuccess: getPageConfigData,
    onFailure: (err) =>
      console.log("Error occured in fetching page config data.", err),
    label: "FETCH_PAGE_CONFIG_MESSAGES_DATA",
  });
}
function getPageConfigData(data) {
  return {
    type: "GET_PAGE_CONFIG_MESSAGES_DATA",
    payload: data,
  };
}

export function fetchPageConfigMessageDetail(contentId) {
  return apiAction.getAPI({
    url: process.env.REACT_APP_API_BASE_URL + "pageConfig/" + contentId,
    onSuccess: getPageConfigDetail,
    onFailure: (err) =>
      console.log("Error occured in fetching page config detail.", err),
    label: "FETCH_PAGE_CONFIG_MESSAGES_DETAIL",
  });
}
function getPageConfigDetail(data) {
  return {
    type: "GET_PAGE_CONFIG_MESSAGES_DETAIL",
    payload: data,
  };
}

export function setIsExecutingAction(data) {
  return {
    type: "IS_EXECUTING_ACTION",
    payload: data,
  };
}
