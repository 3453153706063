import apiAction from "./apiActionMethod";

export function fetchKolDefinitionData() {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "masterData/hcp_kol_stature_question_defns/ACTIVE/dashboard",
    onSuccess: getKolDefinitionData,
    onFailure: err =>
      console.log("Error occured in fetching kol definitionData data.", err),
    label: "FETCH_KOL_DEFINITION_DATA"
  });
}

function getKolDefinitionData(data) {
  return {
    type: "GET_KOL_DEFINITION_DATA",
    payload: data
  };
}
