import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useMsal, useIsAuthenticated } from "@azure/msal-react";
// import { loginRequest } from "../authentication/authConfig";
import { RenderRichText } from "../helper/renderRichText";
import _ from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchPages, fetchPageConfigData } from "../actions/pageConfig";

export function Home() {
  const dispatch = useDispatch();

  const [initializer, setInitializer] = useState(false);
  const [contentHtml, setContentHtml] = useState("");

  const pageConfig = useSelector(state => state.pageConfig.pages);
  const pageConfigData = useSelector(state => state.pageConfig.data);

  useEffect(() => {
    if (!initializer) {
      dispatch(fetchPages());
    }
    setInitializer(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageConfig !== undefined) {
      let landingPageInfo = _.find(
        pageConfig?.data,
        o => {
          return o.page_name.trim().toLowerCase() === "landing page";
        },
        0
      );

      if (landingPageInfo !== undefined) {
        dispatch(fetchPageConfigData(landingPageInfo.id));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageConfig]);

  useEffect(() => {
    if (pageConfigData !== undefined) {
      let pagecontent = _.find(
        pageConfigData?.data,
        o => {
          return o.content_type.trim().toLowerCase() === "page content";
        },
        0
      );

      if (pagecontent !== undefined) {
        const content = pagecontent.contents.replace(
          "[APIBASE]",
          process.env.REACT_APP_API_BASE_URL
        );
        setContentHtml(content);
      }
    }
  }, [pageConfigData]);

  return (
    <div className="home-page">
      <RenderRichText html={contentHtml} />
    </div>
  );
}

export default Home;
