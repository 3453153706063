const userInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_USER_INFO":
      return {
        ...state,
        data: action.payload
      };
    case "API_START":
      return {
        ...state,
        isLoadingData: true
      };
    case "API_END":
      return {
        ...state,
        isLoadingData: false
      };
    default:
      return state;
  }  
};

export default userInfoReducer;
