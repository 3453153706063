import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactTabulator } from "react-tabulator";
import { Row, Col, Button, Popover, OverlayTrigger } from "react-bootstrap";
import {
  fetchMasterData,
  setHCPStatureData,
  setIsDataProcessedForSaveAsNew
} from "../actions/calcConfig";
import _ from "lodash";
import { Calc_Tabs } from "../helper/constants";

export function HCPStature({ tabChangeHandler }) {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [initialData, setInitialData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [tabledata, setTableData] = useState(null);

  const calcConfigHCPStature = useSelector(
    state => state.calcConfig?.calcConfigData?.data?.calcConfigKolStatureMul
  );
  const hcpStatureMaster = useSelector(
    state => state.calcConfig.hcpStatureMasterData
  );
  const variablesData = useSelector(state => state.calcConfig.variablesData);
  const saveAsNew = useSelector(state => state.calcConfig.saveAsNew);

  // const variablesOptions = useRef({});
  // const multiplierHeaderOptions = useRef({});

  useEffect(() => {
    dispatch(fetchMasterData("hcp_statures"));
    dispatch(fetchMasterData("variables"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (variablesData !== undefined) {
      // let i = 0, s = 0, e = 0;
      // variablesData ?.data.map((o) => {
      //     if (o.key === "HCP Stature Multiplier Increment") {
      //         i = o.value
      //     }
      //     if (o.key === "HCP Stature Multiplier Start Value") {
      //         s = o.value
      //     }
      //     if (o.key === "HCP Stature Multiplier End Value") {
      //         e = o.value
      //     }
      // });
      // let data = []
      // let dataHeader = []
      // let j = parseFloat(s)
      // let l = 1
      // if (i !== undefined && s !== undefined && e !== undefined) {
      //     for (let k = 0; k < l; k++) {
      //         data.push(
      //             {
      //                 multiplier: parseFloat(j).toFixed(2),
      //                 id: j.toFixed(2)
      //             })
      //         dataHeader.push(
      //             {
      //                 multiplier: parseFloat(j).toFixed(2),
      //                 id: j.toFixed(2)
      //             })
      //         j = parseFloat(j) + parseFloat(i)
      //         if (j <= e) {
      //             l++
      //         }
      //     }
      // }
      // multiplierHeaderOptions.current = _.mapValues(_.keyBy(dataHeader, 'id'), 'multiplier')
      // data.push({ id: "", multiplier: "~clear~" })
      // variablesOptions.current = _.mapValues(_.keyBy(data, 'id'), 'multiplier')
    }
  }, [variablesData]);

  useEffect(() => {
    if (hcpStatureMaster !== undefined) {
      setInitialData(
        _.map(hcpStatureMaster.data, row => {
          row.hcp_stature_id = row.id;
          return row;
        })
      );

      let tableColumns = [
        {
          title: "HCP stature",
          field: "value",
          headerFilter: "input",
          headerFilterPlaceholder: "Search by Name",
          tooltip: true
        },
        {
          title: "Market consensus",
          field: "multiplier",
          editor: "number",
          editorParams: { min: 0 },
          headerFilter: "input",
          headerFilterPlaceholder: "Search",
          tooltip: true,
          validator: [
            {
              type: checkNegative
            }
          ]
          // editor: "select",
          // editorParams: {
          //     allowEmpty: false, values: true,
          //     values: variablesOptions.current,
          // },
          // headerFilter: "select",
          // headerFilterPlaceholder: "Filter",
          // headerFilterParams: { values: multiplierHeaderOptions.current },
        }
      ];
      setTableColumns(tableColumns);
    }
  }, [hcpStatureMaster, variablesData]);

  const checkNegative = function(cell, value, parameters) {
    let check = true;
    if (value) {
      check = value > 0;
    }
    //cell - the cell component for the edited cell
    //value - the new input value of the cell
    //parameters - the parameters passed in with the validator
    return check; //don't allow values divisible by devisor ;
  };

  useEffect(() => {
    if (calcConfigHCPStature !== undefined) {
      _.forEach(calcConfigHCPStature, function(obj) {
        _.map(initialData, row => {
          if (row.hcp_stature_id === obj.hcp_stature_id) {
            Object.assign(row, obj);
          }
          return row;
        });
      });
      setInitialData(initialData);
      setTableData(
        _.filter(
          initialData,
          o => {
            return o.multiplier !== undefined && o.multiplier !== "";
          },
          0
        )
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcConfigHCPStature]);

  useEffect(() => {
    if (saveAsNew) {
      let propsToDelete = [
        "item_id",
        "calc_config_id",
        "calc_config_basic_id",
        "updated_at",
        "updated_by"
      ];

      let data = _.forEach(initialData, obj => {
        _.forEach(propsToDelete, prop => {
          delete obj[prop];
        });
      });
      let filteredData = _.filter(
        data,
        o => {
          return o.multiplier !== undefined && o.multiplier !== "";
        },
        0
      );
      setInitialData(data);
      setTableData(filteredData);
      dispatch(setHCPStatureData({ tableData: filteredData }));
      dispatch(setIsDataProcessedForSaveAsNew({ hcpStaturesProcessed: true }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAsNew]);

  useEffect(() => {
    tabChangeHandler.current[Calc_Tabs.TAB_HCPStature] = {
      getTabData: getTabData
    };
    getTabData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabledata]);

  const getTabData = function() {
    dispatch(setHCPStatureData({ tableData: tabledata }));
  };

  function onCellEdited(cell) {
    if (
      cell
        .getRow()
        .getTable()
        .getData() !== undefined
    ) {
      let selectedData = _.filter(
        cell
          .getRow()
          .getTable()
          .getData(),
        o => {
          return o.multiplier !== undefined && o.multiplier !== "";
        },
        0
      );

      if (selectedData !== undefined && selectedData.length > 0) {
        setTableData(
          _.map(selectedData, o => {
            return {
              item_id: o.item_id,
              calc_config_id: o.calc_config_id,
              calc_config_basic_id: o.calc_config_basic_id,
              hcp_stature_id: o.hcp_stature_id,
              multiplier: o.multiplier
            };
          })
        );
      } else if (selectedData !== undefined && selectedData.length === 0) {
        setTableData(selectedData);
      }
    }
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        To exclude any row please leave the Market consensus value blank.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="hcp-stature">
      <Row>
        <Col className="section-header" md={12}>
          HCP KOL Stature and Multipliers
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            overlay={popover}
          >
            <Button className="Info-btn-tbulator">i</Button>
          </OverlayTrigger>
        </Col>
      </Row>

      {hcpStatureMaster !== undefined ? (
        <ReactTabulator
          ref={a => (tableRef.current = a)}
          columns={tableColumns}
          data={initialData}
          key={"hcpStature"}
          cellEdited={onCellEdited}
          height="100%"
          layout="fitColumns"
        />
      ) : (
        ""
      )}
    </div>
  );
}
