import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

export function TextEditor(props) {
  const editorbuttonList = [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["paragraphStyle", "blockquote"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor" /**, 'textStyle' */],
    ["removeFormat"],
    "/", // Line break
    ["outdent", "indent"],
    ["align" /** , 'horizontalRule'*/, "list", "lineHeight"],
    ["table", "link", "image" /**, 'video', 'audio'  ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin. // You must add the "imageGalleryUrl".
    /** ['imageGallery'] */ ["fullScreen", "showBlocks", "codeView"]
    // ['preview', 'print'],
    // ['save', 'template'],
    /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
  ];

  return (
    <SunEditor
      onChange={props.onChange}
      setContents={props.setContents}
      setOptions={{ buttonList: editorbuttonList }}
      height="100%"
    />
  );
}
