import { useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

let pagesAccess = [];

const ShowForPermissionComponent = (props) => {
  UserAccessRoles();

  let isAccess = false;
  switch (props?.UserRolesPermission) {
    case "Master Data Management":
      _.map(pagesAccess, (o) => {
        if (o === "Master Data Management") {
          isAccess = true;
        }
      });
      break;
    case "activity_types":
      _.map(pagesAccess, (o) => {
        if (o === "activity_types") {
          isAccess = true;
        }
      });
      break;
    case "variables":
      _.map(pagesAccess, (o) => {
        if (o === "variables") {
          isAccess = true;
        }
      });
      break;
    case "currencies":
      _.map(pagesAccess, (o) => {
        if (o === "currencies") {
          isAccess = true;
        }
      });
      break;
    case "countries":
      _.map(pagesAccess, (o) => {
        if (o === "countries") {
          isAccess = true;
        }
      });
      break;
    case "nav_links":
      _.map(pagesAccess, (o) => {
        if (o === "nav_links") {
          isAccess = true;
        }
      });
      break;
    case "hcp_specialities":
      _.map(pagesAccess, (o) => {
        if (o === "hcp_specialities") {
          isAccess = true;
        }
      });
      break;
    case "hcp_speciality_list":
      _.map(pagesAccess, (o) => {
        if (o === "hcp_speciality_list") {
          isAccess = true;
        }
      });
      break;
    case "hcp_statures":
      _.map(pagesAccess, (o) => {
        if (o === "hcp_statures") {
          isAccess = true;
        }
      });
      break;
    case "hcp_kol_stature_question_defns":
      _.map(pagesAccess, (o) => {
        if (o === "hcp_kol_stature_question_defns") {
          isAccess = true;
        }
      });
      break;
    case "languages":
      _.map(pagesAccess, (o) => {
        if (o === "languages") {
          isAccess = true;
        }
      });
      break;
    case "pageconfig":
      _.map(pagesAccess, (o) => {
        if (o === "pageconfig") {
          isAccess = true;
        }
      });
      break;
    case "percentiles":
      _.map(pagesAccess, (o) => {
        if (o === "percentiles") {
          isAccess = true;
        }
      });
      break;
    case "reference_categories":
      _.map(pagesAccess, (o) => {
        if (o === "reference_categories") {
          isAccess = true;
        }
      });
      break;
    case "regions":
      _.map(pagesAccess, (o) => {
        if (o === "regions") {
          isAccess = true;
        }
      });
      break;
    case "sectors":
      _.map(pagesAccess, (o) => {
        if (o === "sectors") {
          isAccess = true;
        }
      });
      break;
    case "segments":
      _.map(pagesAccess, (o) => {
        if (o === "segments") {
          isAccess = true;
        }
      });
      break;
    case "travel_distances":
      _.map(pagesAccess, (o) => {
        if (o === "travel_distances") {
          isAccess = true;
        }
      });
      break;
    //travel time discount page access
    case "travel_time":
      _.map(pagesAccess, (o) => {
        if (o === "travel_time") {
          isAccess = true;
        }
      });
      break;
    case "Calculator configuration":
      _.map(pagesAccess, (o) => {
        if (o === "Calculator configuration") {
          isAccess = true;
        }
      });
      break;
    case "calculatordashboard":
      _.map(pagesAccess, (o) => {
        if (o === "calculatordashboard") {
          isAccess = true;
        }
      });
      break;
    case "calculatorconfig":
      _.map(pagesAccess, (o) => {
        if (o === "calculatorconfig") {
          isAccess = true;
        }
      });
      break;
    default:
      isAccess = false;
      break;
  }
  return isAccess ? props.children : null;
};

function UserAccessRoles(props) {
  //   const dispatch = useDispatch();

  const userInformation = useSelector((state) => state.userInformation);

  // useEffect(() => {
  //     let token = getAuthToken()
  //     let userId = token.userEmailId
  //     if (userInformation === undefined || userInformation ?.data === undefined) {
  //         dispatch(fetchUserInformation(userId))
  //     }
  // }, [])

  useEffect(() => {
    if (userInformation !== undefined && userInformation?.data !== undefined) {
      pagesAccess = userInformation?.data?.users[0]?.accessPages;
    }
  }, [userInformation]);
}

export const UserAccess = ShowForPermissionComponent;
