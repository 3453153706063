import apiAction from "./apiActionMethod";

export function fetchKolDashboardData() {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "kolAssessments/fetch/allItems/dashboard/ACTIVE",
    onSuccess: getKolDashboardData,
    onFailure: err =>
      console.log("Error occured in fetching KOL dashboard data. ", err),
    label: "FETCH_KOL_DASHBOARD_DATA"
  });
}

function getKolDashboardData(data) {
  return {
    type: "GET_KOL_DASHBOARD_DATA",
    payload: data
  };
}

export function fetchKOLSectionDetail(itemId) {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL + "kolAssessments/section/" + itemId,
    onSuccess: getKolSectionDetail,
    onFailure: err =>
      console.log("Error occured in fetching KOL section detail. ", err),
    label: "FETCH_KOL_SECTION_DETAIL"
  });
}

function getKolSectionDetail(data) {
  return {
    type: "GET_KOL_SECTION_DETAIL",
    payload: data
  };
}

export function fetchKOLAssessmentDetail(itemId) {
  return apiAction.getAPI({
    url:
      process.env.REACT_APP_API_BASE_URL +
      "kolAssessments/assessment/" +
      itemId,
    onSuccess: getKolAssessmentDetail,
    onFailure: err =>
      console.log("Error occured in fetching KOL assessment detail. ", err),
    label: "FETCH_KOL_ASSESSMENT_DETAIL"
  });
}

function getKolAssessmentDetail(data) {
  return {
    type: "GET_KOL_ASSESSMENT_DETAIL",
    payload: data
  };
}

export function updateKOLAssessmentConfig(
  postMethod,
  dataToProcess,
  itemType,
  itemId
) {
  let apiURL =
    postMethod.toUpperCase() === "PATCH"
      ? process.env.REACT_APP_API_BASE_URL +
        "kolAssessments/" +
        itemType.toLowerCase() +
        "/" +
        itemId
      : process.env.REACT_APP_API_BASE_URL + "kolAssessments";

  return apiAction.updateAPI({
    url: apiURL,
    method: postMethod,
    data: dataToProcess,
    onSuccess: postKOLAssessmentConfig,
    onFailure: err =>
      console.log(
        "Error occured in saving/updating KOL assessment configuration. ",
        err
      ),
    label: "UPDATE_KOL_ASSESSMENT_CONFIG"
  });
}

function postKOLAssessmentConfig(data) {
  return {
    type: "POST_KOL_ASSESSMENT_CONFIG",
    payload: data
  };
}

export function deleteKOLAssessmentConfig(dataToProcess, itemType) {
  return apiAction.updateAPI({
    url: process.env.REACT_APP_API_BASE_URL + "kolAssessments/" + itemType,
    method: "DELETE",
    data: dataToProcess,
    onSuccess: deleteKOLConfigData,
    onFailure: err =>
      console.log(
        "Error occured in deleting KOL assessment configuration. ",
        err
      ),
    label: "DELETE_KOL_ASSESSMENT_CONFIG"
  });
}

function deleteKOLConfigData(data) {
  return {
    type: "DELETE_KOL_CONFIG_DATA",
    payload: data
  };
}
