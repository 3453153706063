const calculatorReducer = (state = {}, action) => {
  switch (action.type) {
    case "GET_COUNTRY_SECTOR_OPTIONS":
      return {
        ...state,
        countrySectorOptions: action.payload
      };
    case "GET_CALCULATOR_DATA":
      return {
        ...state,
        calculatorData: action.payload
      };
    case "RESET_CALCULATOR_DATA":
      return {
        ...state,
        calculatorData: action.payload
      };
    case "PREVIEW_END_USER_CALCULATOR_DATA":
      return {
        ...state,
        calculatorData: action.payload
      };
    case "SET_SELECTED_TRANS_LANGUAGE":
      return {
        ...state,
        selectedTransLanguage: action.payload
      };
    case "SET_SELECTED_TRANSLATION_DATA":
      return {
        ...state,
        selectedTranslationData: action.payload
      };
    case "SET_ISPREVIEW_CALCULATOR_DATA":
      return {
        ...state,
        isPreviewCalculatorData: action.payload
      };
    case "SET_COUNTRY_SEACTOR_NAME":
      return {
        ...state,
        contrySectorNameData: action.payload
      };
    case "SET_CALCULATOR_HEADER_DATA":
      return {
        ...state,
        calculatorHeaderData: action.payload
      };
    case "SET_DRAFT_VERSION_STATUS":
      return {
        ...state,
        draftVersionStatusData: action.payload
      };
    case "API_START":
      if (action.payload === "FETCH_COUNTRY_SECTOR_OPTIONS") {
        return {
          ...state,
          isLoadingCountrySectorOptions: true
        };
      } else if (action.payload === "FETCH_CALCULATOR_DATA") {
        return {
          ...state,
          isLoadingData: true
        };
      } else if (action.payload === "PREVIEW_CALCULATOR_DATA") {
        return {
          ...state,
          isLoadingData: true
        };
      }
      break;
    case "API_END":
      if (action.payload === "FETCH_COUNTRY_SECTOR_OPTIONS") {
        return {
          ...state,
          isLoadingCountrySectorOptions: false
        };
      } else if (action.payload === "FETCH_CALCULATOR_DATA") {
        return {
          ...state,
          isLoadingData: false
        };
      } else if (action.payload === "PREVIEW_CALCULATOR_DATA") {
        return {
          ...state,
          isLoadingData: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default calculatorReducer;
