import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import showtoast from "../helper/showToastAlert";
import Swal from "sweetalert2";
import _ from "lodash";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  fetchLanguageMaster,
  fetchLanguageTranslation,
  updateLanguageTranslation
} from "../actions/translation";
import Template from "../helper/translationTemplate.json";
// import CsvDownload from "react-json-to-csv";
import { CSVLink } from "react-csv";
import Papa from "papaparse";

export function LanguageTranslation() {
  const dispatch = useDispatch();

  const [languagesListObj, setLanguagesListObj] = useState([]);
  const [languageId, setLanguageId] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [currentTranslation, setCurrentTranslation] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [isAccess, setIsAccess] = useState(false);
  const [isLanguageSelected, setIsLanguageSelected] = useState(false);

  const userInformation = useSelector(state => state.userInformation);
  const languagesOptions = useSelector(
    state => state.translation.languagemaster
  );
  const languageTranslationData = useSelector(
    state => state.translation.languageTranslation
  );
  const languageTranslationUpdated = useSelector(
    state => state.translation.languageTranslationData
  );

  const accessUrl = useRef("");
  const currentTranslationObj = useRef([]);

  useEffect(() => {
    dispatch(fetchLanguageMaster());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (languageId !== undefined && languageId !== "") {
      dispatch(fetchLanguageTranslation(languageId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageId, languageTranslationUpdated]);

  useEffect(() => {
    if (userInformation !== undefined && userInformation?.data !== undefined) {
      let pagesAccess = userInformation?.data?.users[0]?.accessPages;
      if (pagesAccess !== undefined) {
        _.map(pagesAccess, o => {
          if (o === "Master Data Management") {
            setIsAccess(true);
          }
        });
        accessUrl.current = "/accessdenied";
      }
    }
  }, [userInformation]);

  useEffect(() => {
    if (languagesOptions !== undefined) {
      let languagesList = languagesOptions?.data.map((obj, i) => {
        return (
          <option
            key={i}
            value={obj.id}
            id={"masterdata-translationlanguage-option-" + obj.value}
          >
            {obj.value}
          </option>
        );
      });
      setLanguagesListObj(languagesList);
    }
  }, [languagesOptions]);

  useEffect(() => {
    if (languageTranslationData?.data !== undefined) {
      setCurrentTranslation(languageTranslationData?.data[0]?.translations);
      currentTranslationObj.current = [
        JSON.parse(languageTranslationData?.data[0]?.translations)
      ].flat();
    }
  }, [languageTranslationData]);

  useEffect(() => {
    if (languageTranslationUpdated?.data[0]?.translations !== undefined) {
      showtoast.showSuccess("File uploaded successfully!");
      if (selectedFile !== "") {
        let path = "";
        setSelectedFile(path);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageTranslationUpdated]);

  const changeHandler = event => {
    let fileupload = document.getElementById("uploadFileId");
    if (fileupload.value) {
      if (fileupload.value.match(/csv$/)) {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
      } else {
        Swal.fire(
          "",
          "<strong>Please upload a csv file for translation.</strong><h6>You can download the sample template for reference and edit it for translation.</h6>",
          "warning"
        );
      }
    }
  };

  const DropDownComp = props => {
    if (props.listObj !== undefined) {
      return (
        <select
          className="select-config"
          value={props.objId}
          onChange={e => props.callOnChange(e.target.value)}
          id={props.selecteId}
        >
          <option value="">Select</option>
          {props.listObj}
        </select>
      );
    }
    return (
      <select>
        <option>Select</option>
      </select>
    );
  };

  const OnSelectLanguage = value => {
    setLanguageId(value);
    setIsLanguageSelected(true);
    if (selectedFile !== "") {
      let path = "";
      setSelectedFile(path);
    }
  };

  const handleSubmission = () => {
    const files = selectedFile;
    if (files) {
      Papa.parse(files, {
        complete: function(results) {
          let data = [];
          if (results?.data !== undefined && results?.data !== "") {
            // eslint-disable-next-line array-callback-return
            results?.data?.map((arr, index) => {
              if (index > 0) {
                let first = "",
                  second = "";
                if (arr.length > 0) {
                  if (arr.length > 1) {
                    // eslint-disable-next-line array-callback-return
                    arr.map((obj, i) => {
                      if (i === 0) {
                        if (obj !== null && obj !== "null") {
                          first = obj;
                        }
                      }
                      if (i === 1) {
                        if (obj !== null && obj !== "null") {
                          second = obj;
                        }
                      }
                    });
                  } else if (arr.length === 1) {
                    // eslint-disable-next-line array-callback-return
                    arr.map((obj, i) => {
                      if (i === 0) {
                        if (obj !== null && obj !== "null") {
                          first = obj;
                          second = "";
                        }
                      }
                    });
                  }
                  data.push({ KEY: first, VALUE: second });
                }
              }
            });
          }

          const myJSON = JSON.stringify(data);
          let datatoprocess = {
            value: _.find(languagesOptions?.data, o => {
              return o?.id === languageId;
            }).value,
            translations: myJSON
          };
          dispatch(updateLanguageTranslation(datatoprocess, languageId));
        }
      });
    }
  };

  return (
    <>
      {accessUrl.current !== "" &&
        (!isAccess ? <Redirect to={accessUrl.current} /> : "")}
      {isAccess ? (
        <div>
          <div>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              toastStyle={{
                border: "1px solid",
                boxShadow: "10px 10px 16px 0px rgba(0,0,0,0.75)",
                color: "black",
                backgroundColor: "#e6e6e6",
                fontWeight: "bold"
              }}
            />
          </div>
          <Container className="translation-header-div">
            <Row>
              <Col md={12}>
                <h4>Language Translation</h4>
              </Col>
            </Row>
          </Container>
          <Container className="translation-border">
            <Row>
              <Col className="translation-sub-header" md={12}>
                Translation
              </Col>
            </Row>
            <Row>
              <Col className="label-name" md={2}>
                Select Language<span className="asterisk-mandatory">*</span>
              </Col>
              <Col md={3}>
                <DropDownComp
                  listObj={languagesListObj}
                  callOnChange={OnSelectLanguage}
                  objId={languageId}
                  selecteId={"master_data_translationlanguageselect_id"}
                ></DropDownComp>
              </Col>
              <Col />
              <Col md={5} className="download_temp_col">
                <CSVLink
                  href="data:text/csv; charset=utf-8,"
                  data={Template}
                  className="download_template_btn"
                  filename="Sample_Template.csv"
                >
                  Download Sample Template
                </CSVLink>
                <div className="template_msg">
                  *Please do not change the KEY(s) in the downloaded template.
                </div>
              </Col>
            </Row>
            {isLanguageSelected && languageId ? (
              currentTranslation ? (
                <Row className="current_template">
                  <Col className="label-name" md={2}>
                    Current Translation
                  </Col>
                  <Col md={3}>
                    <CSVLink
                      href="data:text/csv; charset=utf-8,"
                      data={currentTranslationObj.current}
                      className="download_template_btn"
                      filename="Translation_Template.csv"
                    >
                      Current Translation Template
                    </CSVLink>
                  </Col>
                </Row>
              ) : (
                <Row className="current_template">
                  <Col md={5} className="template_msg">
                    No translation is available for this language.
                  </Col>
                </Row>
              )
            ) : (
              ""
            )}
            {isLanguageSelected && languageId ? (
              <Row className="current_template">
                <Col className="label-name" md={2}>
                  Upload Translation
                </Col>
                <Col md={4}>
                  <div className="upload_translation">
                    <div className="upload_div">
                      <input
                        type="file"
                        name="file"
                        id="uploadFileId"
                        className="upload_translation_input"
                        onChange={changeHandler}
                        onClick={event => {
                          event.target.value = null;
                        }}
                      />
                    </div>
                    {isSelected && selectedFile ? (
                      <div className="uploaded_file_name">
                        {selectedFile?.name}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Container>
          {isSelected && selectedFile ? (
            <div className="calc-config-buttons">
              <Button
                variant="secondary"
                className="submit_template_btn"
                onClick={handleSubmission}
              >
                Submit
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
