import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import jnjlogo from "../assets/jnj-logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { RenderRichText } from "../helper/renderRichText";
import {
  fetchAppMessages,
  fetchApplicationMessagesData
} from "../actions/appMessages";
import _ from "lodash";

export function AccessDenied() {
  const dispatch = useDispatch();

  const [initialize, setInitialize] = useState(false);
  const [message, setMessage] = useState("");

  const pageConfig = useSelector(state => state.appMessages.appMessagePages);
  const pageConfigData = useSelector(
    state => state.appMessages.pageAppMessageData
  );

  useEffect(() => {
    if (!initialize) {
      dispatch(fetchAppMessages());
    }
    setInitialize(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize]);

  useEffect(() => {
    if (pageConfig !== undefined) {
      let AppMessagePageInfo = _.find(
        pageConfig?.data,
        o => {
          return o.page_name.trim().toLowerCase() === "application messages";
        },
        0
      );

      if (AppMessagePageInfo !== undefined) {
        dispatch(fetchApplicationMessagesData(AppMessagePageInfo.id));
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageConfig]);

  useEffect(() => {
    if (pageConfigData !== undefined) {
      let pagecontent = _.find(
        pageConfigData?.data,
        o => {
          return (
            o.content_type.trim().toLowerCase() === "access denied message"
          );
        },
        0
      );

      if (pagecontent !== undefined) {
        setMessage(pagecontent.contents);
      }
    }
  }, [pageConfigData]);

  return (
    <div>
      <div className="access-denied-page">
        <div className="access-denied-container">
          <Image src={jnjlogo} fluid className="Access-Denied-logo"></Image>
          <RenderRichText html={message} />
          <Link to="/" className="Access-Denied-link">
            Go to the FMV Home
          </Link>
        </div>
      </div>
    </div>
  );
}
