const endUserActivityReducer = (state = {}, action) => {
  switch (action.type) {
    case "POST_END_USER_ACTIVITY":
      return {
        ...state,
        Data: action.payload
      };
    case "SET_END_USER_SESSION_UUID":
      return {
        ...state,
        endUserSessionUuid: action.payload
      };
    case "API_START":
      if (action.payload === "UPDATE_END_USER_ACTIVITY") {
        return {
          ...state,
          isLoadingActivity: true
        };
      }
      break;
    case "API_END":
      if (action.payload === "UPDATE_END_USER_ACTIVITY") {
        return {
          ...state,
          isLoadingActivity: false
        };
      }
      break;
    default:
      return state;
  }
  return state;
};

export default endUserActivityReducer;
