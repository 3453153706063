import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactTabulator } from "react-tabulator";
import { Row, Col, Button, Popover, OverlayTrigger } from "react-bootstrap";
import {
  fetchMasterData,
  setTravelTimeData,
  setIsDataProcessedForSaveAsNew,
} from "../actions/calcConfig";
import _ from "lodash";
import { Calc_Tabs } from "../helper/constants";

export function ActivityLocation({ tabChangeHandler }) {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const [item_id, setItem_id] = useState("");
  const [calc_config_id, setCalc_config_id] = useState("");
  const [calc_config_basic_id, setCalc_config_basic_id] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [tabledata, setTableData] = useState(null);
  const [travelDiscount, setTravelDiscount] = useState("");
  const [dataInitionlizer, setDataInitionlizer] = useState(false);

  const calcConfigTravelTime = useSelector(
    (state) => state.calcConfig?.calcConfigData?.data?.calcConfigTravelTime
  );
  const calcConfigTravelTimeDiscount = useSelector(
    (state) =>
      state.calcConfig?.calcConfigData?.data?.calcConfigTravelTimeDiscount
  );
  const travelDistanceMaster = useSelector(
    (state) => state.calcConfig.travelDistanceMasterData
  );
  //const travelTimeMaster = useSelector(
  //  state => state.calcConfig.travelTimeMasterData
  //);
  const saveAsNew = useSelector((state) => state.calcConfig.saveAsNew);

  const regx_percent = /^100$|^\d{0,2}(\.\d{1,2})? *%?$/;

  useEffect(() => {
    dispatch(fetchMasterData("travel_distances"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (travelDistanceMaster !== undefined) {
      if (
        travelDistanceMaster.data !== undefined &&
        travelDistanceMaster.data.length > 0 &&
        !dataInitionlizer
      ) {
        setTableData(
          _.map(travelDistanceMaster.data, (o) => {
            return {
              //   item_id: o.item_id,
              //   calc_config_id: o.calc_config_id,
              //   calc_config_basic_id: o.calc_config_basic_id,
              travel_dist_id: o.id,
              time_hr: Number.parseFloat(o.default_val),
              //   comments: o.comments,
            };
          })
        );
        setDataInitionlizer(true);
      }
      setInitialData(
        _.map(travelDistanceMaster.data, (row) => {
          row.travel_dist_id = row.id;
          row.time_hr = row.default_val;
          return row;
        })
      );

      let tableColumns = [
        {
          title: "Activity Location (Hours)",
          field: "title",
          headerFilter: "input",
          headerFilterPlaceholder: "Search by Name",
          tooltip: true,
        },
        {
          title: "Market consensus",
          field: "time_hr",
          editor: "number",
          editorParams: { min: 0 },
          headerFilter: "input",
          headerFilterPlaceholder: "Search",
          tooltip: true,
          width: 200,
        },
        {
          title: "Please provide commentary on Travel Time (Hours)",
          field: "comments",
          editor: "input",
          headerFilter: "input",
          headerFilterPlaceholder: "Search",
          tooltip: true,
        },
      ];
      setTableColumns(tableColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelDistanceMaster]);

  useEffect(() => {
    if (calcConfigTravelTime !== undefined) {
      let formatData = [];
      formatData = _.map(initialData, (row) => {
        row.time_hr = null;
        return row;
      });

      _.forEach(calcConfigTravelTime, function (obj) {
        _.map(formatData, (row) => {
          if (row.travel_dist_id === obj.travel_dist_id) {
            Object.assign(row, obj);
          }
          return row;
        });
      });
      setInitialData(formatData);
      setTableData(formatData);
      setDataInitionlizer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcConfigTravelTime]);

  useEffect(() => {
    if (calcConfigTravelTimeDiscount !== undefined) {
      setItem_id(calcConfigTravelTimeDiscount.item_id);
      setCalc_config_id(calcConfigTravelTimeDiscount.calc_config_id);
      setCalc_config_basic_id(
        calcConfigTravelTimeDiscount.calc_config_basic_id
      );
      setTravelDiscount(
        calcConfigTravelTimeDiscount.percentage_discount !== null
          ? calcConfigTravelTimeDiscount.percentage_discount
          : ""
      );
    }
  }, [calcConfigTravelTimeDiscount, saveAsNew]);

  useEffect(() => {
    if (saveAsNew) {
      setItem_id("");
      setCalc_config_id("");
      setCalc_config_basic_id("");

      let propsToDelete = [
        "item_id",
        "calc_config_id",
        "calc_config_basic_id",
        "updated_at",
        "updated_by",
      ];
      let data = _.forEach(initialData, (obj) => {
        _.forEach(propsToDelete, (prop) => {
          delete obj[prop];
        });
      });
      setInitialData(data);
      setTableData(data);
      dispatch(setTravelTimeData({ tableData: data }));
      dispatch(
        setIsDataProcessedForSaveAsNew({ activityLocationProcessed: true })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAsNew]);

  useEffect(() => {
    tabChangeHandler.current[Calc_Tabs.TAB_ActivityLocation] = {
      getTabData: getTabData,
    };
    getTabData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabledata, travelDiscount]);

  const getTabData = function () {
    dispatch(
      setTravelTimeData({
        tableData: tabledata,
        travelTimeDiscount: {
          ...(item_id !== undefined && item_id !== ""
            ? { item_id: item_id }
            : {}),
          ...(calc_config_id !== undefined && calc_config_id !== ""
            ? { calc_config_id: calc_config_id }
            : {}),
          ...(calc_config_basic_id !== undefined && calc_config_basic_id !== ""
            ? { calc_config_basic_id: calc_config_basic_id }
            : {}),
          percentage_discount: travelDiscount,
        },
      })
    );
  };

  function onCellEdited(cell) {
    if (cell.getRow().getTable().getData() !== undefined) {
      let selectedData = _.filter(
        cell.getRow().getTable().getData(),
        (o) => {
          return o.time_hr !== "" && o.time_hr !== null;
        },
        0
      );

      if (selectedData !== undefined && selectedData.length > 0) {
        setTableData(
          _.map(selectedData, (o) => {
            return {
              item_id: o.item_id,
              calc_config_id: o.calc_config_id,
              calc_config_basic_id: o.calc_config_basic_id,
              travel_dist_id: o.travel_dist_id,
              time_hr: o.time_hr,
              comments: o.comments,
            };
          })
        );
      } else if (selectedData !== undefined && selectedData.length === 0) {
        setTableData(selectedData);
      }
    }
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        To exclude any row please leave the Market consensus value blank.
      </Popover.Body>
    </Popover>
  );

  const handleKeyDown = (event) => {
    if (event.key === "-" || event.key === "+" || event.key === "e") {
      event.preventDefault();
    }
  };

  return (
    <div className="activity-location">
      <Row>
        <Col className="section-header" md={12}>
          Activity Location - Travel Time (Hours)
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            overlay={popover}
          >
            <Button className="Info-btn-tbulator">i</Button>
          </OverlayTrigger>
        </Col>
      </Row>

      {travelDistanceMaster !== undefined ? (
        <ReactTabulator
          ref={(a) => (tableRef.current = a)}
          columns={tableColumns}
          data={initialData}
          key={"activityLocation"}
          cellEdited={onCellEdited}
          height="100%"
          layout="fitColumns"
        />
      ) : (
        ""
      )}
      <Row className="travel-discount"></Row>
      <Row className="travel-discount">
        <Col className="section-header" md={12}>
          Travel Time Discount (% of service per hour rate)
        </Col>
      </Row>
      <Row className="travel-discount">
        <Col className="label-name" md={2}>
          % of the hourly rate
        </Col>
        <Col md={10}>
          <input
            type="number"
            min="0"
            max="100"
            value={travelDiscount}
            onKeyDown={handleKeyDown}
            onChange={(e) =>
              regx_percent.test(e.target.value) &&
              setTravelDiscount(e.target.value)
            }
            id="calcConfig-activity-hourly-rate-id"
            title=""
          />
        </Col>
      </Row>
      <Row className="travel-discount"></Row>
      <Row className="travel-discount"></Row>
    </div>
  );
}
