import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Popover, OverlayTrigger, Button } from "react-bootstrap";
import {
  setCountrySpecLimitData,
  setIsDataProcessedForSaveAsNew
} from "../actions/calcConfig";
import _ from "lodash";
import { Calc_Tabs } from "../helper/constants";
import NumberFormat from "react-number-format";

export function CountrySpecificLimit({ tabChangeHandler }) {
  const dispatch = useDispatch();

  let { calculatorid } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [item_id, setItem_id] = useState("");
  const [calc_config_id, setCalc_config_id] = useState("");
  const [calc_config_basic_id, setCalc_config_basic_id] = useState("");
  const [rateHrLimit, setRateHrLimit] = useState("");
  const [hrLimit, setHrLimit] = useState("");
  const [sessionLimitGen, setSessionLimitGen] = useState("");
  const [initializer, setInitializer] = useState(false);

  const countrySpecLimitConfig = useSelector(
    state => state.calcConfig?.calcConfigData?.data?.calcCountrySpecificLimit
  );
  const saveAsNew = useSelector(state => state.calcConfig.saveAsNew);

  useEffect(() => {
    if (!initializer && item_id && item_id !== "") {
      setInitializer(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item_id]);

  useEffect(() => {
    if (calculatorid !== undefined) {
      setIsEditing(true);
    }
  }, [calculatorid]);

  useEffect(() => {
    if (countrySpecLimitConfig !== undefined && isEditing) {
      setItem_id(countrySpecLimitConfig.item_id);
      setCalc_config_id(countrySpecLimitConfig.calc_config_id);
      setCalc_config_basic_id(countrySpecLimitConfig.calc_config_basic_id);
      setRateHrLimit(
        countrySpecLimitConfig.rate_per_hr_limit !== null
          ? countrySpecLimitConfig.rate_per_hr_limit
          : ""
      );
      setHrLimit(
        countrySpecLimitConfig.hour_limit !== null
          ? countrySpecLimitConfig.hour_limit
          : ""
      );
      setSessionLimitGen(
        countrySpecLimitConfig.session_limit_general !== null
          ? countrySpecLimitConfig.session_limit_general
          : ""
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySpecLimitConfig, saveAsNew]);

  useEffect(() => {
    if (saveAsNew) {
      setItem_id("");
      setCalc_config_id("");
      setCalc_config_basic_id("");

      let propsToDelete = ["item_id", "calc_config_id", "calc_config_basic_id"];
      _.forEach(propsToDelete, prop => {
        delete countrySpecLimitConfig[prop];
      });
      dispatch(setCountrySpecLimitData(countrySpecLimitConfig));
      dispatch(
        setIsDataProcessedForSaveAsNew({ countrySpecLimitProcessed: true })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAsNew]);

  useEffect(() => {
    tabChangeHandler.current[Calc_Tabs.TAB_CountrySpecificLimit] = {
      getTabData: getTabData
    };
    getTabData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initializer,
    item_id,
    calc_config_id,
    calc_config_basic_id,
    rateHrLimit,
    hrLimit,
    sessionLimitGen
  ]);

  const getTabData = function() {
    dispatch(
      setCountrySpecLimitData({
        ...(item_id !== undefined && item_id !== ""
          ? { item_id: item_id }
          : {}),
        ...(calc_config_id !== undefined && calc_config_id !== ""
          ? { calc_config_id: calc_config_id }
          : {}),
        ...(calc_config_basic_id !== undefined && calc_config_basic_id !== ""
          ? { calc_config_basic_id: calc_config_basic_id }
          : {}),
        rate_per_hr_limit: rateHrLimit,
        hour_limit: hrLimit,
        session_limit_general: sessionLimitGen
      })
    );
  };

  // const handleKeyDown = event => {
  //   if (event.key === "-" || event.key === "+" || event.key === "e") {
  //     event.preventDefault();
  //   }
  // };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Market Specific Cap Limits for the calculator.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="country-spec-limit">
      <Row>
        <Col className="section-header" md={12}>
          Market Specific Limits
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            overlay={popover}
          >
            <Button className="Info-btn-tbulator">i</Button>
          </OverlayTrigger>
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          Rate/Hourly Limit - General (in local currency)
        </Col>
        <Col md={10}>
          {/* <input
            type="number"
            min={0}
            value={rateHrLimit}
            onKeyDown={handleKeyDown}
            onChange={(e) => setRateHrLimit(e.target.value)}
          /> */}
          <NumberFormat
            thousandsGroupStyle="thousand"
            value={rateHrLimit}
            decimalSeparator="."
            displayType="input"
            type="text"
            thousandSeparator={true}
            onValueChange={values => {
              setRateHrLimit(values.value);
            }}
            id="calcConfig-countrySpecific-rate-hourly-limit-id"
            allowNegative={false}
          />
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          Hourly Limit - General (hrs)
        </Col>
        <Col md={10}>
          {/* <input
            type="number"
            min={0}
            value={hrLimit}
            onKeyDown={handleKeyDown}
            onChange={(e) => setHrLimit(e.target.value)}
            id="calcConfig-countrySpecific-hourly-limit-id"
          /> */}
          <NumberFormat
            thousandsGroupStyle="thousand"
            value={hrLimit}
            decimalSeparator="."
            displayType="input"
            type="text"
            thousandSeparator={true}
            onValueChange={values => {
              setHrLimit(values.value);
            }}
            id="calcConfig-countrySpecific-hourly-limit-id"
            allowNegative={false}
          />
        </Col>
      </Row>
      <Row>
        <Col className="label-name" md={2}>
          Session Limit - General (in local currency)
        </Col>
        <Col md={10}>
          {/* <input
            type="number"
            min={0}
            value={sessionLimitGen}
            onKeyDown={handleKeyDown}
            onChange={(e) => setSessionLimitGen(e.target.value)}
          /> */}
          <NumberFormat
            thousandsGroupStyle="thousand"
            value={sessionLimitGen}
            decimalSeparator="."
            displayType="input"
            type="text"
            thousandSeparator={true}
            onValueChange={values => {
              setSessionLimitGen(values.value);
            }}
            id="calcConfig-countrySpecific-session-limit-id"
            allowNegative={false}
          />
        </Col>
      </Row>
    </div>
  );
}
