import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { Image, Button } from "react-bootstrap";
import add from "../assets/add.svg";
import deleteicon from "../assets/delete-icon.png";
import _ from "lodash";
import Swal from "sweetalert2";
import { ReactTabulator } from "react-tabulator";
import {
  fetchKolDashboardData,
  deleteKOLAssessmentConfig
} from "../actions/kolAssessment";
import { fetchMasterConfigData } from "../actions/masterDataConfig";
import { ShowLoader } from "../helper/showLoader";

export function KolDashboard() {
  const dispatch = useDispatch();

  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rowSelected, setRowSelected] = useState(false);
  const [isAccess, setIsAccess] = useState(false);

  const accessUrl = useRef("");
  const ref = useRef(null);

  const userInformation = useSelector(state => state.userInformation);
  const kolDashboardData = useSelector(
    state => state.kolAssessment.kolDashboardData
  );
  const kolConfigDeleted = useSelector(
    state => state.kolAssessment.kolConfigDeleted
  );
  const hcpStatureMaster = useSelector(state => state.masterDataConfig);

  useEffect(() => {
    if (userInformation !== undefined && userInformation?.data !== undefined) {
      let pagesAccess = userInformation?.data?.users[0]?.accessPages;
      if (pagesAccess !== undefined) {
        _.map(pagesAccess, o => {
          if (o === "Master Data Management") {
            setIsAccess(true);
          }
        });
        accessUrl.current = "/accessdenied";
      }
    }
  }, [userInformation]);

  useEffect(() => {
    dispatch(fetchKolDashboardData());
    dispatch(fetchMasterConfigData("hcp_statures"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (kolDashboardData) {
      setTableData(
        _.map(kolDashboardData?.data, o => {
          o.assessment = o.section;
          o._children = o.assessments;
          o.addAssessment = "add";
          return o;
        })
      );
    }
  }, [kolDashboardData]);

  useEffect(() => {
    if (kolConfigDeleted && kolConfigDeleted?.data) {
      window.location.reload();
    }
  }, [kolConfigDeleted]);

  useEffect(() => {
    if (tableData) {
      getTableColumns();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const getTableColumns = () => {
    let tableColumns = [
      {
        formatter: "rowSelection",
        titleFormatter: "rowSelection",
        hozAlign: "right",
        headerSort: false,
        width: 60,
        cellClick: function(e, cell) {
          cell.getRow().toggleSelect();
        }
      },
      {
        title: "Section/Assessments",
        field: "assessment",
        tooltip: true,
        cellClick: goToDetail
      },
      {
        title: "Order",
        field: "order",
        tooltip: true,
        width: 90,
        // editor: "number"
        cellClick: goToDetail
      },
      {
        field: "addAssessment",
        title: " ",
        hozAlign: "left",
        width: 50,
        headerSort: false,
        formatter: addAssessmentIcon,
        cellClick: addAssessment
      }
    ];
    setTableColumns(tableColumns);
  };

  const addAssessmentIcon = function(cell, formatterParams, onRendered) {
    if (cell.getValue()) {
      return (
        "<img height='20px' width='20px' title='Add Assessment' src='" +
        add +
        "'>"
      );
    }
  };

  const addAssessment = (e, cell) => {
    if (cell.getValue()) {
      window.location.href =
        "/kolassessmentconfig/section/addassessment/" + cell.getData().id;
    }
  };

  const goToDetail = (e, cell) => {
    if (cell.getData().section && cell.getData().section !== null) {
      window.location.href =
        "/kolassessmentconfig/section/update/" + cell.getData().id;
    } else {
      window.location.href =
        "/kolassessmentconfig/assessment/update/" +
        cell.getData().assessment_id;
    }
  };

  const addSection = () => {
    window.location.href = "/kolassessmentconfig";
  };

  const deleteItem = () => {
    let sectionsSelected = _.filter(ref.current.table.getSelectedData(), o => {
      return o.section !== undefined && o.section !== null;
    });
    let sectionsToDelete = _.map(sectionsSelected, o => {
      return o.id;
    });

    let assessmentsSelected = _.filter(
      ref.current.table.getSelectedData(),
      o => {
        return o.assessment_id !== undefined && o.assessment_id !== null;
      }
    );
    let filteredAssessments = _.filter(assessmentsSelected, o => {
      return sectionsToDelete.indexOf(o.section_id) < 0;
    });
    let assessmentsToDelete = _.map(filteredAssessments, o => {
      return o.assessment_id;
    });

    let confirmMssg =
      sectionsSelected.length > 0
        ? "<strong>Are you sure you want to delete the selected item(s)?</strong>" +
          "<h6>On deleting a section, all its subsequent assessments will also be deleted. Do you want to proceed?</h6>"
        : "<strong>Are you sure you want to delete the selected assessment(s)?</strong>";

    Swal.fire({
      html: confirmMssg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#808080",
      focusConfirm: false,
      confirmButtonText: "Yes, delete!",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then(result => {
      if (result.isConfirmed) {
        if (sectionsToDelete && sectionsToDelete.length > 0) {
          let sections = {
            ids: sectionsToDelete
          };
          dispatch(deleteKOLAssessmentConfig(sections, "section"));
        }
        if (assessmentsToDelete && assessmentsToDelete.length > 0) {
          let assessments = {
            ids: assessmentsToDelete
          };
          dispatch(deleteKOLAssessmentConfig(assessments, "assessment"));
        }
      }
    });
  };

  const onValidateMaxScore = () => {
    if (kolDashboardData && kolDashboardData?.data) {
      let maximumScore = 0;
      _.map(kolDashboardData?.data, obj => {
        let assScore = 0;
        _.map(obj.assessments, o => {
          let maxScore;
          if (o?.options && o?.options?.length > 0) {
            maxScore = o?.options.reduce(
              (acc, shot) => (acc = acc > shot.score ? acc : shot.score),
              0
            );
          } else if (o?.score && o?.score > 0) {
            maxScore = o?.score;
          }
          if (maxScore > 0) {
            assScore = assScore + maxScore;
          }
        });
        if (assScore > 0) {
          maximumScore = maximumScore + assScore;
        }
      });

      let stature = "",
        minRange = 0,
        maxRange = 0;
      if (hcpStatureMaster?.data) {
        let statureData = hcpStatureMaster?.data?.data;
        if (statureData && maximumScore) {
          _.forEach(statureData, obj => {
            if (
              maximumScore >= parseInt(obj.min) &&
              maximumScore <= parseInt(obj.max)
            ) {
              stature = obj.title;
              minRange = obj.min;
              maxRange = obj.max;
            }
          });
          if (stature === "") {
            _.map(statureData, obj => {
              if (obj.title?.toLowerCase().includes("international")) {
                if (maximumScore > parseInt(obj.max)) {
                  stature = "scoreoutweighs";
                  minRange = obj.min;
                  maxRange = obj.max;
                }
              }
            });
          }
          if (stature !== "") {
            let msg;
            if (stature.toLocaleLowerCase() === "scoreoutweighs") {
              msg =
                "<strong>Maximum score (" +
                maximumScore +
                ") outweighs max allowable value, refer to International KOL (" +
                minRange +
                "-" +
                maxRange +
                ") score guidelines.</strong>";
            } else {
              msg =
                "<strong>Maximum score (" +
                maximumScore +
                ") refer to " +
                stature +
                " (" +
                minRange +
                "-" +
                maxRange +
                ") score guidelines.</strong>";
            }
            Swal.fire({
              html: msg,
              icon: "info",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#808080",
              focusConfirm: false,
              confirmButtonText: "OK",
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            });
          }
        }
      }
    }
  };

  const optionsNested = {
    dataTree: true,
    dataTreeStartExpanded: true,
    dataTreeSelectPropagate: true
  };

  return (
    <>
      {accessUrl.current !== "" &&
        (!isAccess ? <Redirect to={accessUrl.current} /> : "")}
      {isAccess ? (
        <div className="kol-dashboard masterdata-config">
          <ShowLoader></ShowLoader>

          <div className="table-heading">
            <span>KOL Assessments Dashboard</span>
            <Button
              variant="secondary"
              className="hcp-filter-btn addAllSpeciality"
              onClick={onValidateMaxScore}
            >
              Validate Max Score
            </Button>
            {!rowSelected ? (
              <div className="add-section">
                <label className="label-name">Add New Section &nbsp;</label>
                <Image
                  id="addNewSection"
                  src={add}
                  className="add-item"
                  alt="Add New Section"
                  onClick={() => addSection()}
                ></Image>
              </div>
            ) : (
              ""
            )}
            {rowSelected ? (
              <div className="delete-item">
                <button onClick={() => deleteItem()}>
                  <Image src={deleteicon} alt="Delete"></Image>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="tabulator-wrapper">
            {tableData && tableColumns ? (
              <ReactTabulator
                ref={a => (ref.current = a)}
                columns={tableColumns}
                data={tableData}
                options={optionsNested}
                key={"KOLDashboard"}
                height="100%"
                layout="fitColumns"
                rowSelectionChanged={function(data, rows) {
                  if (rows.length > 0) setRowSelected(true);
                  else setRowSelected(false);
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
